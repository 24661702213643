import React from 'react';
import {
  Text, Stack, Badge, Flex, PseudoBox,
} from '@chakra-ui/core';
import {
  Collapse, CollapseHeader, CollapsePanel, CollapseBody, FieldUppy,
} from 'components';
import PropTypes from 'prop-types';
import { Formiz } from '@formiz/core';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { APPENV } from 'services/config';
import { NotificationBadge } from 'components/NotificationBadge';
import { queryCache } from 'react-query';
import { DocumentDetails } from '../DocumentDetails';

export const LivrableDetails = ({
  livrable: {
    id,
    livrableLibelle,
    livrableLibelleCustom,
    lotId,
  },
  auth,
  documents,
  actionCountDocumentsDeposesByLivrableId,
  actionCountDocumentsRefusesByLivrableId,
  phaseStatut,
  phaseId,

}) => {
  const { t } = useTranslation();
  const livrableName = livrableLibelleCustom !== null ? livrableLibelleCustom : t(livrableLibelle, { defaultValue: livrableLibelle });
  const { projectToken } = useParams();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const showLivrable = parseInt(params.get('livrableId'), 10) === id;
  const handleInvalidate = () => {
    queryCache.invalidateQueries([['phaseId', 'projectToken'], [phaseId, projectToken]]);
  };



  return (
    <Collapse
      position="relative"
      state={showLivrable}
    >
      {/* Si la notification des actions sur les documents déposes existe et est différent de
      0 on affiche (le filtre du rôle est traité dans le back) */}
      {
        actionCountDocumentsDeposesByLivrableId !== 0 && (phaseStatut !== 'statut.cloturee') && (
          <NotificationBadge
            position="absolute"
            limit={9}
            left="-0.7rem"
            top="-0.7rem"
            d="flex"
            value={actionCountDocumentsDeposesByLivrableId}
            backgroundColor="#1378fa"
            fontSize="0.8rem"
            color="white"
            justifyContent="center"
            alignItems="center"
            w="1.2rem"
            h="1.2rem"
            p="0.8rem"
          />
        )
      }
      {/* Si la notification des actions sur les documents refusés existe et est différent de 0
      on affiche (le filtre du rôle est traité dans le back) */}
      {
        actionCountDocumentsRefusesByLivrableId !== 0 && (phaseStatut !== 'statut.cloturee') && (
          <NotificationBadge
            position="absolute"
            limit={9}
            left="-0.7rem"
            top="-0.7rem"
            d="flex"
            value={actionCountDocumentsRefusesByLivrableId}
            backgroundColor="#1378fa"
            fontSize="0.8rem"
            color="white"
            justifyContent="center"
            alignItems="center"
            w="1.2rem"
            h="1.2rem"
            p="0.8rem"
          />
        )
      }
      <CollapseHeader>
        <Stack isInline>
          <Flex>
            <Text
              m="auto"
            >
              {livrableName}

            </Text>

            {
              Object.keys(documents).length === 1 && (
                <Badge
                  w="7rem"
                  display="flex"
                  justifyContent="center"
                  p={1}
                  ml={3}
                >
                  <Text>
                    {`${Object.keys(documents).length} ${t('document.singulier', { defaultValue: 'document' })}`}
                  </Text>
                </Badge>
              )
            }

            {
              Object.keys(documents).length !== 0 && Object.keys(documents).length !== 1 && (
                <Badge
                  w="7rem"
                  display="flex"
                  justifyContent="center"
                  p={1}
                  ml={3}
                >
                  <Text>
                    {`${Object.keys(documents).length} ${t('document.pluriel', { defaultValue: 'documents' })}`}
                  </Text>
                </Badge>
              )
            }
          </Flex>
        </Stack>
      </CollapseHeader>
      <CollapsePanel>
        <CollapseBody>
          <Formiz>
            <form noValidate>

              {

                typeof (documents) !== 'undefined' && Object.values(documents)?.map((document) => (
                  <DocumentDetails
                    lotId={lotId}
                    livrableId={id}
                    key={document.data.id}
                    document={document.data}
                    auth={document.auth}
                  />

                ))
              }
              <PseudoBox
                mx="auto"
                w="97.5%"
                mt={4}
              >
                {auth.canUpload && (
                  <FieldUppy
                    url={`${APPENV.HOST_API}/projet/${projectToken}/file/livrable/upload`}
                    label={t('files.allow')}
                    name="files"
                    required="required"
                    livrableId={id}
                    fromForm={false}
                    callbackForRefresh={handleInvalidate}
                  />
                )}
              </PseudoBox>
            </form>
          </Formiz>
        </CollapseBody>
      </CollapsePanel>
    </Collapse>
  );
};
LivrableDetails.propTypes = {
  livrable: PropTypes.shape({
    id: PropTypes.number,
    livrableLibelleCustom: PropTypes.string,
    livrableLibelle: PropTypes.string,
    moyenneDocumentsValides: PropTypes.number,
    lotId: PropTypes.number,
  }).isRequired,
  documents: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  auth: PropTypes.shape({
    canRead: PropTypes.bool,
    canUpdate: PropTypes.bool,
    canUpload: PropTypes.bool,
    canDelete: PropTypes.bool,
  }),
  actionCountDocumentsDeposesByLivrableId: PropTypes.number.isRequired,
  actionCountDocumentsRefusesByLivrableId: PropTypes.number.isRequired,
  phaseStatut: PropTypes.string.isRequired,
  phaseId: PropTypes.number.isRequired,
};
LivrableDetails.defaultProps = {
  documents: [],
  auth: [],
};
