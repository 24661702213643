import React, { useState } from 'react';
import {
  Button,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
} from '@chakra-ui/core';
import { Formiz, useForm } from '@formiz/core';
import { FieldInput } from 'components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { APPENV } from 'services/config';
import axios from 'axios';
import { queryCache } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Enregistrer } from 'components/Button/Enregistrer';

export const EditPhase = ({
  isOpen, onClose, id, auth,
}) => {
  const updatePhaseForm = useForm();
  const { t } = useTranslation();
  const { projectToken } = useParams();
  const { phaseId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const toast = useToast();

  const handleEdit = async (values) => {
    const commentaire = values.commentaire !== '' ? values.commentaire : null;

    const formData = {
      commentaire,
    };

    const url = auth.canCloturer ? `${APPENV.HOST_API}/projet/${projectToken}/phase/${id}/cloturer`
      : `${APPENV.HOST_API}/projet/${projectToken}/phase/${id}/commentaire`;

    try {
      setLoading(true);
      const phase = await axios.put(`${url}`, formData);
      if (auth.canCloturer) {
        await queryCache.invalidateQueries('projectToken', projectToken);
        setLoading(false);
        onClose();

        if (phase.data.phaseSuivante !== null) {
          history.push({
            pathname: `/projects/${projectToken}/phase/${phase.data.phaseSuivante.id}`,
          });
        }
      } else {
        await queryCache.invalidateQueries([['phaseId', 'projectToken'], [phaseId, projectToken]]);
        await queryCache.invalidateQueries('projectToken', projectToken);
        setLoading(false);
        onClose();
      }
    } catch (err) {
      toast({
        title: 'Phase not updated',
        description: 'Failed to update',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('modal.entete.cloturer.phase', { defaultValue: 'Edit Phase' })}
        </ModalHeader>
        <ModalCloseButton />
        <Formiz onValidSubmit={handleEdit} connect={updatePhaseForm}>
          <form noValidate onSubmit={updatePhaseForm.submit}>
            <ModalBody>
              {auth.canUpdateCommentaireMoa && (
                <FieldInput
                  name="commentaire"
                  type="textarea"
                  label={t('phase.libelle.commentaire', { defaultValue: 'Commentaire de validation' })}
                  placeholder={t('phase.placeholder.commentaire', { defaultValue: 'Commentaire de validation' })}
                />
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                variantColor="gray"
                onClick={onClose}
              >
                {t('cancel', { defaultValue: 'Cancel' })}
              </Button>
              {/* Bouton enregistrer  */}
              <Enregistrer
                isLoading={isLoading}
              />
            </ModalFooter>
          </form>
        </Formiz>
      </ModalContent>
    </Modal>
  );
};

EditPhase.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.number.isRequired,
  auth: PropTypes.shape({
    canRead: PropTypes.bool,
    canUpdate: PropTypes.bool,
    canDelete: PropTypes.bool,
    canUpdateCommentaireMoa: PropTypes.bool,
    canCloturer: PropTypes.bool,
  }),
};

EditPhase.defaultProps = {
  isOpen: false,
  onClose: () => { },
  auth: [],
};
