import React from 'react';
import {
  Flex, Text, PseudoBox,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const LectureItemProfil = ({
  label, value,
}) => {
  const { t } = useTranslation();

  return (
    <PseudoBox
      w="100%"
      _hover={{
        backgroundColor: 'gray.50',
      }}
    >
      <Flex
        p={4}
        w="100%"
        borderTop="1px"
        borderColor="gray.50"
      >
        {/* Label */}
        <Flex
          w={{ base: '30%' }}
          alignItems="center"
        >
          <Text
            fontSize="xs"
            color="gray.600"
          >
            {t(label).toUpperCase()}
          </Text>
        </Flex>
        {/* Valeur */}
        <Flex
          as="b"
          w={{ base: '70%' }}
        >
          <Text
            wordBreak="break-word"
            fontSize={{ base: 'xs', lg: 'md' }}
          >
            {value}
          </Text>
        </Flex>

      </Flex>
    </PseudoBox>
  );
};

LectureItemProfil.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
