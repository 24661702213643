/**
 * Coupe une châine de caractère si elle dépasse une certaine longueur monDoc[...].pdf
 * @param {String} str
 */
export const wrapStr = (str, length) => {
  if (str === null || str === undefined) {
    return str;
  }
  if (str.length > length) {
    let newStr = str.substring(0, `${length}`);
    newStr += '[...]';
    return newStr;
  }
  return str;
};
