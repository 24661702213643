import React from 'react';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import { FormGroup } from 'components';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {
  Input, PseudoBox, IconButton, Flex,
} from '@chakra-ui/core';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { FcCalendar } from 'react-icons/fc';
import { useTranslation } from 'react-i18next';

const propTypes = {
  ...fieldPropTypes,
};

const defaultProps = {
  ...fieldDefaultProps,
};

export const FieldDatePicker = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    setValue,
    value,
  } = useField(props);
  const {
    label, type, required, isDisabled, placeholder, format, ...otherProps
  } = props;
  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);
  const { t } = useTranslation();
  const lang = t('core.lang.shortcut');

  function parseDate(str, dateFormat, locale) {
    const parsed = dateFnsParse(str, dateFormat, new Date(), { locale });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }

  function formatDate(date, dateFormat, locale) {
    return dateFnsFormat(date, dateFormat, { locale });
  }

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };

  return (
    <PseudoBox
      w="11rem"
    >
      <FormGroup
        {...formGroupProps}
      >
        <Flex>
          <DayPickerInput
            id={id}
            value={(value !== 'null' && `${dateFnsFormat(new Date(value), format)}`) ?? ''}
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => {
              setIsTouched(true);
            }}
            dayPickerProps={{
              locale: lang,
              months: t('dateinput.MONTHS', { returnObjects: true }),
              weekdaysLong: t('dateinput.WEEKDAYS_LONG', { returnObjects: true }),
              weekdaysShort: t('dateinput.WEEKDAYS_SHORT', { returnObjects: true }),
              firstDayOfWeek: t('dateinput.FIRST_DAY_OF_WEEK'),
              labels: t('dateinput.LABELS', { returnObjects: true }),
            }}
            formatDate={formatDate}
            parseDate={parseDate}
            format={format}
            placeholder={placeholder}
            aria-invalid={showError}
            aria-required={!!required}
            aria-describedby={showError ? `${id}-error` : null}
            onDayChange={(day) => setValue(day)}
            component={Input}
          />
          <Flex>
            <IconButton
              cursor="zoom-in"
              border="none"
              variant="outline"
              variantColor="teal"
              aria-label="Call Sage"
              fontSize="20px"
              icon={FcCalendar}
              as="div"
              _hover={{
                backgroundColor: 'white',
              }}
              onClick={() => {
                setIsTouched(true);
              }}
            />
          </Flex>
        </Flex>
      </FormGroup>
    </PseudoBox>
  );
};

FieldDatePicker.propTypes = propTypes;
FieldDatePicker.defaultProps = defaultProps;
