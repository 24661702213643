import React from 'react';
import { Flex } from '@chakra-ui/core';

export const InputFilePlaceholder = (props) => (
  <Flex
    as="span"
    borderStyle="dashed"
    borderWidth="0.075rem"
    borderRadius="md"
    borderColor="gray.300"
    bg="white"
    p="4"
    align="center"
    justify="center"
    {...props}
  />
);
