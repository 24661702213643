import React, { useCallback, useState } from 'react';
import {
  SimpleGrid,
  Grid,
  Box,
  Tag,
  TagLabel,
  Stack,
  Alert,
  AlertIcon,
  Button,
  useToast,
} from '@chakra-ui/core';
import { useParams } from 'react-router-dom';
import { APPENV } from 'services/config';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formiz, useForm } from '@formiz/core';
import {
 Card, CardHeader, CardTitle, Icon,
} from 'components';
import { FaFlag } from 'react-icons/fa';
import { AutoCompleteIntervenants } from 'components/AutoComplete/intervenants';
import { IntervenantList } from 'components/IntervenantCard';

export const IntervenantsConfiguration = ({
  projetIntervenants,
  defaultContact,
  rolesListe,
  entreprises,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [isLoading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const intervenantForm = useForm();
  const { projectToken } = useParams();

  const rolesForSelect = rolesListe?.map((role) => ({
    value: `${role.clef}`,
    label: t(role.valeur),
  }));

  const entrepriseForSelect = entreprises?.map((entreprise) => ({
    value: `${entreprise.id}`,
    label: `${entreprise.nom}`,
  }));

  const removeDuplicateEmail = (otherArray) => (current) => otherArray.filter((other) => other.data.contactEmail === current.email)
      .length === 0;
  const fullDefaultListe = defaultContact;

  const defaultIntervenantsList = defaultContact.filter(
    removeDuplicateEmail(projetIntervenants),
  );

  const [listeIntervenantsProjet, setListeIntervenantsProjet] = useState(
    projetIntervenants,
  );
  const [listeContactsDefault, setListeContactsDefault] = useState(
    defaultIntervenantsList,
  );

  const getItem = (src, indexSrc) => {
    const srcArr = Array.from(src);
    const [removed] = srcArr.splice(indexSrc, 1);
    srcArr.splice(indexSrc, 0, removed);
    return removed;
  };

  const addItem = (arr, item) => {
    arr.push(item);
    return arr;
  };

  const removeItem = (src, indexSrc) => {
    const srcArr = Array.from(src);
    srcArr.splice(indexSrc, 1);
    return srcArr;
  };

  const handleRemove = useCallback(
    async (src, indexSrc) => {
      const intervenant = getItem(src, indexSrc);
      try {
        axios.delete(
          `${APPENV.HOST_API}/projet/${projectToken}/intervenant/${intervenant.data.id}`,
        );
        const removed = removeItem(src, indexSrc);

        const item = getItem(src, indexSrc);
        const itemToAdd = fullDefaultListe.find(
          (x) => x.id === item.data.contactId,
        );

        if (typeof itemToAdd !== 'undefined') {
          const arrDefault = addItem(listeContactsDefault, itemToAdd);
          setListeContactsDefault(arrDefault);
        }

        setListeIntervenantsProjet(removed);

        toast({
          title: t('success.delete.intervenant.libelle', {
            defaultValue: 'Intervenant supprimée',
          }),
          description: t('success.delete.intervenant.message', {
            defaultValue: 'Intervenant supprimée',
          }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (e) {
        toast({
          title: t('error.delete.intervenant.libelle', {
            defaultValue: 'Erreur lors de la suppression',
          }),
          description: t('error.delete.intervenant.message', {
            defaultValue: 'Erreur lors de la suppression',
          }),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [projectToken, fullDefaultListe, listeContactsDefault, t, toast],
  );

  const handleChangeRole = useCallback(
    async (e, intervenantId) => {
      const formData = {
        contact_role: e.target.value,
      };

      try {
        await axios.put(
          `${APPENV.HOST_API}/projet/${projectToken}/intervenant/${intervenantId}/role`,
          formData,
        );
        toast({
          title: t('success.update.intervenant.libelle', {
            defaultValue: 'Role intervenant mise à jour',
          }),
          description: t('success.update.intervenant.message', {
            defaultValue: 'Role intervenant mise à jour',
          }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (err) {
        toast({
          title: t('error.update.intervenant.libelle', {
            defaultValue: 'Erreur lors de la mise à jour',
          }),
          description: t('error.update.intervenant.message', {
            defaultValue: 'Erreur lors de la mise à jour',
          }),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [projectToken, toast, t],
  );

  const handleSendInvitation = useCallback(
    async (e, intervenantId) => {
      try {
        await axios.get(
          `${APPENV.HOST_API}/projet/${projectToken}/intervenant/${intervenantId}/invitation`,
        );
        toast({
          title: t('success.invitation.intervenant.libelle', {
            defaultValue: 'Invitation envoyée',
          }),
          description: t('success.invitation.intervenant.message', {
            defaultValue: 'Invitation envoyée',
          }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (err) {
        toast({
          title: t('error.invitation.intervenant.libelle', {
            defaultValue: "Erreur lors de l'invitation",
          }),
          description: t('error.invitation.intervenant.message', {
            defaultValue: "Erreur lors de l'invitation",
          }),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [projectToken, toast, t],
  );

  const handleSubmit = async (values) => {
    if (
      !values?.contact_email
      && !values?.contact_role
      && !values?.entreprise_id
    ) {
      toast({
        title: t('error.insert.intervenant.libelle', {
          defaultValue: 'Erreur lors de la création',
        }),
        description: t('error.insert.intervenant.message', {
          defaultValue: 'Erreur lors de la création',
        }),
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    } else {
      const dataForm = { intervenants: { values } };
      setLoading(true);

      try {
        const result = await axios.post(
          `${APPENV.HOST_API}/projet/${projectToken}/intervenant`,
          dataForm,
        );
        const arrIntervenants = Object.values(
          result.data.intervenantAdded,
        )?.map((data) => addItem(listeIntervenantsProjet, data));

        setListeIntervenantsProjet(arrIntervenants[0]);
        const index = listeContactsDefault.findIndex(
          (x) => x.email === values.contact_email,
        );
        const removeFromDefault = removeItem(listeContactsDefault, index);

        setListeContactsDefault(removeFromDefault);
        setIsSubmit(true);

        toast({
          title: t('success.insert.intervenant.libelle', {
            defaultValue: 'Intervenant ajoutée',
          }),
          description: t('success.insert.intervenant.message', {
            defaultValue: 'Intervenant ajoutée',
          }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (err) {
        if (err.response) {
          if (err.response.status === 409) {
            toast({
              title: t(err.response.data.error.description, {
                defaultValue: 'Erreur lors de la création',
              }),
              description: t(err.response.data.error.description, {
                defaultValue: 'Erreur lors de la création',
              }),
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top-right',
            });
          } else {
            toast({
              title: t('error.insert.intervenant.libelle', {
                defaultValue: 'Erreur lors de la création',
              }),
              description: t('error.insert.intervenant.message', {
                defaultValue: 'Erreur lors de la création',
              }),
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top-right',
            });
          }
        }
      }

      setLoading(false);
    }
  };

  return (
    <>
      <Stack spacing={6} pl={4} pr={4}>
        <Card
          w={{
            base: '100%',
            md: '80%',
          }}
          textAlign="center"
          m="auto"
        >
          <CardHeader>
            <CardTitle>
              <Tag variantColor="green">
                <Icon icon={FaFlag} mr={2} />
                {/* <TagLabel> */}
                {t('intervenants.configuration', {
                  defaultValue: 'Configuration des intervenants',
                })}
                {/* </TagLabel> */}
              </Tag>
            </CardTitle>
          </CardHeader>
          <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} spacing={8} p={4}>
            {listeIntervenantsProjet?.map((intervenant, index) => (
              <IntervenantList
                key={intervenant.data.id}
                intervenant={intervenant.data}
                auth={intervenant.auth}
                roles={rolesListe}
                onDelete={() => handleRemove(listeIntervenantsProjet, index)}
                onChangeRole={handleChangeRole}
                onInvitation={handleSendInvitation}
              />
            ))}
          </SimpleGrid>
        </Card>
        <Card
          w={{
            base: '100%',
            md: '80%',
          }}
          textAlign="center"
          m="auto"
        >
          <Formiz onValidSubmit={handleSubmit} connect={intervenantForm}>
            <form noValidate onSubmit={intervenantForm.submit}>
              <CardHeader>
                <CardTitle>
                  <Tag variantColor="green">
                    <Icon icon={FaFlag} mr={2} />
                    <TagLabel>
                      {t('intervenant.header', {
                        defaultValue: 'Intervenants',
                      })}
                    </TagLabel>
                  </Tag>
                </CardTitle>

                <Alert status="info" mt={4}>
                  <AlertIcon />
                  {t('intervenant.description.recherche')}
                </Alert>
              </CardHeader>
              <Grid
                templateColumns={{
                  base: '1fr',
                }}
                alignItems="center"
                p={6}
              >
                <Box
                  rounded="md"
                  borderWidth="1px"
                  borderStyle="dashed"
                  m={2}
                  position="relative"
                >
                  <AutoCompleteIntervenants
                    listeDefault={listeContactsDefault}
                    typeInput="intervenant"
                    isSubmit={isSubmit}
                    setIsSubmit={setIsSubmit}
                    roles={rolesForSelect}
                    entreprises={entrepriseForSelect}
                  />
                </Box>
              </Grid>
              <Stack p={4}>
                <Button
                  mr={4}
                  ml="auto"
                  type="submit"
                  variantColor="brand"
                  disabled={
                    isLoading
                    || (!intervenantForm.isValid && intervenantForm.isSubmitted)
                  }
                >
                  {isLoading
                    ? t('button.loading.libelle', {
                        defaultValue: 'Chargement...',
                      })
                    : t('button.ajouterauprojet.libelle', {
                        defaultValue: 'Ajouter au projet',
                      })}
                </Button>
              </Stack>
            </form>
          </Formiz>
        </Card>
      </Stack>
    </>
  );
};

IntervenantsConfiguration.propTypes = {
  projetIntervenants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      contactNom: PropTypes.string,
      contactPrenom: PropTypes.string,
      contactEmail: PropTypes.string,
    }).isRequired,
  ),
  defaultContact: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      valeur: PropTypes.string,
      ordre: PropTypes.number,
    }).isRequired,
  ),
  rolesListe: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      valeur: PropTypes.string,
      ordre: PropTypes.number,
    }).isRequired,
  ),
  entreprises: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      nom: PropTypes.string,
    }).isRequired,
  ),
};

IntervenantsConfiguration.defaultProps = {
  projetIntervenants: [],
  defaultContact: [],
  rolesListe: [],
  entreprises: [],
};
