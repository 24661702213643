import React, { useContext } from 'react';
import { CircularProgress } from 'components';
import { TimelineContext } from './Timeline';

export const TimelineProgress = (props) => {
  const { progressSize } = useContext(TimelineContext);

  return (
    <CircularProgress
      position="absolute"
      top="0"
      left="0"
      size={progressSize}
      {...props}
    />
  );
};
