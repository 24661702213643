import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/core';

export const CollapseContext = createContext();

export const Collapse = ({ variant, state, ...props }) => {
  const [isOpen, setIsOpen] = useState(state);

  return (
    <CollapseContext.Provider value={{ variant, isOpen, setIsOpen }}>
      <Box h="100%" {...props} />
    </CollapseContext.Provider>
  );
};

Collapse.propTypes = {
  variant: PropTypes.oneOf(['card']),
  state: PropTypes.bool,
};

Collapse.defaultProps = {
  variant: null,
  state: false,
};
