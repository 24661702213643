import React, { useContext } from 'react';
import { Box } from '@chakra-ui/core';
import { CardContext } from 'components/Card/Card';

export const CardHeader = (props) => {
  const { withActionButton } = useContext(CardContext);

  return (
    <Box px="6" pt="4" pb="2" pr={withActionButton ? '12' : '6'} {...props} />
  );
};
