import React, { useCallback, useState } from 'react';
import {
  SimpleGrid,
  Grid,
  Box,
  Tag,
  TagLabel,
  Stack,
  useToast,
  Alert,
  AlertIcon,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DraggablePhasesCard } from 'components/DraggableCard/phase';
import { useParams } from 'react-router-dom';
import { APPENV } from 'services/config';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formiz, useForm } from '@formiz/core';
import {
  Card, CardBody, CardHeader, CardTitle, Icon,
} from 'components';
import { FaFlag } from 'react-icons/fa';
import { AutoCompletePhase } from 'components/AutoComplete/phase';
import { formatDateDb } from 'services/utils';
import { Enregistrer } from 'components/Button/Enregistrer';
import { queryCache } from 'react-query';

export const DatasPhasesConfiguration = ({
  setPhasesProjet,
  projetPhases,
  defaultPhases,
}) => {
  const { t } = useTranslation();
  const phasesForm = useForm();
  const { projectToken } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [dateDebut, setDateDebut] = useState(new Date());
  const [dateFin, setDateFin] = useState(new Date());
  let defaultPhasesList = defaultPhases?.map((phase) => ({
    label: t(phase.valeur, { defaultValue: phase.valeur }),
    codePhase: `${phase.clef}`,
    id: `${phase.id}`,
    phaseId: `${phase.id}`,
    ordre: phase.ordre,
  }));

  const fullDefaultListe = defaultPhasesList;

  const toRemove = projetPhases?.filter(
    (x) => defaultPhasesList.indexOf(x) === -1,
  );

  const removeDuplicatePhases = (otherArray) => (current) => otherArray.filter((other) => other.phaseId === current.id).length === 0;

  defaultPhasesList = defaultPhasesList.filter(removeDuplicatePhases(toRemove));
  const [listePhasesProjet, setListePhasesProjet] = useState(projetPhases);
  const [listePhasesDefault, setListePhasesDefault] = useState(
    defaultPhasesList,
  );
  const toast = useToast();

  const getItem = (src, indexSrc) => {
    const srcArr = Array.from(src);
    const [removed] = srcArr.splice(indexSrc, 1);
    srcArr.splice(indexSrc, 0, removed);
    return removed;
  };

  const addItem = (arr, item) => {
    arr.push(item);
    return arr;
  };

  const removeItem = (src, indexSrc) => {
    const srcArr = Array.from(src);
    srcArr.splice(indexSrc, 1);
    return srcArr;
  };

  const reorder = (src, indexSrc, indexDest) => {
    const srcArr = Array.from(src);
    const [removed] = srcArr.splice(indexSrc, 1);
    srcArr.splice(indexDest, 0, removed);

    return srcArr;
  };

  const handleRemove = useCallback(
    async (src, indexSrc) => {
      const result = removeItem(src, indexSrc);

      try {
        await axios.put(
          `${APPENV.HOST_API}/projet/${projectToken}/phases/delete`,
          result,
        );
        setListePhasesProjet(result);
        setPhasesProjet(result);

        const item = getItem(src, indexSrc);
        const itemToAdd = fullDefaultListe.find((x) => x.id === item.phaseId);

        if (typeof itemToAdd !== 'undefined') {
          const arrDefault = addItem(listePhasesDefault, itemToAdd);
          setListePhasesDefault(arrDefault);
        }

        toast({
          title: t('success.delete.phase.libelle', {
            defaultValue: 'Phase supprimée',
          }),
          description: t('success.delete.phase.message', {
            defaultValue: 'Phase supprimée',
          }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (err) {
        toast({
          title: t('error.delete.phase.libelle', {
            defaultValue: 'Erreur lors de la suppression',
          }),
          description: t('error.delete.phase.message', {
            defaultValue: 'Erreur lors de la suppression',
          }),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [
      projectToken,
      toast,
      listePhasesDefault,
      fullDefaultListe,
      t,
      setPhasesProjet,
    ],
  );

  const handleReorder = useCallback(
    async (values) => {
      await axios.put(
        `${APPENV.HOST_API}/projet/${projectToken}/phases/order`,
        values,
      );
    },
    [projectToken],
  );

  const handleSubmit = async (values) => {
    const dataForm = values;
    dataForm.dateFin = formatDateDb(dateFin);
    dataForm.dateDebut = formatDateDb(dateDebut);

    try {
      setLoading(true);
      const result = await axios.post(
        `${APPENV.HOST_API}/projet/${projectToken}/phases/insert`,
        values,
      );
      const phasesResult = Object.values(result?.data)?.map((phase) => {
        const libelle = phase.data.phaseId === null
          ? phase.data.phaseLibelleCustom
          : t(phase.data.phaseLibelle, {
            defaultValue: phase.data.phaseLibelle,
          });
        return {
          label: libelle,
          codePhase: `${phase.data.codePhase}`,
          id: `${phase.data.id}`,
          phaseId: `${phase.data.phaseId}`,
          dateDebut: `${phase.data.dateDebut}`,
          dateFin: `${phase.data.dateFin}`,
          ordre: phase.data.ordre,
          statut: `${phase.data.statutLibelle}`,
          canDelete: phase.auth.canDelete,
          canUpdate: phase.auth.canUpdate,
        };
      });

      setListePhasesProjet(phasesResult);
      setPhasesProjet(phasesResult);

      const index = listePhasesDefault.findIndex((x) => x.id === values.phaseId);
      const removeFromDefault = removeItem(listePhasesDefault, index);

      setListePhasesDefault(removeFromDefault);
      setIsSubmit(true);

      toast({
        title: t('success.insert.phase.libelle', {
          defaultValue: 'Phase ajoutée',
        }),
        description: t('success.insert.phase.message', {
          defaultValue: 'Phase ajoutée',
        }),
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (err) {
      toast({
        title: t('error.insert.phase.libelle', {
          defaultValue: 'Erreur lors de la création',
        }),
        description: t('error.insert.phase.message', {
          defaultValue: 'Erreur lors de la création',
        }),
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    }
    setLoading(false);
  };

  const handleChangeDate = useCallback(
    async (id, dateType, dateValues) => {
      const formData = {};
      if (dateType === 'dateFin') {
        formData.id = id;
        formData.dateFin = formatDateDb(dateValues);
      } else {
        formData.id = id;
        formData.dateDebut = formatDateDb(dateValues);
      }

      try {
        await axios.put(
          `${APPENV.HOST_API}/projet/${projectToken}/phase/${id}/dates`,
          formData,
        );
        toast({
          title: t('success.update.phase.libelle', {
            defaultValue: 'Phase mise à jour',
          }),
          description: t('success.update.phase.message', {
            defaultValue: 'Phase mise à jour',
          }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
        await queryCache.invalidateQueries(['configuration', projectToken]);
      } catch (err) {
        toast({
          title: t('error.update.phase.libelle', {
            defaultValue: 'Erreur lors de la mise à jour',
          }),
          description: t('error.update.phase.message', {
            defaultValue: 'Erreur lors de la mise à jour',
          }),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [projectToken, toast, t],
  );

  const getListStyle = () => ({
    padding: '1.5rem',
    width: '100%',
    margin: 'auto',
  });

  // using useCallback is optional
  const onBeforeCapture = useCallback(() => {
    /* ... */
  }, []);
  const onBeforeDragStart = useCallback(() => {
    /* ... */
  }, []);
  const onDragStart = useCallback(() => {
    /* ... */
  }, []);
  const onDragUpdate = useCallback(() => { }, []);
  const onDragEnd = useCallback(
    async (datas) => {
      // dropped outside the list
      const { source, destination } = datas;

      if (!destination) {
        return;
      }

      if (
        source.droppableId === destination.droppableId
        && source.index === destination.index
      ) {
        return;
      }

      if (source.droppableId !== destination.droppableId) {
        return;
      }

      if (source.droppableId === destination.droppableId) {
        const result = reorder(
          listePhasesProjet,
          source.index,
          destination.index,
        );
        setListePhasesProjet(result);
        await handleReorder(result);
      }
    },
    [listePhasesProjet, handleReorder],
  );

  return (
    <>
      <Stack spacing={6} pl={4} pr={4}>
        <Card
          w={{
            base: '100%',
            md: '80%',
          }}
          textAlign="center"
          m="auto"
        >
          <CardHeader>
            <CardTitle>
              <Tag variantColor="green">
                <Icon icon={FaFlag} mr={2} />
                <Text>
                  {t('phase.configuration', {
                    defaultValue: 'Configuration des phases',
                  })}
                </Text>
              </Tag>
              <Alert status="info" mt={4}>
                <AlertIcon />
                {t('phase.description.draganddrop')}
              </Alert>
            </CardTitle>
          </CardHeader>
          <DragDropContext
            onBeforeCapture={onBeforeCapture}
            onBeforeDragStart={onBeforeDragStart}
            onDragStart={onDragStart}
            onDragUpdate={onDragUpdate}
            onDragEnd={onDragEnd}
          >
            <SimpleGrid columns={1} spacing={10}>
              <Droppable droppableId="projet">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {listePhasesProjet?.map((phase, index) => (
                      <Draggable
                        key={phase.id}
                        draggableId={`${phase.id}`}
                        index={index}
                      >
                        {(pvd) => (
                          <Flex
                            ref={pvd.innerRef}
                            {...pvd.draggableProps}
                            {...pvd.dragHandleProps}
                            justify="center"
                          >
                            <DraggablePhasesCard
                              id={phase.id}
                              canDelete={phase.canDelete}
                              canChangeDates={phase.canUpdate}
                              label={`${phase.label}`}
                              statut={`${phase.statut}`}
                              dateDebut={phase.dateDebut}
                              dateFin={phase.dateFin}
                              onClick={() => handleRemove(listePhasesProjet, index)}
                              onChange={handleChangeDate}
                            />
                          </Flex>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </SimpleGrid>
          </DragDropContext>
        </Card>
        <Card
          w={{
            base: '100%',
            md: '80%',
          }}
          textAlign="center"
          m="auto"
        >
          <Formiz onValidSubmit={handleSubmit} connect={phasesForm}>
            <form noValidate onSubmit={phasesForm.submit}>
              <CardHeader>
                <CardTitle>
                  <Tag variantColor="green">
                    <Icon icon={FaFlag} mr={2} />
                    <TagLabel>
                      {t('phase.header', { defaultValue: 'Phases' })}
                    </TagLabel>
                  </Tag>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Grid
                  templateColumns={{
                    base: '1fr',
                  }}
                  alignItems="center"
                  p={6}
                >
                  <Heading size="sm" as="div">
                    Recherchez parmis les phases disponibles, ou ajouter en une.
                  </Heading>
                  <Flex flexWrap="wrap">
                    {listePhasesDefault?.map((phase) => (
                      <Tag key={phase.id} m={2} maxW={300} variantColor="gray">
                        <Text fontSize="xs">
                          {`[ ${phase.codePhase}] ${phase.label} `}
                        </Text>
                      </Tag>
                    ))}
                  </Flex>
                </Grid>
                <Grid
                  templateColumns={{
                    base: '1fr',
                  }}
                  alignItems="center"
                  p={6}
                >
                  <Box
                    rounded="md"
                    borderWidth="1px"
                    borderStyle="dashed"
                    m={2}
                    position="relative"
                  >
                    <AutoCompletePhase
                      dateDebut={dateDebut}
                      dateFin={dateFin}
                      setDateDebut={setDateDebut}
                      setDateFin={setDateFin}
                      name="autocomplete"
                      required="This is required"
                      placeholder="Start date"
                      listeDefault={listePhasesDefault}
                      typeInput="phase"
                      isSubmit={isSubmit}
                      setIsSubmit={setIsSubmit}
                    />
                  </Box>
                </Grid>
                <Stack p={4}>
                  <Enregistrer
                    label="button.ajouterauprojet.libelle"
                    isLoading={isLoading}
                    disabled={
                      isLoading
                      || (!phasesForm.isValid && phasesForm.isSubmitted)
                    }
                  />
                </Stack>
              </CardBody>
            </form>
          </Formiz>
        </Card>
      </Stack>
    </>
  );
};

DatasPhasesConfiguration.propTypes = {
  projetPhases: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      budget: PropTypes.oneOfType([
        PropTypes.oneOf([null]).isRequired,
        PropTypes.number,
        PropTypes.string,
      ]),
      variation: PropTypes.oneOfType([
        PropTypes.oneOf([null]).isRequired,
        PropTypes.number,
      ]),
      avancement: PropTypes.oneOfType([
        PropTypes.oneOf([null]).isRequired,
        PropTypes.number,
        PropTypes.string,
      ]),
      libelleCustom: PropTypes.string,
      libelle: PropTypes.string,
      dateDebut: PropTypes.string,
      dateFin: PropTypes.string,
      moyenneDocumentsValides: PropTypes.number,
    }).isRequired,
  ),
  defaultPhases: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      valeur: PropTypes.string,
      ordre: PropTypes.number,
    }).isRequired,
  ),
  setPhasesProjet: PropTypes.func.isRequired,
};

DatasPhasesConfiguration.defaultProps = {
  projetPhases: [],
  defaultPhases: [],
};
