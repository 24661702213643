import React, { useEffect, useState } from 'react';
import {
  Text,
  Stack,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { PageContent } from 'layout';
import { useAuthentificationContext } from 'context/auth-context';
import { Redirect } from 'react-router-dom';
import { Spinner } from 'components';

export const Signout = () => {
  const { logOut } = useAuthentificationContext();
  const [runRedirect, setRunRedirect] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    logOut();
    const timer = setTimeout(() => {
      setRunRedirect(true);
    }, 2000);
    return () => clearTimeout(timer);
  });

  if (runRedirect) {
    return (<Redirect to="/login" />);
  }

  return (
    <>
      <PageContent m={0}>
        <Stack
          mx="auto"
          my="auto"
        >
          <Stack
            d="flex"
            flexDirection="row"
          >
            <Text
              my="auto"
              mr="auto"
            >
              {t('login.signout', { defaultValue: 'Déconnexion' })}
              {' '}
              <Spinner color="success.500" size="xs" />

            </Text>
          </Stack>
        </Stack>
      </PageContent>
    </>
  );
};
