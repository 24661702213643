import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Text, Flex, Spinner, Stack, Button,
} from '@chakra-ui/core';
import { Icon } from 'components';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { handleDownload } from 'services/files';
import { FormatDate } from 'services/utils';
import { wrapStr } from 'functionsHelper/Dashboard/wrapStr';

export const TitleDocument = ({
  label, dateImport, nomImport, prenomImport, urlTarget, displayLoadBy = true, ...props
}) => {
  const { t } = useTranslation();
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  return (
    <Flex
      flexDirection="column"
      {...props}
      h="auto"
    >
      {/* Icone + titre */}
      <Flex>

        {/* Titre */}
        <Stack
          isInline
          pl={0}
        >
          <Button
            px={2}
            variantColor="brand"
            variant="ghost"
            icon={FaCloudDownloadAlt}
            onClick={(e) => {
              e.preventDefault();
              handleDownload(`${urlTarget}`, 'file', setIsLoadingDownload);
            }}
            _hover={{
              backgroundColor: 'brand.100',
            }}
          >
            <Text
              d="flex"
              color="green.500"
              _hover={{
                color: 'blue',
              }}
              fontSize={{ base: 'xs', lg: 'xs' }}
              wordBreak="break-word"
            >
              {wrapStr(label, 25)}
              {isLoadingDownload && (
                <Spinner size="1.2rem" color="brand.500" ml="1rem" />
              )}

              {!isLoadingDownload && (
                <Icon size="1.2rem" icon={FaCloudDownloadAlt} color="brand.500" ml="1rem" />
              )}

            </Text>
          </Button>
        </Stack>
      </Flex>

      {/* Chargé le  */}
      {
        displayLoadBy && (
          <Flex
            p={{ base: 0, md: 2 }}
          >
            <Text
              mb={0}
              as="i"
              fontSize="xs"
              color="gray.400"
              wordBreak="break-word"
            >
              {t('documents.loaded', {
                date: `${FormatDate(dateImport, t('core.lang.shortcut'))}`,
                nomprenom: `${nomImport ?? ''} ${prenomImport ?? ''}`,
                default: `Chargé le ${FormatDate(dateImport)} par ${nomImport ?? ''} ${prenomImport ?? ''}`,
              })}
            </Text>
          </Flex>
        )
      }

    </Flex>
  );
};

TitleDocument.propTypes = {
  label: PropTypes.string.isRequired,
  dateImport: PropTypes.string.isRequired,
  nomImport: PropTypes.string,
  prenomImport: PropTypes.string,
  urlTarget: PropTypes.string.isRequired,
  displayLoadBy: PropTypes.bool,
};

TitleDocument.defaultProps = {
  displayLoadBy: true,
  nomImport: null,
  prenomImport: null,
};
