import React from 'react';
import {
  PseudoBox, Icon, Button, Tooltip,
} from '@chakra-ui/core';
import PropTypes from 'prop-types';

export const Supprimer = ({
  onOpen, label, ...props
}) => (
  <>
    <Tooltip
      hasArrow
      placement="top"
      label={label}
    >
      <PseudoBox
        rounded="0.3rem"
        p="0.5rem"
        d="flex"
        alignItems="center"
        cursor="pointer"
        backgroundColor="white"
        {...props}
      >
        <PseudoBox
          h="1.5rem"
          w="1.5rem"
          color="green.800"
          d="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            background="white"
            onClick={onOpen}
          >
            <Icon name="close" />
          </Button>
        </PseudoBox>
      </PseudoBox>
    </Tooltip>
  </>
  );

Supprimer.propTypes = {
  onOpen: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};
