import Axios from 'axios';
import { useMutation } from 'react-query';
import { APPENV } from 'services/config';

export const useDeleteDocument = (config) => useMutation(({
    id,
    projectToken,
}) => {
    const url = `${APPENV.HOST_API}/projet/${projectToken}/document/${id}`;

    const response = Axios.delete(`${url}`);

    return response;
}, config);
