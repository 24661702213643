import React from 'react';
import {
  Button,
  Text,
  Select,
  Flex,
} from '@chakra-ui/core';
import { ReadProfile } from 'pages/Profile/read-profile';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageHeader, PageContent, PageFooter } from 'layout';
import { updateLanguage } from 'config/i18next';

export const Profile = () => {
  const { t } = useTranslation();

  const handleChangeLang = (event) => {
    updateLanguage(event.target.value);
  };

  return (
    <>
      <PageHeader
        p={4}
      >
        <Text
          as="b"
          fontSize={{ base: 'md', lg: 'lg' }}
          color="brand.800"
          fontWeight="500"
        >
          {t('profile', { defaultValue: 'My Profile' })}
        </Text>
      </PageHeader>
      <PageContent
        mb="4rem"
      >
        <Flex
          flexDirection="column"
          p={4}
          mx="auto"
          w={{
            base: '100%', lg: '70%', xl: '50%',
          }}
        >
          <Flex
            w="100%"
            mb={{ base: 2 }}
          >
            <Text
              my="auto"
              mr="1rem"
            >
              {t('label.langue', { defaultValue: 'Langue' })}
            </Text>
            <Select
              //  defaultValue={i18next.language}
              defaultValue="fr"
              onChange={handleChangeLang}
            >
              <option value="fr">Français</option>
            </Select>
          </Flex>
          <ReadProfile />
          <Flex
            w="100%"
          >
            <Button
              as={Link}
              to="/signout"
              w="full"
              mt={3}
              variantColor="danger"
            >
              {t('logout', { defaultValue: 'Logout' })}
            </Button>
          </Flex>
        </Flex>
      </PageContent>
      <PageFooter />
    </>
  );
};
