import React from 'react';
import PropTypes from 'prop-types';
import { Badge as BadgeChakra } from '@chakra-ui/core';

export const Badge = ({ size, ...props }) => {
  const getStyles = () => {
    switch (size) {
      case '2xs': return { px: 2, py: 1, fontSize: '2xs' };
      case 'xs': return { px: 2, py: 1, fontSize: 'xs' };
      case 'sm': return { px: 2, py: 1, fontSize: 'sm' };
      case 'md': return { px: 3, py: 1, fontSize: 'md' };
      case 'lg': return { px: 3, py: 1, fontSize: 'lg' };
      case 'xl': return { px: 4, py: 1, fontSize: 'xl' };
      default: {
        return { px: 2, py: 1, fontSize: '2xs' };
      }
    }
  };

  return (
    <BadgeChakra textTransform="none" borderRadius="full" {...getStyles()} {...props} />
  );
};

Badge.propTypes = {
  size: PropTypes.string,
};

Badge.defaultProps = {
  size: '2xs',
};
