import React, { createContext, useState } from 'react';
import { PseudoBox } from '@chakra-ui/core';

export const CardContext = createContext();

export const Card = (props) => {
  const [withActionButton, setWithActionButton] = useState(false);

  return (
    <CardContext.Provider value={{ withActionButton, setWithActionButton }}>
      <PseudoBox
        position="relative"
        bg="white"
        borderRadius="lg"
        boxShadow="card"
        borderWidth="1px"
        borderColor="gray.50"
        w="100%"
        {...props}
      />
    </CardContext.Provider>
  );
};
