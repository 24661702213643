import React, { useState } from 'react';
import {
  Button,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
} from '@chakra-ui/core';
import { Formiz, useForm } from '@formiz/core';
import { FieldInput } from 'components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNumber } from '@formiz/validations';
import { APPENV } from 'services/config';
import axios from 'axios';
import { queryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import { Enregistrer } from 'components/Button/Enregistrer';

export const EditLot = ({
  isOpen, onClose, id, avancementLot, budgetLot,
}) => {
  const updateLotForm = useForm();
  const { t } = useTranslation();
  const { projectToken } = useParams();
  const { phaseId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async (values) => {
    const avancement = values.avancement !== '' ? parseFloat(values.avancement) : null;
    const budget = values.budget !== '' ? parseFloat(values.budget) : null;

    const formData = {
      avancement,
      budget,
    };

    try {
      setLoading(true);
      await axios.put(`${APPENV.HOST_API}/projet/${projectToken}/lot/${id}`, formData);
    } catch (err) {
      toast({
        title: 'Lot not updated',
        description: 'Failed to update',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    }

    setLoading(false);
    onClose();
    await queryCache.invalidateQueries([['phaseId', 'projectToken'], [parseInt(phaseId, 10), projectToken]]);
    await queryCache.invalidateQueries('projectToken', projectToken);
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('modal.entete.edit.lot', { defaultValue: 'Edit Lot' })}
        </ModalHeader>
        <ModalCloseButton />
        <Formiz onValidSubmit={handleSubmit} connect={updateLotForm}>
          <form noValidate onSubmit={updateLotForm.submit}>
            <ModalBody>
              <FieldInput
                value="test"
                defaultValue={avancementLot}
                name="avancement"
                label={t('lot.libelle.avancement', { defaultValue: 'Avancement du lot' })}
                placeholder={t('lot.placeholder.avancement', { defaultValue: 'Saisissez l\'avancement (0-100)' })}
                validations={[
                  {
                    rule: isNumber(),
                    message: 'This is not a number',
                  },
                ]}
              />
              <FieldInput
                name="budget"
                defaultValue={budgetLot}
                label={t('lot.libelle.budget', { defaultValue: 'Budget du lot' })}
                placeholder={t('lot.placeholder.budget', { defaultValue: 'Saisissez le budget' })}
                validations={[
                  {
                    rule: isNumber(),
                    message: 'This is not a number',
                  },
                ]}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                variantColor="gray"
                onClick={onClose}
              >
                {t('cancel', { defaultValue: 'Cancel' })}
              </Button>
              {/* Bouton enregistrer  */}
              <Enregistrer
                isLoading={isLoading}
              />
            </ModalFooter>
          </form>
        </Formiz>
      </ModalContent>
    </Modal>
  );
};

EditLot.propTypes = {
  isOpen: PropTypes.bool,
  avancementLot: PropTypes.oneOfType([PropTypes.oneOf([null]).isRequired, PropTypes.number, PropTypes.string]),
  budgetLot: PropTypes.oneOfType([PropTypes.oneOf([null]).isRequired, PropTypes.number, PropTypes.string]),
  onClose: PropTypes.func,
  id: PropTypes.number.isRequired,
};

EditLot.defaultProps = {
  isOpen: false,
  budgetLot: null,
  avancementLot: null,
  onClose: () => { },
};
