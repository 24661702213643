import React, { useState } from 'react';
import {
 Stack, Input, ListIcon, ListItem, List,
} from '@chakra-ui/core';
import { FieldInput, FieldSelect } from 'components/Fields';
import Autosuggest from 'react-autosuggest';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FaPlusCircle } from 'react-icons/fa';
import { isEmail } from '@formiz/validations';

export const AutoCompleteIntervenants = (props) => {
  const {
    entreprises,
    roles,
    listeDefault,
    typeInput,
    isSubmit,
    setIsSubmit,
  } = props;

  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');
  const [id, setId] = useState(null);
  const [isSelected, setIsSelected] = useState(false);

  const [prenom, setPrenom] = useState(null);
  const [nom, setNom] = useState(null);
  const [entrepriseId, setEntrepriseId] = useState(null);

  const { t } = useTranslation();

  const escapeRegexCharacters = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const theme = {
    container: {
      position: 'relative',
    },
    inputFocused: {
      outline: 'none',
    },
    inputOpen: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
      textAlign: 'left',
    },
    suggestion: {
      cursor: 'pointer',
      padding: '10px 20px',
    },
    suggestionHighlighted: {
      backgroundColor: '#ddd',
    },
  };

  const getInputValue = (suggestion) => {
    if (suggestion.isAddNew) {
      return value;
    }

    return suggestion.email;
  };

  const getSuggestionValue = (suggestion) => {
    if (suggestion.isAddNew) {
      return value;
    }

    return suggestion;
  };

  const getSuggestions = (val) => {
    const escapedValue = escapeRegexCharacters(val.trim());
    if (escapedValue === '') {
      return [];
    }

    const regex = new RegExp(`^${escapedValue}`, 'i');
    const suggestionNom = listeDefault.filter((intervenant) => regex.test(getSuggestionValue(intervenant.nom)));
    const suggestionPrenom = listeDefault.filter((intervenant) => regex.test(getSuggestionValue(intervenant.prenom)));
    const suggestionEmail = listeDefault.filter((intervenant) => regex.test(getSuggestionValue(intervenant.email)));
    const suggestionEntreprise = listeDefault.filter((intervenant) => regex.test(getSuggestionValue(intervenant.entrepriseNom)));

    const listeSuggestions = [
      ...suggestionNom,
      ...suggestionPrenom,
      ...suggestionEmail,
      ...suggestionEntreprise,
    ];
    const uniqueSuggestions = Array.from(
      new Set(listeSuggestions.map((a) => a.id)),
    ).map((phaseId) => listeSuggestions.find((a) => a.id === phaseId));
    if (uniqueSuggestions.length === 0) {
      return [{ isAddNew: true }];
    }
    return uniqueSuggestions;
  };

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => {
    if (suggestion.isAddNew) {
      return (
        <>
          <span>
            {`[+] ${t('intervenant.new', {
              defaultValue: ' Ajouter un nouvel intervenant',
            })}`}
            <strong>{value}</strong>
          </span>
        </>
      );
    }

    return (
      <List data-id={id} key={suggestion.id} spacing={3} listStyleType="none">
        <ListItem listStyleType="none">
          <ListIcon icon={FaPlusCircle} color="green.500" />
          {`${suggestion.email} - ${suggestion.prenom} ${suggestion.nom}`}
        </ListItem>
      </List>
    );
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    setIsSelected(false);
    setIsSubmit(false);
    setId(null);
    setEntrepriseId(null);
    setNom(null);
    setPrenom(null);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = (inputData) => {
    setSuggestions(getSuggestions(inputData.value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    setIsSelected(true);
    setIsSubmit(false);
    setId(suggestion.id);
    setEntrepriseId(suggestion.entrepriseId);
    setNom(suggestion.nom);
    setPrenom(suggestion.prenom);
    if (suggestion.isAddNew) {
      setId(null);
    }
  };

  // POUR AUTO SUGGESTION
  const inputProps = {
    placeholder: t('intervenant.placeholder.search', {
      defaultValue: 'Rechercher un contact',
    }),
    onChange,
    value,
  };

  const renderInputComponent = (inputProperties) => (
    <Stack spacing={3} p={4}>
      <Input
        focusBorderColor="brand.500"
        variant="outline"
        width={{
          base: '100%', // base
          sm: '100%%', // 480px upwards
          md: '100%', // 768px upwards
          lg: '50%', // 992px upwards
        }}
        {...inputProperties}
      />
    </Stack>
  );

  const renderSuggestionsContainer = (datas) => {
    const { containerProps, children, query } = datas;
    return (
      <>
        <div {...containerProps}>
          <div>{children}</div>
        </div>

        {isSelected && !isSubmit && typeInput === 'intervenant' && (
          <>
            <Stack p={4}>
              <Stack p={4}>
                <FieldInput
                  helper="Required email"
                  w={{ base: '100%', md: '50%', lg: '25%' }}
                  typeInput="email"
                  name="contact_email"
                  label={`${t('intervenant.libelle.email', {
                    defaultValue: "Email de l'intervenant",
                  })}`}
                  required={`${t('errors.required', {
                    defaultValue: 'Ce champ est requis',
                  })}`}
                  placeholder={`${t('intervenant.placeholder.email', {
                    defaultValue: "Email de l'intervenant",
                  })}`}
                  defaultValue={query}
                  validations={[
                    {
                      rule: isEmail(),
                      message: t('errors.isEmail', {
                        defaultValue: 'Invalid email',
                      }),
                    },
                  ]}
                />
              </Stack>
              <Stack isInline p={4}>
                <FieldInput
                  w={{ base: '100%', md: '50%', lg: '25%' }}
                  typeInput="text"
                  name="contact_nom"
                  defaultValue={nom}
                  label={`${t('intervenant.libelle.nom', {
                    defaultValue: "Nom de l'intervenant",
                  })}`}
                  placeholder={`${t('intervenant.placeholder.nom', {
                    defaultValue: "Nom de l'intervenant",
                  })}`}
                />
                <FieldInput
                  w={{ base: '100%', md: '50%', lg: '25%' }}
                  typeInput="text"
                  name="contact_prenom"
                  defaultValue={prenom}
                  label={`${t('intervenant.libelle.prenom', {
                    defaultValue: "Prénom de l'intervenant",
                  })}`}
                  placeholder={`${t('intervenant.placeholder.prenom', {
                    defaultValue: "Prénom de l'intervenant",
                  })}`}
                />
              </Stack>
              <Stack isInline p={4}>
                <FieldSelect
                  w={{ base: '100%', md: '50%', lg: '25%' }}
                  label="Role"
                  name="contact_role"
                  helper={`${t('intervenant.helper.role', {
                    defaultValue: 'Vous devez sélectionner un role',
                  })}`}
                  required={`${t('errors.required', {
                    defaultValue: 'Champ requis',
                  })}`}
                  placeholder={`${t('intervenant.placeholder.role', {
                    defaultValue: 'Role',
                  })}`}
                  options={roles}
                />
                <FieldSelect
                  w={{ base: '100%', md: '50%', lg: '25%' }}
                  label="Entreprise"
                  name="entreprise_id"
                  helper={`${t('intervenant.helper.entreprise', {
                    defaultValue: 'Vous devez sélectionner une entreprise',
                  })}`}
                  required={`${t('errors.required', {
                    defaultValue: 'Champ requis',
                  })}`}
                  placeholder={`${t('intervenant.placeholder.entreprise', {
                    defaultValue: 'Entreprise',
                  })}`}
                  options={entreprises}
                  defaultValue={entrepriseId}
                />
              </Stack>
            </Stack>
          </>
        )}
      </>
    );
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getInputValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
      theme={theme}
    />
  );
};

AutoCompleteIntervenants.propTypes = {
  listeDefault: PropTypes.arrayOf(PropTypes.object).isRequired,
  typeInput: PropTypes.string.isRequired,
  isSubmit: PropTypes.bool.isRequired,
  setIsSubmit: PropTypes.func.isRequired,
  entreprises: PropTypes.arrayOf(PropTypes.object).isRequired,
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
};
