/* eslint-disable no-console */
import {
  Flex, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalBody, ModalCloseButton, Button, ModalFooter,
}
  from '@chakra-ui/core';
import { Navbar, ViewportHeight } from 'layout';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from 'Routes';
import { useAuthentificationContext } from 'context/auth-context';
import { APPENV } from 'services/config';
import axios from 'axios';
import refbuild from 'services/build.json';
import { useTranslation } from 'react-i18next';

export const App = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLogged } = useAuthentificationContext();
  const { t } = useTranslation();

  useEffect(() => {
    // const clientRefBuild = window.localStorage.getItem('refbuild');
    window.localStorage.setItem('mustReloadApp', '0');
    const interval = setInterval(() => {
      const mustReloadApp = window.localStorage.getItem('mustReloadApp') ?? '0';
      // Deja eu l'avertissement
      if (mustReloadApp === '1') {
        console.log('must reload');
        return;
      }

      // Lecture de la conf
      axios.get(`${APPENV.HOST_FRONT}/build.json`).then((response) => {
        // Comparaison avec le localstorage
        if (refbuild.refbuild !== response.refbuild) {
          onOpen();
          console.log(`${refbuild.refbuild}!==${response.refbuild}`);
          window.localStorage.setItem('mustReloadApp', '1');
          console.log('mustReloadApp = 1');
        } else {
          console.log('build ok');
        }
      }).catch(() => {
        console.log('error loading build.json');
      });
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Router>
        <ViewportHeight bg="lightWhite" direction="row">
          {/* navbar */}
          {isLogged && (
            <>
              <Navbar />
            </>
          )}
          {/* Router */}
          <Flex
            direction="column"
            w={{
              base: '85%',
              sm: '85%',
              md: '85%',
              lg: '100%',
            }}
            ml={isLogged ? { base: '15%', lg: '6%' } : 0}
          >
            <Routes />
          </Flex>
        </ViewportHeight>
      </Router>
      <Modal onClose={onClose} size="md" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="brand.500">{t('newVersion')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {t('reloadPage')}
          </ModalBody>
          <ModalFooter>
            <Button
              backgroundColor="gray.300"
              onClick={() => onClose()}
              _hover={{
                backgroundColor: 'gray.200',
              }}
            >
              {t('ignore')}
            </Button>
            <Button
              ml={2}
              color="white"
              backgroundColor="brand.500"
              onClick={() => window.location.reload()}
              _hover={{
                backgroundColor: 'brand.400',
              }}
            >
              {t('refresh')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
