import React from 'react';
import PropTypes from 'prop-types';

export const FormatNumber = ({
  value, deci, currency, defaultValue, locale, transformZeroToTiret,
}) => {
  const propCurrency = currency ? {
    style: 'currency',
    currency: 'EUR',
  } : {};
  const valueParsed = parseFloat(value);

  if (value === null) {
    return (<>--</>);
  }

  if (Number.isNaN(valueParsed)) {
    return (<>nan</>);
  }

  if (transformZeroToTiret) {
    if (valueParsed === 0) {
      return (<>--</>);
    }
  } else if (valueParsed === 0) {
      return (<>0</>);
    }

  const formatedValue = new Intl.NumberFormat(locale, {
    ...propCurrency,
    minimumFractionDigits: deci,
    maximumFractionDigits: deci,
  }).format(valueParsed ?? defaultValue);
  return <>{formatedValue}</>;
};

FormatNumber.propTypes = {
  deci: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  locale: PropTypes.string,
  currency: PropTypes.bool,
  transformZeroToTiret: PropTypes.bool,
};

FormatNumber.defaultProps = {
  value: '--',
  deci: 0,
  currency: false,
  defaultValue: '--',
  locale: 'fr-FR',
  transformZeroToTiret: true,
};
