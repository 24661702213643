import React, { useContext } from 'react';
import { Collapse } from '@chakra-ui/core';
import { CollapseContext } from './Collapse';

export const CollapsePanel = (props) => {
  const { variant, isOpen } = useContext(CollapseContext);

  const getStyles = () => {
    if (variant === 'card') {
      return {
        borderColor: 'gray.50',
        boxShadow: 'card',
        mt: -1,
        pt: 1,
      };
    }

    return {
      backgroundColor: 'lightWhite',
    };
  };

  return (
    <Collapse
      isOpen={isOpen}
      borderWidth="1px"
      borderTopWidth="0"
      borderBottomLeftRadius="lg"
      borderBottomRightRadius="lg"
      {...getStyles()}
      {...props}
    />
  );
};
