import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress as ChakraCircularProgress } from '@chakra-ui/core';

export const CircularProgress = ({ size, variantColor, ...props }) => (
  <ChakraCircularProgress
    flex="none"
    thickness={0.6}
    w={`circularProgress.${size}`}
    h={`circularProgress.${size}`}
    color={variantColor}
    trackColor={variantColor}
    {...props}
  />
);

CircularProgress.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl']),
  variantColor: PropTypes.string,
};

CircularProgress.defaultProps = {
  variantColor: 'brand',
  size: 'md',
};
