import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from '@chakra-ui/core';
import { FormatNumber } from 'components/FormatNumber';

export const Progress = ({
  size, value, color, hideLabel, ...props
}) => {
  let valueParsed = parseFloat(value);

  if (value === null) {
    valueParsed = 0;
  }

  const getStyles = () => {
    switch (size) {
      case 'xs': return { h: 1 };
      case '2xs': return { h: 2 };
      case 'sm': default: return { h: 3 };
      case 'md': return { h: 4 };
      case 'lg': return { h: 5 };
      case 'xl': return { h: 6 };
    }
  };

  return (
    <Flex w="100%" align="center" {...props}>
      <Box
        bg="gray.50"
        w="100%"
        borderRadius="full"
        overflow="hidden"
      >
        <Box
          width={`${valueParsed}%`}
          bg={color}
          {...getStyles()}
        />
      </Box>
      {!hideLabel && (
        <Text
          fontSize={size}
          whiteSpace="nowrap"
          ml="3"
        >
          <FormatNumber value={value} />
          %
        </Text>
      )}
    </Flex>
  );
};

Progress.propTypes = {
  size: PropTypes.oneOf(['2xs', 'xs', 'sm', 'md', 'lg', 'xl']),
  value: PropTypes.number,
  color: PropTypes.string,
  hideLabel: PropTypes.bool,
};

Progress.defaultProps = {
  size: 'sm',
  value: 0,
  color: 'gray.600',
  hideLabel: false,
};
