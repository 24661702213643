import React from 'react';
import {
  SimpleGrid,
  useDisclosure,
  Flex,
} from '@chakra-ui/core';
import {
  Collapse, CollapseHeader, CollapsePanel, CollapseBody,
} from 'components';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { LivrableDetails } from '../LivrableDetails';
import { ItemDetails } from '../ItemDetails';
import { EditLot } from './EditLot';

export const LotDetails = ({
  lot: {
    id,
    budget,
    avancement,
    budgetCourant,
    // eslint-disable-next-line no-unused-vars
    budgetPrecedent,
    lotLibelle,
    lotLibelleCustom,
    moyenneDocumentsValides,
    phaseId,
    variation,
    variationPourcentage,
  },
  auth,
  dateDebut,
  dateFin,
  livrables,
  actionCountDocumentsDeposesByLotId,
  actionCountDocumentsRefusesByLotId,
  phaseStatut,
}) => {
  const detail = {
    id,
    budget,
    avancement,
    budgetCourant,
    variation,
    variationPourcentage,
    libelle: lotLibelle,
    libelleCustom: lotLibelleCustom,
    dateDebut,
    dateFin,
    moyenneDocumentsValides,
  };

  

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const show = parseInt(params.get('lotId'), 10) === id;
  return (
    <SimpleGrid
      id={id}
      columns={1}
      spacing={6}
      mt={5}
    >
      <Collapse spacing={4} variant="card" state={show}>
        <CollapseHeader p={0}>
          <Flex>
            <ItemDetails
              isLot
              livrables={livrables}
              withLabel
              withLegends={false}
              detail={detail}
              actionCountDocumentsDeposesByLotId={actionCountDocumentsDeposesByLotId}
              actionCountDocumentsRefusesByLotId={actionCountDocumentsRefusesByLotId}
              auth={auth}
              onOpen={onOpen}
              phaseStatut={phaseStatut}
            />
          </Flex>
        </CollapseHeader>
        <CollapsePanel>
          <CollapseBody>
            <SimpleGrid columns={1} spacing={6}>
              {
                typeof (livrables) !== 'undefined' && Object.values(livrables)?.map((livrable) => (
                  <LivrableDetails
                    actionCountDocumentsDeposesByLivrableId={livrable.actionCountDocumentsDeposesByLivrableId}
                    actionCountDocumentsRefusesByLivrableId={livrable.actionCountDocumentsRefusesByLivrableId}
                    key={livrable.data.id}
                    livrable={livrable.data}
                    auth={livrable.auth}
                    documents={livrable.documents}
                    docacharger={livrable.docacharger}
                    phaseStatut={phaseStatut}
                    phaseId={phaseId}
                  />
                ))
              }
            </SimpleGrid>
          </CollapseBody>
        </CollapsePanel>
      </Collapse>
      <EditLot
        avancementLot={avancement}
        budgetLot={budget}
        id={id}
        isOpen={isOpen}
        onClose={onClose}
      />
    </SimpleGrid>
  );
};
LotDetails.propTypes = {
  lot: PropTypes.shape({
    id: PropTypes.number,
    budget: PropTypes.oneOfType([PropTypes.oneOf([null]).isRequired, PropTypes.number, PropTypes.string]),
    budgetCourant: PropTypes.oneOfType([PropTypes.oneOf([null]).isRequired, PropTypes.number]),
    budgetPrecedent: PropTypes.oneOfType([PropTypes.oneOf([null]).isRequired, PropTypes.number, PropTypes.string]),
    avancement: PropTypes.oneOfType([PropTypes.oneOf([null]).isRequired, PropTypes.number, PropTypes.string]),
    lotLibelleCustom: PropTypes.string,
    lotLibelle: PropTypes.string,
    moyenneDocumentsValides: PropTypes.number,
    phaseId: PropTypes.number,
    variation: PropTypes.oneOfType([PropTypes.oneOf([null]).isRequired, PropTypes.number, PropTypes.string]),
    variationPourcentage: PropTypes.oneOfType([PropTypes.oneOf([null]).isRequired, PropTypes.number, PropTypes.string]),
  }).isRequired,
  livrables: PropTypes.objectOf(PropTypes.object),
  auth: PropTypes.shape({
    canRead: PropTypes.bool,
    canUpdate: PropTypes.bool,
    canDelete: PropTypes.bool,
  }),
  actionCountDocumentsDeposesByLotId: PropTypes.number.isRequired,
  actionCountDocumentsRefusesByLotId: PropTypes.number.isRequired,
  dateDebut: PropTypes.string,
  dateFin: PropTypes.string,
  phaseStatut: PropTypes.string.isRequired,
};

LotDetails.defaultProps = {
  livrables: [],
  dateDebut: '',
  auth: [],
  dateFin: '',
};
