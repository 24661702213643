export const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};
export const handleDownload = (url, type, setIsLoadingDownload) => {
  let linkUri = '';
  let fileName = '';
  setIsLoadingDownload(true);

  fetch(
    url,
    {
      headers: {
        uid: JSON.parse(localStorage.getItem('uid')),
        email: JSON.parse(localStorage.getItem('email')),
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
    },
  )
    .then(handleErrors)
    .then((response) => {
      if (response.ok && response.status === 200) {
        const contentDisposition = response.headers.get('content-disposition');
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      } else {
        throw new Error(response.status);
      }
      return response.blob();
    })
    .then((blob) => {
      if (type === 'zip') {
        linkUri = window.URL.createObjectURL(new Blob([blob], { type: 'application/zip' }));
      } else {
        linkUri = window.URL.createObjectURL(new Blob([blob]));
      }

      const link = document.createElement('a');
      link.setAttribute('download', fileName);
      link.href = linkUri;
      document.body.appendChild(link);
      link.click();
      setIsLoadingDownload(false);
    })
    .catch((error) => {
      setIsLoadingDownload(false);
      return error;
    });
};
