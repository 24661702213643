/**
 * Check si le budget de la phase en cours *110% est inférieur au budget du projet
 * @param {*} budgetPhaseEnCours
 * @param {*} budgetProjet
 */
export const checkBudgetProjet = (budgetPhaseEnCours, budgetProjet) => {
    if (budgetPhaseEnCours === null || budgetProjet === null || budgetProjet === 0) {
      return false;
    }
    const budgetMaxDeLaPhaseEnCours110pourcent = (budgetPhaseEnCours * 110) / 100;

    if (budgetMaxDeLaPhaseEnCours110pourcent < budgetProjet) {
        return false;
    }
    return true;
};
