import React, { useEffect, useContext } from 'react';
import { Box } from '@chakra-ui/core';
import { CardContext } from 'components/Card/Card';

export const CardAction = (props) => {
  const { setWithActionButton } = useContext(CardContext);

  useEffect(() => {
    setWithActionButton(true);
    return () => setWithActionButton(false);
  }, [setWithActionButton]);

  return (
    <Box
      position="absolute"
      top="3"
      right="3"
      {...props}
    />
  );
};
