import React, { useState } from 'react';
import {
  Stack, Input, ListIcon, ListItem, List, Flex,
} from '@chakra-ui/core';
import { FieldInput } from 'components/Fields';
import Autosuggest from 'react-autosuggest';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FaPlusCircle } from 'react-icons/fa';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactDatePicker } from 'components/Fields/Calendar/ReactDatePicker';

export const AutoCompletePhase = (props) => {
  const {
    listeDefault, typeInput, isSubmit, setIsSubmit, dateDebut, setDateDebut, dateFin, setDateFin,
  } = props;
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');
  const [id, setId] = useState(null);
  const [code, setCode] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const { t } = useTranslation();

  const escapeRegexCharacters = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const theme = {
    container: {
      position: 'relative',
    },
    inputFocused: {
      outline: 'none',
    },
    inputOpen: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
      textAlign: 'left',
    },
    suggestion: {
      cursor: 'pointer',
      padding: '10px 20px',
    },
    suggestionHighlighted: {
      backgroundColor: '#ddd',
    },
  };

  const getSuggestionCode = (suggestion) => {
    if (suggestion.isAddNew) {
      return value;
    }

    return suggestion.codePhase;
  };

  const getSuggestionValue = (suggestion) => {
    if (suggestion.isAddNew) {
      return value;
    }

    return suggestion.label;
  };

  const getSuggestions = (val) => {
    const escapedValue = escapeRegexCharacters(val.trim());
    if (escapedValue === '') {
      return [];
    }

    const regex = new RegExp(`^${escapedValue}`, 'i');
    const suggestionCode = listeDefault.filter((phase) => regex.test(getSuggestionCode(phase)));
    const suggestionLabel = listeDefault.filter((phase) => regex.test(getSuggestionValue(phase)));
    const listeSuggestions = [...suggestionLabel, ...suggestionCode];
    const uniqueSuggestions = Array.from(
      new Set(listeSuggestions.map((a) => a.id)),
    ).map((phaseId) => listeSuggestions.find((a) => a.id === phaseId));
    if (uniqueSuggestions.length === 0) {
      return [{ isAddNew: true }];
    }

    return uniqueSuggestions;
  };

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => {
    if (suggestion.isAddNew) {
      return (
        <>
          <span>
            {`[+] ${t('phase.new', {
              defaultValue: 'Ajouter une nouvelle phase',
            })}`}
            <strong>{value}</strong>
          </span>
        </>
      );
    }

    return (
      <List key={suggestion.id} spacing={3} listStyleType="none">
        <ListItem listStyleType="none">
          <ListIcon icon={FaPlusCircle} color="green.500" />
          {suggestion.label}
        </ListItem>
      </List>
    );
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    setIsSelected(false);
    setIsSubmit(false);
    setId(null);
    setCode(null);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = (inputData) => {
    setSuggestions(getSuggestions(inputData.value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    setIsSelected(true);
    setIsSubmit(false);
    setId(suggestion.id);
    setCode(suggestion.codePhase);
    if (suggestion.isAddNew) {
      setId(null);
      setCode(null);
    }
  };

  // POUR AUTO SUGGESTION
  const inputProps = {
    placeholder: t('phase.placeholder.search', {
      defaultValue: 'Recherche une phase',
    }),
    onChange,
    value,
  };

  const renderInputComponent = (inputProperties) => (
    <Stack spacing={3} p={4}>
      <Input
        focusBorderColor="brand.500"
        variant="outline"
        width={[
          '100%', // base
          '100%', // 480px upwards
          '100%', // 768px upwards
          '50%', // 992px upwards
        ]}
        {...inputProperties}
      />
    </Stack>
  );

  const renderSuggestionsContainer = (datas) => {
    const { containerProps, children, query } = datas;
    return (
      <>
        <div {...containerProps}>
          <div>{children}</div>
        </div>

        {isSelected && !isSubmit && typeInput === 'phase' && (
          <Stack p={4}>
            <FieldInput
              name="phaseLibelle"
              hidden
              defaultValue={query}
              required="This is required"
            />
            <FieldInput name="phaseId" hidden defaultValue={id} />
            <FieldInput
              d="flex"
              flexDirection="column"
              justifyContent="start"
              w={{ base: '100%', md: '50%', lg: '25%' }}
              name="codePhase"
              label={`${t('phase.libelle.codephase', {
                defaultValue: 'Code de la phase',
              })}`}
              required={`${t('errors.required', {
                defaultValue: 'Ce champ est requis',
              })}`}
              placeholder={`${t('phase.placeholder.codephase', {
                defaultValue: 'Code de la phase',
              })}`}
              defaultValue={code}
            />
            <Flex
              justifyContent="space-around"
            >
              <ReactDatePicker
                w="30%"
                selected={dateDebut}
                onChange={(date) => setDateDebut(date)}
                label={t('phase.libelle.datedebut')}

              />

              <ReactDatePicker
                w="30%"
                selected={dateFin}
                onChange={(date) => setDateFin(date)}
                label={t('phase.libelle.datefin')}
              />

              {/* <FieldDatePicker
                d="flex"
                flexDirection="column"
                justifyContent="start"
                w="100%"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                defaultValue={Date('now')}
                name="dateDebut"
                format="dd/MM/yyyy"
                label={`${t('phase.libelle.datedebut', {
                  defaultValue: 'Date de début de la phase',
                })}`}
                required={`${t('errors.required', {
                  defaultValue: 'Ce champ est requis',
                })}`}
              />
              <FieldDatePicker
                d="flex"
                flexDirection="column"
                justifyContent="start"
                w="100%"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                defaultValue={Date('now')}
                name="dateFin"
                format="dd/MM/yyyy"
                label={`${t('phase.libelle.datefin', {
                  defaultValue: 'Date de fin de la phase',
                })}`}
                required={`${t('errors.required', {
                  defaultValue: 'Ce champ est requis',
                })}`}
              /> */}
            </Flex>
          </Stack>
        )}
      </>
    );
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
      theme={theme}
    />
  );
};

AutoCompletePhase.propTypes = {
  listeDefault: PropTypes.arrayOf(PropTypes.object).isRequired,
  typeInput: PropTypes.string.isRequired,
  isSubmit: PropTypes.bool.isRequired,
  setIsSubmit: PropTypes.func.isRequired,
  setDateDebut: PropTypes.func.isRequired,
  setDateFin: PropTypes.func.isRequired,
  dateDebut: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  dateFin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
};
