import React from 'react';
import { Flex } from '@chakra-ui/core';
import PropTypes from 'prop-types';

export const CardDocumentBody = ({ children }) => (
  <Flex
    flexDirection="column"
  >
    {children}
  </Flex>
  );

CardDocumentBody.propTypes = {
  children: PropTypes.node.isRequired,
};
