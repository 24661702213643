import React from 'react';
import { Button } from '@chakra-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const Enregistrer = ({
  isLoading, handleClick, label, ...props
}) => {
  const { t } = useTranslation();

  return (
    <Button
      variantColor="brand"
      ml="auto"
      type="submit"
      isLoading={isLoading}
      onClick={handleClick}
      {...props}
    >
      {t(label) || t('enregistrer', { defaultValue: 'Enregistrer' })}
    </Button>
  );
};

Enregistrer.propTypes = {
  isLoading: PropTypes.bool,
  handleClick: PropTypes.func,
  label: PropTypes.string,
};

Enregistrer.defaultProps = {
  isLoading: false,
  handleClick: null,
  label: '',
};
