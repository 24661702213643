import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuthentificationContext } from 'context/auth-context';
import { PageContent } from 'layout';
import { Spinner, Stack } from '@chakra-ui/core';

export const Authenticated = (props) => {
  const { isLogged, initialisation } = useAuthentificationContext();
  const { pathname } = useLocation();

  if (initialisation) {
    return (
      <>
        <PageContent>
          <Stack flex={1} align="center" justify="center">
            <Spinner size="xl" color="brand.500" />
          </Stack>
        </PageContent>
      </>
    );
  }

  if (isLogged && !initialisation) {
    return <Route {...props} />;
  }

  if (!isLogged && !initialisation) {
    return <Redirect to={{ pathname: '/login', state: { from: pathname } }} />;
  }

  return <Redirect to={{ pathname: '/login', state: { from: pathname } }} />;
};
