import Axios from 'axios';
import { useMutation } from 'react-query';
import { APPENV } from 'services/config';

export const useUpdateContact = (config) => useMutation(({
    nom,
    prenom,
    designationMetier,
    adresse1,
    adresse2,
    adresse3,
    telephone1,
    telephone2,
    ville,
    codePostal,
    id,
}) => {
    const url = `${APPENV.HOST_API}/utilisateur/${id}`;

    const response = Axios.put(`${url}`, {
        nom,
        prenom,
        designationMetier,
        adresse1,
        adresse2,
        adresse3,
        telephone1,
        telephone2,
        ville,
        codePostal,
    });

    return response;
}, config);
