import React from 'react';
import { Card } from 'components/Card/Card';
import {
  Box, IconButton, Stack, Text, Flex,
} from '@chakra-ui/core';
import { CardHeader } from 'components/Card/CardHeader';
import { CardTitle } from 'components/Card/CardTitle';
import { CardDivider } from 'components/Card/CardDivider';
import { CardAction } from 'components/Card/CardAction';
import { FaTimes } from 'react-icons/fa';
import { CardBody } from 'components/Card/CardBody';
import { Formiz } from '@formiz/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormatDate } from 'services/utils';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { ReactDatePicker } from 'components/Fields/Calendar/ReactDatePicker';

export const DraggablePhasesCard = (props) => {
  const {
    id, label, canDelete, canChangeDates, onClick, onChange,
  } = props;
  const { dateDebut, dateFin, statut } = props;
  const { t } = useTranslation();
  let bg = 'white';
  switch (statut) {
    case 'statut.avenir':
      bg = 'orange.200';
      break;
    case 'statut.cloturee':
      bg = 'red.200';
      break;
    case 'statut.encours':
      bg = 'brand.200';
      break;
    default:
      bg = 'white';
  }

  return (
    <Card
      mb={4}
      border="1px dashed"
      borderRadius="md"
      borderColor="brand.500"
      width={{
        base: '100%',
        lg: '60%',
      }}
    >
      <CardHeader backgroundColor={bg}>
        <CardTitle>{label}</CardTitle>
      </CardHeader>
      <CardDivider />

      {canDelete && (
        <CardAction>
          <IconButton
            onClick={onClick}
            variantColor="brand"
            variant="ghost"
            icon={FaTimes}
            _hover={{
              borderColor: 'gray.50',
              border: '1px',
              boxShadow: 'xl',
            }}
          />
        </CardAction>
      )}

      {canChangeDates && (
        <CardBody>
          <Formiz>
            <Flex
              justifyContent="space-between"
            >
              <ReactDatePicker
                w="47%"
                selected={new Date(dateDebut)}
                onChange={(date) => onChange(id, 'dateDebut', date)}
                label={t('phase.libelle.datedebut')}
              />
              <ReactDatePicker
                w="47%"
                selected={new Date(dateFin)}
                onChange={(value) => onChange(id, 'dateFin', value)}
                label={t('phase.libelle.datefin')}
              />
              {/* <FieldDatePicker
                label={`${t('phase.libelle.datedebut', {
                  defaultValue: 'Date de début de la phase',
                })}`}
                name="dateDebut"
                format="dd/MM/yyyy"
                defaultValue={dateDebut}
                required="This is required"
                placeholder="Start date"
                onChange={(value) => onChange(id, 'dateDebut', value)}
              />
              <FieldDatePicker
                label={`${t('phase.libelle.datefin', {
                  defaultValue: 'Date de fin de la phase',
                })}`}
                name="dateFin"
                format="dd/MM/yyyy"
                defaultValue={dateFin}
                required="This is required"
                placeholder="End date"
                onChange={(value) => onChange(id, 'dateFin', value)}
              /> */}
            </Flex>
          </Formiz>
        </CardBody>
      )}

      {!canChangeDates && (
        <CardBody>
          <Formiz>
            <Stack isInline justifyContent="center">
              <Text>{FormatDate(dateDebut, t('core.lang.shortcut'))}</Text>
              <Box mx={2} as={AiOutlineArrowRight} />
              <Text>{FormatDate(dateFin, t('core.lang.shortcut'))}</Text>
            </Stack>
          </Formiz>
        </CardBody>
      )}
    </Card>
  );
};

DraggablePhasesCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  dateDebut: PropTypes.string,
  dateFin: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canChangeDates: PropTypes.bool.isRequired,
  statut: PropTypes.string.isRequired,
};

DraggablePhasesCard.defaultProps = {
  dateDebut: null,
  dateFin: null,
};
