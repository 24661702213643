import React from 'react';
import { Card } from 'components/Card/Card';
import { Grid, IconButton } from '@chakra-ui/core';
import { CardHeader } from 'components/Card/CardHeader';
import { CardTitle } from 'components/Card/CardTitle';
import { CardDivider } from 'components/Card/CardDivider';
import { CardAction } from 'components/Card/CardAction';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { CardBody } from 'components/Card/CardBody';
import { Formiz } from '@formiz/core';
import { FieldInput } from 'components/Fields';
import { useTranslation } from 'react-i18next';
import { isNumber } from '@formiz/validations';

export const DraggableLotCard = (props) => {
  const {
    id,
    label,
    canDelete,
    canUpdate,
    onClick,
    handleBudgetUpdate,
    handleAvancementUpdate,
  } = props;

  const { t } = useTranslation();

  const { statut, budget, avancement } = props;

  let formatedAvancement = parseFloat(avancement);
  let formatedBudget = parseFloat(budget);

  if (formatedAvancement === null) {
    formatedAvancement = 0;
  }

  if (formatedBudget === null) {
    formatedAvancement = 0;
  }

  if (Number.isNaN(formatedBudget)) {
    formatedBudget = 0;
  }

  if (Number.isNaN(formatedAvancement)) {
    formatedAvancement = 0;
  }

  let bg = 'white';

  switch (statut) {
    case 'statut.avenir':
      bg = 'orange.200';
      break;
    case 'statut.cloturee':
      bg = 'red.200';
      break;
    case 'statut.encours':
      bg = 'gray';
      break;
    default:
      bg = 'white';
  }

  const activeButton = !!canUpdate;

  return (
    <Card
      mb={4}
      border="1px dashed"
      borderRadius="md"
      borderColor="brand.500"
      width={{
        base: '100%',
        lg: '40%',
      }}
    >
      <CardHeader backgroundColor={bg}>
        <CardTitle>{label}</CardTitle>
      </CardHeader>
      <CardDivider />

      {canDelete && (
        <CardAction>
          <IconButton
            onClick={onClick}
            variantColor="brand"
            variant="ghost"
            icon={FaTimes}
            _hover={{
              borderColor: 'gray.50',
              border: '1px',
              boxShadow: 'xl',
            }}
          />
        </CardAction>
      )}

      <CardBody>
        <Formiz>
          <Grid
            templateColumns={{
              base: '1fr',
              md: 'repeat(2, 1fr) auto',
            }}
            gap={4}
          >
            <FieldInput
              customId={id}
              label={`${t('lot.libelle.avancement', {
                defaultValue: 'Avancement du lot',
              })}`}
              name="avancement"
              defaultValue={formatedAvancement}
              placeholder="avancement"
              isDisabled={!canUpdate}
              validations={[
                {
                  rule: isNumber(),
                  message: t('errors.isNumber', {
                    defaultValue: 'Invalid number',
                  }),
                },
              ]}
              activeRightButton={activeButton}
              textRightButton="update"
              handleClickRightButton={handleAvancementUpdate}
            />
            <FieldInput
              customId={id}
              label={`${t('phase.libelle.budget', {
                defaultValue: 'Budget du lot',
              })}`}
              name="budget"
              defaultValue={formatedBudget}
              placeholder="budget"
              isDisabled={!canUpdate}
              validations={[
                {
                  rule: isNumber(),
                  message: t('errors.isNumber', {
                    defaultValue: 'Invalid number',
                  }),
                },
              ]}
              activeRightButton={activeButton}
              textRightButton="update"
              handleClickRightButton={handleBudgetUpdate}
            />
          </Grid>
        </Formiz>
      </CardBody>
    </Card>
  );
};

DraggableLotCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  budget: PropTypes.oneOfType([
    PropTypes.oneOf([null]).isRequired,
    PropTypes.number,
    PropTypes.string,
  ]),
  avancement: PropTypes.oneOfType([
    PropTypes.oneOf([null]).isRequired,
    PropTypes.number,
    PropTypes.string,
  ]),
  onClick: PropTypes.func.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canUpdate: PropTypes.bool.isRequired,
  handleAvancementUpdate: PropTypes.func.isRequired,
  handleBudgetUpdate: PropTypes.func.isRequired,
  statut: PropTypes.string.isRequired,
};

DraggableLotCard.defaultProps = {
  budget: 0,
  avancement: 0,
};
