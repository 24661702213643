import React from 'react';
import {
  Flex,
  List,
  ListItem,
  Text,
  useTheme,
  PseudoBox,
} from '@chakra-ui/core';
import { Logo, Icon } from 'components';
import {
  Link as RouterLink,
  useRouteMatch,
} from 'react-router-dom';
import { FaEnvelope, FaUserCircle } from 'react-icons/fa';
import { MenuIcon } from 'components/Icon/customIcons';
import PropTypes from 'prop-types';
import { LogoWyzen } from 'components/LogoWyzen';
import { useTranslation } from 'react-i18next';

const MenuCorner = ({ isActive, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    {...props}
  >
    {/* eslint-disable-next-line max-len */}
    <path fillRule="evenodd" d="M19.989,0.137V0.04c0,0.032-0.001,0.064-0.001,0.096c-0.052,10.976-8.965,19.858-19.953,19.858l9.989,9.989L29.93,10.078  L19.989,0.137z" />
  </svg>
);

MenuCorner.propTypes = {
  isActive: PropTypes.bool,
};

MenuCorner.defaultProps = {
  isActive: false,
};

const NavItem = ({ to, children, ...props }) => {
  const theme = useTheme();
  const isActive = !!useRouteMatch(to);

  return (
    <PseudoBox
      as={RouterLink}
      to={to}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      h={{ base: '6rem' }}
      position="relative"
      ml={2}
      transition="color 0.3s ease"
      color={isActive ? 'brand.500' : 'lightWhite'}
      bg={isActive ? 'lightWhite' : 'brand.500'}
      borderRadius={isActive ? { base: '4.5rem 0 0 4.5rem', md: '10rem 0 0 10rem' } : 'none'}
      _hover={{
        color: isActive ? 'brand.500' : theme.colors.brand[200],
      }}
      focus={{
        color: isActive ? 'brand.500' : theme.colors.brand[200],
      }}
      {...props}
    >
      {children}
      <MenuCorner
        isActive={isActive}
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          fill: theme.colors.lightWhite,
          opacity: isActive ? '1' : '0',
          right: '-2px',
          bottom: '98%',
        }}
      />
      <MenuCorner
        isActive={isActive}
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          fill: theme.colors.lightWhite,
          opacity: isActive ? '1' : '0',
          right: '-2px',
          top: '97%',
          transform: 'rotate(-90deg)',
        }}
      />
    </PseudoBox>
  );
};

NavItem.propTypes = {
  to: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

NavItem.defaultProps = {
  to: '#',
  children: null,
};

export const Navbar = () => {
  const { t } = useTranslation();

  return (
    <Flex
      position="fixed"
      alignItems="center"
      bg="brand.500"
      width={{ base: '15%', lg: '6%' }}
      height="100vh"
      color="lightWhite"
      direction="column"
      justifyContent="space-between"
      overflow="hidden"
    >
      <Flex w="100%" direction="column">
        <Logo as={RouterLink} to="/projects" color="white" p={4} mb={4} h="inherit" w="100%" />
        <Flex w="100%">
          <List w="100%">
            <ListItem h={24} maxW="100%">
              <NavItem to="/projects">
                <Icon fontSize="2xl" icon={MenuIcon} mb={{ base: 0 }} mr={{ base: 0 }} />
                <Text display={{ base: 'none', xl: 'block' }} fontSize="xs">{ t('nav.dashboard') }</Text>
              </NavItem>

            </ListItem>
          </List>
        </Flex>
      </Flex>
      <Flex w="100%" direction="column-reverse" flexGrow="1">
        <List w="100%">

          <a href="mailto:contact@wyzengroup.com">
            <Flex direction="column" alignItems="center">
              <Icon fontSize="2xl" icon={FaEnvelope} />
              <Text textAlign="center" display={{ base: 'none', xl: 'block' }} fontSize="xs">{t('support')}</Text>
            </Flex>
          </a>

        </List>
      </Flex>
      <Flex w="100%" direction="column">
        <List w="100%">
          <NavItem
            to="/profile"
            pr={2}
          >
            <Icon fontSize="2xl" icon={FaUserCircle} mb={2} />

            <Text display={{ base: 'none', xl: 'block' }} fontSize="xs">{t('nav.profil')}</Text>
          </NavItem>
          <LogoWyzen color="white" p={4} mb={{ base: 0 }} h="inherit" w="100%" />
        </List>
      </Flex>
    </Flex>
  );
};
