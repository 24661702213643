import dayjs from 'dayjs';
import 'dayjs/locale/fr';

export const formatDateDb = (date) => (
  dayjs(date).format('YYYY-MM-DD HH:mm:ss')
);

export const FormatDate = (date, lang) => dayjs(date).locale(`'${lang}'`).format('DD MMM YYYY');

export const getProjectAdvancement = (startDate, endDate) => {
  if (typeof (startDate) !== 'undefined' || typeof (endDate) !== 'undefined') {
    if (startDate === null || endDate === null) {
      return 0;
    }

    const currentDate = dayjs();

    const endDateObj = dayjs(endDate);

    const projectDays = endDateObj.diff(startDate, 'day');
    const projectCurrentDays = currentDate.diff(startDate, 'day');

    if (projectCurrentDays >= projectDays) {
      return 100;
    }

    if (projectDays > 0) {
      return Math.round((projectCurrentDays / projectDays) * 100);
    }
  }

  return 0;
};

export const getUnique = (arr, comp) => {
  // store the comparison  values in array
  const unique = arr.map((e) => e[comp])

    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
    .filter((e) => arr[e]).map((e) => arr[e]);

  return unique;
};
