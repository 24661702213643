import React from 'react';
import { PseudoBox } from '@chakra-ui/core';
import PropTypes from 'prop-types';
import { FiEdit } from 'react-icons/fi';

export const Editer = ({ handleClick, ...props }) => (
  <PseudoBox
    rounded="0.3rem"
    d="flex"
    alignItems="center"
    p="0.5rem"
    cursor="pointer"
    _hover={{
                backgroundColor: 'gray.50',
            }}
    {...props}
  >
    <PseudoBox
      h="1.5rem"
      w="1.5rem"
      as={FiEdit}
      color="green.800"
      onClick={handleClick}
    />
  </PseudoBox>
    );

Editer.propTypes = {
    handleClick: PropTypes.func,
};

Editer.defaultProps = {
    handleClick: null,
};
