import React, { useState } from 'react';
import {
  Button,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  Flex,
  Text, Stack, Spinner,
} from '@chakra-ui/core';
import { Formiz, useForm } from '@formiz/core';
import { FieldInput } from 'components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { APPENV } from 'services/config';
import { useQuery, queryCache } from 'react-query';
import Axios from 'axios';
import { useUpdateContact } from 'pages/ProjectDetails/_partials/ProjectContact/EditContact/useUpdateContact';
import { useParams } from 'react-router-dom';
import { isNumber } from '@formiz/validations';
import { Enregistrer } from 'components/Button/Enregistrer';

export const EditContact = ({
  isOpen, onClose, contactId, isReadonly,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { projectToken } = useParams();
  const formEditContact = useForm();
  const [isSubmiting, setIsSubmiting] = useState(false);

  // Requête pour récupérer les informations du projet contact par son adresse mail
  const {
    data: contactResponseRequest, isLoading,
  } = useQuery(['contact', contactId], () => Axios.get(
    `${APPENV.HOST_API}/contact/${contactId}`,
  ));

  const [mutate] = useUpdateContact({
    onError: () => {
      setIsSubmiting(false);
    },
    onSuccess: async ({ data }) => {
      // Chargement terminé
      // Mise à jour de la dépendance contact pour rafraîchir les informations dans la modale
      // AffichageTost && fermeture de la modale
      await queryCache.invalidateQueries('projectToken', projectToken);
      await queryCache.invalidateQueries('contact', contactId);

      toast({
        title: t('update.success', { defaultValue: 'Modification terminée' }),
        description: `${data.nom} ${data.prenom} a été mis à jour avec succès`,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
      setIsSubmiting(false);
      onClose();
    },
  });

  if (isLoading) {
    return (
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('modal.entete.edit.contact', { defaultValue: 'Editer le contact' })}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack flex={1} align="center" justify="center">
              <Spinner size="xl" color="brand.500" />
            </Stack>
          </ModalBody>
          {!isReadonly && (
          <ModalFooter>
            <Button
              variantColor="gray"
              onClick={onClose}
            >
              {t('cancel', { defaultValue: 'Cancel' })}
            </Button>
            {/* Bouton enregistrer  */}
            <Enregistrer
              isLoading={isSubmiting}
            />
          </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    );
  }

  const contact = contactResponseRequest?.data;

  // Fonction au submit de l'update d'un contact
  const handleEdit = async (values) => {
    setIsSubmiting(true);
    const entrepriseIdAndIdContact = { entrepriseId: contact?.entrepriseId, id: contact?.id };
    // Rajout du entrepriseId et id
    const datas = { ...values, ...entrepriseIdAndIdContact };
    mutate(datas);
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('modal.entete.edit.contact', { defaultValue: 'Editer le contact' })}
        </ModalHeader>
        <ModalCloseButton />
        <Formiz onValidSubmit={handleEdit} connect={formEditContact}>
          <form noValidate onSubmit={formEditContact.submit}>
            {isReadonly && (
            <>

              <ModalBody
                px={5}
                pb={5}
                pt={0}
              >

                {/* Nom */}
                <Flex
                  mb={2}
                >
                  <Text
                    as="b"
                    mr={2}
                  >
                    {t('contact.form.placeholder.nom', { defaultValue: 'Nom' })}
                    :
                  </Text>
                  <Text>
                    {`${contact?.nom ?? ''} ${contact?.prenom ?? ''}`}
                  </Text>
                </Flex>
                {/* Entreprise */}
                <Flex
                  mb={2}
                >
                  <Text
                    as="b"
                    mr={2}
                  >
                    {t('contact.form.placeholder.entreprise', { defaultValue: 'Entreprise' })}
                    :
                  </Text>
                  <Text>
                    {`${contact?.entrepriseNom ?? ''}`}
                  </Text>
                </Flex>
                {/* Adresse mail */}
                <Flex
                  mb={2}
                >
                  <Text
                    as="b"
                    mr={2}
                  >
                    {t('contact.form.placeholder.adresseEmail', { defaultValue: 'Adresse email' })}
                    :
                  </Text>
                  <Text>
                    {`${contact?.email ?? ''}`}
                  </Text>
                </Flex>
                {/* Désignation métier */}
                <Flex
                  mb={2}
                >
                  <Text
                    as="b"
                    mr={2}
                  >
                    {t('contact.form.placeholder.designationMetier', { defaultValue: 'Désignation métier' })}
                    :
                  </Text>
                  <Text>
                    {`${contact?.designationMetier ?? ''}`}
                  </Text>
                </Flex>

                {/* Téléphone1  */}
                <Flex
                  mb={2}
                >
                  <Text
                    as="b"
                    mr={2}
                  >
                    {t('contact.form.placeholder.telephone1', { defaultValue: 'Téléphone 1' })}
                    :
                  </Text>
                  <Text>
                    {`${contact?.telephone1 ?? ''}`}
                  </Text>
                </Flex>

                {/* Téléphone 2  */}
                <Flex
                  mb={2}
                >
                  <Text
                    as="b"
                    mr={2}
                  >
                    {t('contact.form.placeholder.telephone2', { defaultValue: 'Téléphone 2' })}
                    :
                  </Text>
                  <Text>
                    {`${contact?.telephone2 ?? ''}`}
                  </Text>
                </Flex>

                {/* ADresse 1  */}
                <Flex
                  mb={2}
                >
                  <Text
                    as="b"
                    mr={2}
                  >
                    {t('contact.form.placeholder.adresse1', { defaultValue: 'Adresse 1' })}
                    :
                  </Text>
                  <Text>
                    {`${contact?.adresse1 ?? ''}`}
                  </Text>
                </Flex>

                {/* ADresse 2  */}
                <Flex
                  mb={2}
                >
                  <Text
                    as="b"
                    mr={2}
                  >
                    {t('contact.form.placeholder.adresse2', { defaultValue: 'Adresse 2' })}
                    :
                  </Text>
                  <Text>
                    {`${contact?.adresse2 ?? ''}`}
                  </Text>
                </Flex>

                {/* Adresse 3  */}
                <Flex
                  mb={2}
                >
                  <Text
                    as="b"
                    mr={2}
                  >
                    {t('contact.form.placeholder.adresse3', { defaultValue: 'Adresse 3' })}
                    :
                  </Text>
                  <Text>
                    {`${contact?.adresse3 ?? ''}`}
                  </Text>
                </Flex>

                {/* ville  */}
                <Flex
                  mb={2}
                >
                  <Text
                    as="b"
                    mr={2}
                  >
                    {t('contact.form.placeholder.ville', { defaultValue: 'ville' })}
                    :
                  </Text>
                  <Text>
                    {`${contact?.ville ?? ''}`}
                  </Text>
                </Flex>

                {/* Code postal  */}
                <Flex
                  mb={2}
                >
                  <Text
                    as="b"
                    mr={2}
                  >
                    {t('contact.form.placeholder.codePostal', { defaultValue: 'Code postal' })}
                    :
                  </Text>
                  <Text>
                    {`${contact?.codePostal ?? ''}`}
                  </Text>
                </Flex>
              </ModalBody>
            </>
            )}
            {!isReadonly && (
            <>

              <ModalBody
                px={5}
                pb={5}
                pt={0}
              >

                {/* Nom */}
                <Flex
                  mb={2}
                >
                  <Text
                    as="b"
                    mr={2}
                  >
                    {t('contact.form.placeholder.nom', { defaultValue: 'Nom' })}
                    :
                  </Text>
                  <Text>
                    {`${contact?.nom} ${contact?.prenom}`}
                  </Text>
                </Flex>
                {/* Entreprise */}
                <Flex
                  mb={2}
                >
                  <Text
                    as="b"
                    mr={2}
                  >
                    {t('contact.form.placeholder.entreprise', { defaultValue: 'Entreprise' })}
                    :
                  </Text>
                  <Text>
                    {`${contact?.entrepriseNom}`}
                  </Text>
                </Flex>
                {/* Adresse mail */}
                <Flex
                  mb={2}
                >
                  <Text
                    as="b"
                    mr={2}
                  >
                    {t('contact.form.placeholder.adresseEmail', { defaultValue: 'Adresse email' })}
                    :
                  </Text>
                  <Text>
                    {`${contact?.email}`}
                  </Text>
                </Flex>
                {/* Désignation métier */}
                <FieldInput
                  name="designationMetier"
                  placeholder={t('form.contact.placeholder.designationMetier', { defaultValue: 'Désignation métier' })}
                  label={t('contact.form.placeholder.designationMetier', { defaultValue: 'Désignation métier' })}
                  defaultValue={contact?.designationMetier}
                  mb={2}
                />
                <Flex
                  justifyContent="space-between"
                >
                  {/* Téléphone1 */}
                  <FieldInput
                    w={{ base: '48%' }}
                    name="telephone1"
                    placeholder={t('form.contact.placeholder.telephone1', { defaultValue: 'Téléphone (1)' })}
                    label={t('contact.form.placeholder.telephone1', { defaultValue: 'Téléphone 1' })}
                    defaultValue={contact?.telephone1}
                    mb={2}
                    validations={[
                       {
                         rule: isNumber(),
                         message: t('form.telephone.error', { defaultValue: 'Le numéro de téléphone ne peut contenir uniquement des chiffres' }),
                       },
                     ]}
                  />
                  {/* Téléphone2 */}
                  <FieldInput
                    w={{ base: '48%' }}
                    name="telephone2"
                    placeholder={t('form.contact.placeholder.telephone2', { defaultValue: 'Téléphone (2)' })}
                    label={t('contact.form.placeholder.telephone2', { defaultValue: 'Téléphone 2' })}
                    defaultValue={contact?.telephone2}
                    mb={2}
                    validations={[
                       {
                         rule: isNumber(),
                         message: t('form.telephone.error', { defaultValue: 'Le numéro de téléphone ne peut contenir uniquement des chiffres' }),
                       },
                     ]}
                  />
                </Flex>
                {/* Adresse1 */}
                <FieldInput
                  name="adresse1"
                  placeholder={t('form.contact.placeholder.adresse1', { defaultValue: 'Adresse (1)' })}
                  label={t('contact.form.placeholder.adresse1', { defaultValue: 'Adresse 1' })}
                  defaultValue={contact?.adresse1}
                  mb={2}
                />
                {/* Adresse2 */}
                <FieldInput
                  name="adresse2"
                  placeholder={t('form.contact.placeholder.adresse2', { defaultValue: 'Adresse (2)' })}
                  label={t('contact.form.placeholder.adresse2', { defaultValue: 'Adresse 2' })}
                  defaultValue={contact?.adresse2}
                  mb={2}
                />
                {/* Adresse3 */}

                <FieldInput
                  w={{ base: '100%' }}
                  name="adresse3"
                  placeholder={t('form.contact.placeholder.adresse3', { defaultValue: 'Adresse (3)' })}
                  label={t('contact.form.placeholder.adresse3', { defaultValue: 'Adresse 3' })}
                  defaultValue={contact?.adresse3}
                  mb={2}
                />
                <Flex
                  justifyContent="space-between"
                >
                  {/* Ville */}
                  <FieldInput
                    w={{ base: '70%' }}
                    name="ville"
                    placeholder={t('form.contact.placeholder.ville', { defaultValue: 'Ville' })}
                    label={t('contact.form.placeholder.ville', { defaultValue: 'Ville' })}
                    defaultValue={contact?.ville}
                    mb={2}
                  />
                  {/* Code postal */}
                  <FieldInput
                    w={{ base: '25%' }}
                    name="codePostal"
                    placeholder={t('form.contact.placeholder.codePostal', { defaultValue: 'Code postal' })}
                    label={t('contact.form.placeholder.codePostal', { defaultValue: 'Code postal' })}
                    defaultValue={contact?.codePostal}
                    mb={2}
                    validations={[
                       {
                         rule: isNumber(),
                         message: t('form.codePostal.error', { defaultValue: 'Le code postal ne peut contenir uniquement des chiffres' }),
                       },
                     ]}
                  />
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button
                  variantColor="gray"
                  onClick={onClose}
                >
                  {t('cancel', { defaultValue: 'Cancel' })}
                </Button>
                {/* Bouton enregistrer  */}
                <Enregistrer
                  isLoading={isSubmiting}
                />
              </ModalFooter>
            </>
            )}
          </form>
        </Formiz>
      </ModalContent>
    </Modal>
  );
};

EditContact.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  contactId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isReadonly: PropTypes.bool,
};

EditContact.defaultProps = {
  isOpen: false,
  onClose: () => { },
  contactId: '',
  isReadonly: false,
};
