import React, { useState } from 'react';
import {
  Text,
  useDisclosure,
  Box,
  Flex,
  Link,
} from '@chakra-ui/core';
import {
  Card, CardHeader, CardTitle, CardDivider, CardBody,
} from 'components';
import PropTypes, { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BsFillPersonFill } from 'react-icons/bs';
import { GiFactory } from 'react-icons/gi';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { EditContact } from 'pages/ProjectDetails/_partials/ProjectContact/EditContact';
import { Voir } from 'components/Button/Voir';

export const ProjectContact = ({ contact, auth, isLoading }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isClicked, setIsClicked] = useState(false);

  // Fonction lancé en cliquant sur le bouton éditer un contact projet
  const handleClick = (e) => {
    e.stopPropagation();
    setIsClicked(true);
    onOpen();
  };

  if (isLoading) {
    return (
      <>

      </>
    );
  }

  if (contact === null) {
    return (
      <Card>
        <CardHeader
          d="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <CardTitle>{t('projetDetail.contact', { default: 'Contact chef de projet' })}</CardTitle>

        </CardHeader>

        <CardDivider />

        <CardBody fontSize="xs">
          {/* Bloc nom et prénom */}
          <Flex
            flexDirection="row"
            mb={3}
          >
            <Box
              as={BsFillPersonFill}
              size="20px"
              color="brand.500"
              mr={2}
            />
          </Flex>

          {/* Bloc entreprise */}
          <Flex
            flexDirection="row"
            mb={3}
          >
            <Box
              as={GiFactory}
              size="20px"
              color="brand.500"
              mr={2}
            />
          </Flex>

          {/* Bloc entreprise */}
          <Flex
            flexDirection="row"
            mb={3}
          >
            <Box
              as={AiOutlineMail}
              size="20px"
              color="brand.500"
              mr={2}
            />
          </Flex>

          {/* Bloc téléphone */}
          <Flex
            flexDirection="row"
            mb={3}
          >
            <Box
              as={AiOutlinePhone}
              size="20px"
              color="brand.500"
              mr={2}
            />
          </Flex>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        d="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <CardTitle>{t('projetDetail.contact', { default: 'Contact chef de projet' })}</CardTitle>
        {/* Check des droits pour éditer le contact */}
        {
          auth.canUpdate && (
            <Voir
              handleClick={handleClick}
            />
          )
        }
      </CardHeader>
      {/* Bouton pour éditer le contact projet */}
      {contact?.email !== null && isClicked && isOpen && (
        <EditContact
          isReadonly
          isOpen={isOpen}
          onClose={onClose}
          contactId={contact?.id}
        />
      )}

      <CardDivider />

      <CardBody fontSize="0.75rem">
        {/* Bloc nom et prénom */}
        <Flex
          flexDirection="row"
          mb={3}
        >
          <Box
            as={BsFillPersonFill}
            size="20px"
            color="brand.500"
            mr={2}
          />
          <Text fontWeight="bold">
            {`
          ${contact.prenom ?? ''} ${contact.nom ?? ''}
        `}
          </Text>
        </Flex>

        {/* Bloc entreprise */}
        <Flex
          flexDirection="row"
          mb={3}
        >
          <Box
            as={GiFactory}
            size="20px"
            color="brand.500"
            mr={2}
          />
          <Text fontWeight="bold">
            {`
          ${contact?.entreprise}
        `}
          </Text>
        </Flex>

        {/* Bloc entreprise */}
        <Flex
          flexDirection="row"
          mb={3}
        >
          <Box
            as={AiOutlineMail}
            size="20px"
            color="brand.500"
            mr={2}
          />
          <Text
            wordBreak="break-word"
            fontWeight="bold"
          >
            <Link href={`mailto:${contact?.email}`}>
              {`
          ${contact?.email}
        `}
            </Link>
          </Text>
        </Flex>

        {/* Bloc téléphone */}
        {contact.telephone && (
          <Flex
            flexDirection="row"
            mb={3}
          >
            <Box
              as={AiOutlinePhone}
              size="20px"
              color="brand.500"
              mr={2}
            />
            <Text fontWeight="bold">
              <Link href={`tel:${contact?.telephone}`}>
                {`${contact?.telephone}`}
              </Link>

            </Text>
          </Flex>
        )}
      </CardBody>
    </Card>
  );
};

ProjectContact.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number,
    prenom: PropTypes.string,
    nom: PropTypes.string,
    email: PropTypes.string,
    entreprise: PropTypes.string,
    telephone: PropTypes.string,
  }),
  auth: PropTypes.shape({
    canUpdate: bool,
    canRead: bool,
  }),
  isLoading: PropTypes.bool.isRequired,
};

ProjectContact.defaultProps = {
  contact: {},
  auth: {},
};
