import React from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';
import PropTypes from 'prop-types';
import { Flex, Box, Tooltip } from '@chakra-ui/core';
import { FcCalendar } from 'react-icons/fc';

export const ReactDatePicker = ({
 onChange, selected, w, label,
}) => {
  const { t } = useTranslation();
  const lang = t('core.lang.shortcut');
  return (
    <>
      <Tooltip
        hasArrow
        placement="top"
        label={label}
      >
        <Flex
          w={w}
          p={2}
          border="1px"
          borderColor="gray.50"
          justifyContent="start"
          alignItem="center"
          rounded={2}
        >
          <Box
            size="20px"
            as={FcCalendar}
            mr={2}
          />
          <DatePicker
            selected={selected}
            onChange={onChange}
            dateFormat={lang === 'en' ? 'yyyy dd MMMM' : 'dd MMMM yyyy'}
            locale={lang === 'en' ? en : fr}
          />
        </Flex>
      </Tooltip>
    </>
  );
};

ReactDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.instanceOf(Date).isRequired,
  w: PropTypes.string,
  label: PropTypes.string.isRequired,
};

ReactDatePicker.defaultProps = {
  w: '100%',
};
