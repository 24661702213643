/**
 * Fichier pointant sur le firebase stating ou production
 * Le choix se fait dans le docker
 * Par défaut c'est le contenu de test qui est présent
 */

const { APPENV } = require('services/config');

let firebaseConfigEnv = {};
switch (APPENV.ENV) {
  default:
  case 'local':
    // conf local - staging
    firebaseConfigEnv = {
    apiKey: "AIzaSyD6Xjk82BMxM2tmL8Py4u8sMoYSpbRRI1M",
    authDomain: "projectin.fr",
    projectId: "projectin-64821",
    storageBucket: "projectin-64821.appspot.com",
    messagingSenderId: "772248388029",
    appId: "1:772248388029:web:d2426868c3f66ab1e592d4",
    measurementId: "G-WWYJ9D50BB"
    };
    break;
}

export const firebaseConfig = firebaseConfigEnv;
