import React, { } from 'react';
import {
  Text,
  Stack,
  IconButton,
  Menu,
  MenuButton,
  Icon,
  Tooltip,
  Box,
  Flex,
} from '@chakra-ui/core';
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardDivider,
  CardAction,
  CardBody,
  CircularIcon,
  Progress,
  NotificationBadge,
} from 'components';
import {
  FaCalendarAlt, FaBook, FaEuroSign, FaCogs,
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormatDate } from 'services/utils';
import { FormatNumber } from 'components/FormatNumber';
import { checkBudgetProjet } from 'functionsHelper/Dashboard/checkBudgetProjet';
import { AiOutlineArrowRight } from 'react-icons/ai';

export const ProjectCard = ({ project, auth }) => {
  const { t } = useTranslation();
  const dataProjet = project.data;
  const phase = Array.isArray(project.phase) ? {} : project.phase;
  const { nom } = dataProjet;
  const history = useHistory();

  const isBudgetPhaseEnCoursSupBudgetProjet = checkBudgetProjet(phase?.data?.budget, dataProjet?.budget);
  const url = typeof (phase?.data?.id) !== 'undefined' ? `projects/${dataProjet?.projetToken}/phase/${phase?.data?.id}`
    : `projects/${dataProjet?.projetToken}/configuration`;
  const linkProps = {
    as: Link,
    d: 'block',
    to: url,
  };

  const handleClick = async () => {
    history.push({
      pathname: `projects/${dataProjet?.projetToken}/configuration`,
    });
  };

  return (
    <Card transition="0.2s" _hover={{ boxShadow: 'lg' }}>
      {
        (phase?.actionCountDocumentsDeposesByPhaseId !== 0
          && phase?.actionCountDocumentsDeposesByPhaseId !== undefined)
        && (phase?.data?.statutLibelle !== 'statut.cloturee') && (
          <NotificationBadge
            position="absolute"
            left="-0.8rem"
            top="-0.8rem"
            limit={10}
            mr="1rem"
            d="flex"
            value={phase.actionCountDocumentsDeposesByPhaseId}
            fontSize="0.8rem"
            backgroundColor="#1378fa"
            color="white"
            justifyContent="center"
            alignItems="center"
            w="1rem"
            h="1rem"
            p="0.8rem"
          />
        )
      }
      {
        (phase?.actionCountDocumentsRefusesByPhaseId !== 0
          && phase?.actionCountDocumentsRefusesByPhaseId !== undefined)
        && (phase?.data?.statutLibelle !== 'statut.cloturee') && (
          <NotificationBadge
            position="absolute"
            left="-0.8rem"
            top="-0.8rem"
            limit={10}
            mr="1rem"
            d="flex"
            value={phase.actionCountDocumentsRefusesByPhaseId}
            fontSize="0.8rem"
            backgroundColor="#1378fa"
            color="white"
            justifyContent="center"
            alignItems="center"
            w="1rem"
            h="1rem"
            p="0.8rem"
          />
        )
      }
      {
        (phase?.notifications !== undefined)
        && (phase?.notifications.length !== 0) && (
          <NotificationBadge
            position="absolute"
            left="-0.8rem"
            top="-0.8rem"
            limit={10}
            mr="1rem"
            d="flex"
            value={phase?.notifications.length}
            fontSize="0.8rem"
            backgroundColor="#1378fa"
            color="white"
            justifyContent="center"
            alignItems="center"
            w="1rem"
            h="1rem"
            p="0.8rem"
          />
        )
      }
      <CardHeader {...linkProps}>
        <CardTitle>{nom}</CardTitle>
        <CardSubtitle>{`${dataProjet?.ville ?? ''} ${dataProjet?.codePostal ?? ''}`}</CardSubtitle>
      </CardHeader>
      <CardDivider />
      { auth.canConfigurer && (
        <CardAction>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={FaCogs}
              variantColor="brand"
              variant="ghost"
              onClick={handleClick}
              _hover={{
                borderColor: 'gray.50',
                border: '1px',
                boxShadow: 'xl',
              }}
            />
          </Menu>
        </CardAction>
      )}
      <CardBody {...linkProps}>
        <Stack spacing={3}>
          <Text as="b" fontSize="xs">{t(phase?.data?.phaseLibelle, { defaultValue: phase?.data?.phaseLibelle })}</Text>
          <Stack isInline>
            <CircularIcon variantColor="success" icon={FaCalendarAlt} size="lg" />
            <Stack spacing={0} flex={1}>
              <Flex
                alignItems="center"
                fontSize="xs"
              >
                <Text>{FormatDate(phase?.data?.dateDebut, t('core.lang.shortcut'))}</Text>
                <Box mx={2} as={AiOutlineArrowRight} />
                <Text>{FormatDate(phase?.data?.dateFin, t('core.lang.shortcut'))}</Text>
              </Flex>
              <Stack isInline>
                <Progress
                  w="100%"
                  value={phase?.data?.avancement}
                  size="2xs"
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack isInline>
            <CircularIcon variantColor="warning" icon={FaBook} size="lg" />
            <Stack spacing={0} flex={1}>
              <Text fontSize="xs">{t('livrables.libelle', { defaultValue: 'Livrables' })}</Text>
              <Stack isInline>
                <Progress w="100%" value={phase?.data?.moyenneDocumentsValides} size="2xs" />
              </Stack>
            </Stack>
          </Stack>

          <Stack isInline>
            <CircularIcon
              variantColor="danger"
              icon={FaEuroSign}
              size="lg"
            />
            <Stack
              spacing={0}
              flex={1}
            >
              <Text fontSize="xs">{t('budget.projet', { defaultValue: 'Budget client' })}</Text>
              <Text fontSize="xs">
                <FormatNumber value={dataProjet?.budget} currency>-</FormatNumber>
                {
                  isBudgetPhaseEnCoursSupBudgetProjet && (
                    <Tooltip
                      label={t('budget.depassement', { defaultValue: 'Le budget de la phase en cours dépasse le budget du projet (+110%)' })}
                    >
                      <Icon
                        color="yellow.500"
                        name="warning"
                        size="17px"
                        ml="0.5rem"
                      />
                    </Tooltip>
                  )
                }
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      nom: PropTypes.string.isRequired,
      projetToken: PropTypes.string.isRequired,
      adresse1: PropTypes.string,
      adresse2: PropTypes.string,
      adresse3: PropTypes.string,
      codePostal: PropTypes.string,
      ville: PropTypes.string,
      budget: PropTypes.number.isRequired,
      budgetTotalDesLotsDeLaPhaseEnCours: PropTypes.shape({
        budgetTotalDesLotsDeLaPhaseEnCours: PropTypes.number.isRequired,
        statutBudget: PropTypes.string.isRequired,
        pourcentage: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    phase: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        dateDebut: PropTypes.string,
        dateFin: PropTypes.string.isRequired,
        phaseLibelle: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    canRead: PropTypes.bool.isRequired,
    canUpdate: PropTypes.bool.isRequired,
    canConfigurer: PropTypes.bool.isRequired,
  }).isRequired,
};
