import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import dayjs from 'dayjs';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    whitelist: ['fr', 'en'],
    nonExplicitWhitelist: false,
    fallbackLng: 'fr',
    defaultNS: 'global',
    fallbackNS: 'global',
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    // Set current language, for locale translations in dayjs
    dayjs.locale(i18n.language);
  });

export const switchLanguage = () => {
  const newLanguage = i18n.language === 'en' ? 'fr' : 'en';
  i18n.changeLanguage(newLanguage);
  dayjs.locale(newLanguage);
};

export const updateLanguage = (newLanguage) => {
  i18n.changeLanguage(newLanguage);
  dayjs.locale(newLanguage);
};
