import React from 'react';
import PropTypes from 'prop-types';
import { PseudoBox, Box } from '@chakra-ui/core';

export const Icon = ({ icon: IconEl, size, ...props }) => (
  <PseudoBox
    as="span"
    d="inline-block"
    position="relative"
    w="1em"
    flex="none"
    _before={{
      content: '"."',
      visibility: 'hidden',
    }}
    {...props}
  >
    <Box
      as={IconEl}
      size={size}
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
    />
  </PseudoBox>
);

Icon.propTypes = {
  icon: PropTypes.func,
  size: PropTypes.string,
};

Icon.defaultProps = {
  icon: () => null,
  size: '1rem',
};
