import React, { useState } from 'react';
import {
  Text, Divider, Flex, Box, PseudoBox, Tooltip, Button, Grid, Stack,
} from '@chakra-ui/core';
import {
  FaCalendarAlt, FaBook, FaEuroSign, FaArrowUp, FaArrowDown, FaArrowRight, FaCloudDownloadAlt,
} from 'react-icons/fa';
import {
  CircularIcon, Icon, NotificationBadge,
} from 'components';
import PropTypes from 'prop-types';
import { FormatDate } from 'services/utils';
import { useTranslation } from 'react-i18next';
import { FormatNumber } from 'components/FormatNumber';
import { handleDownload } from 'services/files';
import { APPENV } from 'services/config';
import { useParams } from 'react-router-dom';
import { Editer } from 'components/Button/Editer';
import { AiOutlineArrowRight } from 'react-icons/ai';

export const Progress = ({
  size, fontSize, value, color, hideLabel, ...props
}) => {
  const getStyles = () => {
    switch (size) {
      case 'xs': return { h: 1 };
      case '2xs': return { h: 2 };
      case 'sm': return { h: 3 };
      case 'md': return { h: 4 };
      case 'lg': return { h: 5 };
      case 'xl': return { h: 6 };
      default: return { h: 3 };
    }
  };

  let valueTarget = value;
  if (valueTarget === null) {
    valueTarget = 0;
  }

  return (
    <Flex w="100%" align="center" {...props}>
      <Box
        bg="gray.150"
        w="100%"
        borderRadius="full"
        overflow="hidden"
      >
        <Box
          width={`${valueTarget}%`}
          bg={color}
          {...getStyles()}
        />
      </Box>
      {!hideLabel && (
        <Text
          fontSize={fontSize}
          whiteSpace="nowrap"
          ml="3"
        >
          <FormatNumber transformZeroToTiret={false} value={value} />
          %
        </Text>
      )}
    </Flex>
  );
};

Progress.propTypes = {
  size: PropTypes.oneOf(['2xs', 'xs', 'sm', 'md', 'lg', 'xl']),
  fontSize: PropTypes.string,
  value: PropTypes.number,
  color: PropTypes.string,
  hideLabel: PropTypes.bool,
};

Progress.defaultProps = {
  size: 'sm',
  fontSize: '0.5rem',
  value: 0,
  color: 'gray.600',
  hideLabel: false,
};

export const ItemDetails = ({
  withLabel,
  withLegends,
  isLot,
  livrables,
  actionCountDocumentsDeposesByLotId,
  actionCountDocumentsRefusesByLotId,
  auth,
  onOpen,
  phaseStatut,
  detail: {
    id,
    budget,
    variation,
    variationPourcentage,
    variationProjet,
    variationProjetPourcentage,
    avancement,
    libelle,
    libelleCustom,
    dateDebut,
    dateFin,
    moyenneDocumentsValides,
  },
}) => {
  const { t } = useTranslation();
  const name = libelleCustom !== null ? libelleCustom : t(libelle, { defaultValue: libelle });
  const { projectToken } = useParams();
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  let arrow = null;
  let arrowColor = null;
  let transform = null;
  let variationCalc = null;

  let variationProjetCalc = null;
  let arrowProjet = null;
  let arrowColorProjet = null;
  let transformProjet = null;

  const handleClick = (e) => {
    e.stopPropagation();
    onOpen();
  };
  const download = (e) => {
    e.stopPropagation();
    e.preventDefault();
    handleDownload(`${APPENV.HOST_API}/projet/${projectToken}/lot/${id}/files`, 'zip', setIsLoadingDownload);
  };

  if (typeof (variationProjet) !== 'undefined' && variationProjet !== null) {
    if (variationProjet > 0) {
      arrowProjet = FaArrowUp;
      arrowColorProjet = 'red.500';
      transformProjet = 'rotate(45deg)';
    } else if (variationProjet < 0) {
      arrowProjet = FaArrowDown;
      arrowColorProjet = 'brand.500';
      transformProjet = 'rotate(-45deg)';
    } else {
      arrowProjet = FaArrowRight;
      arrowColorProjet = 'brand.500';
      transformProjet = null;
    }

    variationProjetCalc = Math.abs(variationProjet);
  }

  if (typeof (variation) !== 'undefined' && variation !== null) {
    if (variation > 0) {
      arrow = FaArrowUp;
      arrowColor = 'red.500';
      transform = 'rotate(45deg)';
    } else if (variation < 0) {
      arrow = FaArrowDown;
      arrowColor = 'brand.500';
      transform = 'rotate(-45deg)';
    } else {
      arrow = FaArrowRight;
      arrowColor = 'brand.500';
      transform = null;
    }

    variationCalc = Math.abs(variation);
  }

  const sizeIcone = isLot ? 'md' : 'lg';
  const fontSize = isLot ? '0.85rem' : '1rem';
  const fontSizeProgress = isLot ? '2xs' : '2xs';
  const fontSizeTextProgress = isLot ? '0.75rem' : '1rem';
  const progression = avancement !== null ? parseFloat(avancement) : 0;

  // const haveDoc = Object.values(livrables)?.map((livrable) => {
  //   return livrable.documents.length !== 0;
  // });

  const filteredArray = Object.values(livrables)?.filter((livrable) => livrable.documents.length !== 0);
  const haveDoc = filteredArray.length !== 0;
  // Rendu SANS les labels et AVEC les legendes (Première ligne)
  return (
    <>
      <Flex
        fontSize={{ base: 'md', lg: 'xs' }}
        flexDirection={{ base: 'column', lg: 'row' }}
        w="100%"
      >
        <Stack w="100%">

          {/* Nom du lot */}
          {withLabel && isLot && (
            <Flex w="100%">
              {/* Notification action niveau des lots */}
              {
                actionCountDocumentsDeposesByLotId !== 0 && (phaseStatut !== 'statut.cloturee') && (
                  <NotificationBadge
                    limit={10}
                    position="relative"
                    left="-1rem"
                    top="-1rem"
                    d="flex"
                    value={actionCountDocumentsDeposesByLotId}
                    backgroundColor="#1378fa"
                    color="white"
                    fontSize="0.8rem"
                    justifyContent="center"
                    alignItems="center"
                    w="1.2rem"
                    h="1.2rem"
                    p="0.8rem"
                    zIndex="99"
                  />
                )
              }
              {/* Notification action niveau des lots */}
              {
                actionCountDocumentsRefusesByLotId !== 0 && (phaseStatut !== 'statut.cloturee') && (
                  <NotificationBadge
                    limit={10}
                    position="relative"
                    left="-1rem"
                    top="-1rem"
                    d="flex"
                    value={actionCountDocumentsRefusesByLotId}
                    backgroundColor="#1378fa"
                    color="white"
                    fontSize="0.8rem"
                    justifyContent="center"
                    alignItems="center"
                    w="1.2rem"
                    h="1.2rem"
                    p="0.8rem"
                    zIndex="99"

                  />
                )
              }
              <Text
                fontSize={{ base: 'xd', xl: 'md' }}
                as="b"
                p={2}
              >
                {name}
              </Text>
            </Flex>

          )}

          <Grid templateColumns={{ base: '1fr', lg: '25% 25% 50%' }}>
            <Flex
              p={2}
              alignItems="center"
              w={{ base: '100%' }}

            >
              <Stack minW="36px" h="36px" alignItems="center">
                <CircularIcon
                  variantColor="success"
                  icon={FaCalendarAlt}
                  size={sizeIcone}
                />
              </Stack>

              <Flex
                py={0}
                px={2}
                w="100%"
                flexDirection="column"
                alignItems="start"
              >
                {/* Dates */}
                {withLegends && (
                  <Flex
                    w="100%"
                    alignItems="center"
                  >
                    <Text>
                      {FormatDate(dateDebut, t('core.lang.shortcut'))}
                    </Text>
                    <Box mx={2} as={AiOutlineArrowRight} />
                    <Text>
                      {FormatDate(dateFin, t('core.lang.shortcut'))}
                    </Text>
                  </Flex>
                )}
                {/* ProgressBar */}
                <Progress w="100%" value={progression} size={fontSizeProgress} fontSize={fontSizeTextProgress} />
              </Flex>
            </Flex>
            {/* Livrables */}
            <Flex
              py={0}
              px={2}
              alignItems="center"
              w={{ base: '100%' }}

            >
              <Stack minW="36px" h="36px" alignItems="center">
                <CircularIcon variantColor="warning" icon={FaBook} size={sizeIcone} />

              </Stack>
              <Flex
                p={2}
                w="100%"
                flexDirection="column"
                alignItems="start"
              >
                {withLegends && (
                  <Flex
                    flexDirection="column"
                    w="100%"
                  >
                    <Text>
                      {t('livrables.libelle', { defaultValue: '% de validation des livrables' })}
                    </Text>

                  </Flex>
                )}
                {/* ProgressBar */}
                <Progress w="100%" value={moyenneDocumentsValides} size={fontSizeProgress} fontSize={fontSizeTextProgress} />
              </Flex>
            </Flex>

            {/* Estimation budget */}

            <Stack p={2} ml={0} mr="auto" w={{ base: '100%' }} isInline alignItems="center">
              <Grid w="100%" gap={0} templateColumns={{ base: '1fr', lg: '1fr 1fr 2fr' }}>

                <Stack ml="auto" w="100%" isInline alignItems="center">
                  <Stack alignItems="center" minW="36px" h="36px">
                    <CircularIcon variantColor="danger" icon={FaEuroSign} size={sizeIcone} />
                  </Stack>
                  <Flex
                    py={0}
                    px={2}
                    flexDirection="column"
                    alignItems="start"
                    alignSelf={withLegends ? 'start' : 'center'}
                  >
                    {withLegends && (
                      <Flex
                        w="100%"
                        alignItems="center"
                      >
                        <Text>
                          {t('budget.estimation', { defaultValue: 'Estimation HT' })}
                        </Text>
                      </Flex>
                    )}
                    {/*  Valeur budget  */}
                    <Text
                      fontSize={fontSize}
                      textAlign="right"
                      minW={{ base: '100%', lg: '105px' }}
                    >
                      <FormatNumber
                        value={budget}
                        currency
                      />
                    </Text>
                  </Flex>

                </Stack>
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  w="100%"
                >
                  {/* Divider */}
                  <Divider
                    h="2.5rem"
                    orientation="vertical"
                  />
                  <Flex
                    flexDirection="column"
                    alignItems="start"
                    w="100%"
                    alignSelf={withLegends ? 'start' : 'center'}
                  >
                    {/* Lib variation */}
                    {withLegends && (
                      <Flex alignItems="start">
                        {t('variation.phase', { defaultValue: 'Variation' })}
                      </Flex>
                    )}

                    {/* Valeur variation */}
                    <Flex alignItems="start">
                      <FormatNumber value={variationCalc} currency>-</FormatNumber>
                      {variationPourcentage !== null && (
                        <>
                          <Divider
                            h="1rem"
                            orientation="vertical"
                          />
                          <FormatNumber transformZeroToTiret={false} value={variationPourcentage} deci={2} />
                          %
                        </>
                      )}
                      {arrow && (<Icon ml="1rem" transform={transform} color={arrowColor} icon={arrow} />)}
                    </Flex>
                  </Flex>
                </Flex>
                {/* Droite */}
                {!isLot && (
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    w="100%"
                  >
                    {/* Divider */}
                    <Divider
                      h="2.5rem"
                      orientation="vertical"
                    />
                    <Flex
                      flexDirection="column"
                      alignItems="start"
                      w="100%"
                      alignSelf={withLegends ? 'start' : 'center'}
                    >
                      {/* Lib variation */}
                      {withLegends && (
                        <Flex alignItems="start">
                          {t('variation.projet', { defaultValue: 'Variation' })}
                        </Flex>
                      )}
                      {/* Valeur variation */}
                      <Flex alignItems="start">
                        <FormatNumber value={variationProjetCalc} currency>-</FormatNumber>

                        {variationProjetPourcentage && (
                          <>
                            <Divider
                              h="1rem"
                              orientation="vertical"
                            />
                            <FormatNumber value={variationProjetPourcentage} deci={2} />
                            {'% '}
                          </>
                        )}

                        {arrowProjet && (<Icon ml="1rem" transform={transformProjet} color={arrowColorProjet} icon={arrowProjet} />)}
                      </Flex>
                    </Flex>
                  </Flex>
                )}
                {/* Edit && download */}

                {isLot && (

                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    w="100%"
                    pl={{ base: '0px', lg: '5rem' }}
                  >

                      {/* Edit */}
                    <Flex>
                      {auth.canUpdate && (
                      <Editer
                        handleClick={handleClick}
                      />
                        )}
                    </Flex>
                    {/* Download */}
                    <Flex>
                      { haveDoc && (

                      <Tooltip
                        hasArrow
                        placement="top"
                        label={t('tooltips.download', { defaultValue: 'Télécharger les documents du lot' })}
                      >
                        <PseudoBox
                          rounded="0.3rem"
                          p="0.5rem"
                          _hover={{
                            backgroundColor: 'gray.50',
                          }}
                        >
                          {/* Bouton download */}
                          <Button
                            size="3rem"
                            bg="white"
                            isLoading={isLoadingDownload}
                            onClick={download}
                            _hover={{
                              backgroundColor: 'gray.50',
                            }}
                          >
                            <Box as={FaCloudDownloadAlt} size="32px" color="green.800" />
                          </Button>
                        </PseudoBox>

                      </Tooltip>
                      )}
                    </Flex>
                  </Flex>
                )}
              </Grid>
            </Stack>
          </Grid>
        </Stack>

      </Flex>
    </>
  );
};

ItemDetails.propTypes = {
  withLabel: PropTypes.bool.isRequired,
  withLegends: PropTypes.bool.isRequired,
  isLot: PropTypes.bool.isRequired,
  actionCountDocumentsDeposesByLotId: PropTypes.number,
  actionCountDocumentsRefusesByLotId: PropTypes.number,
  auth: PropTypes.shape({
    canRead: PropTypes.bool,
    canUpdate: PropTypes.bool,
    canDelete: PropTypes.bool,
  }),
  phaseStatut: PropTypes.string.isRequired,
  onOpen: PropTypes.func,
  detail: PropTypes.shape({
    id: PropTypes.number,
    budget: PropTypes.oneOfType([PropTypes.oneOf([null]).isRequired, PropTypes.number, PropTypes.string]),
    variation: PropTypes.oneOfType([PropTypes.oneOf([null]).isRequired, PropTypes.number]),
    variationPourcentage: PropTypes.oneOfType([PropTypes.oneOf([null]).isRequired, PropTypes.number]),
    variationProjet: PropTypes.oneOfType([PropTypes.oneOf([null]).isRequired, PropTypes.number]),
    variationProjetPourcentage: PropTypes.oneOfType([PropTypes.oneOf([null]).isRequired, PropTypes.number]),
    avancement: PropTypes.oneOfType([PropTypes.oneOf([null]).isRequired, PropTypes.number, PropTypes.string]),
    libelleCustom: PropTypes.string,
    libelle: PropTypes.string,
    dateDebut: PropTypes.string,
    dateFin: PropTypes.string,
    moyenneDocumentsValides: PropTypes.number,
  }).isRequired,
  livrables: PropTypes.objectOf(PropTypes.object),
};

ItemDetails.defaultProps = {
  auth: [],
  onOpen: null,
  actionCountDocumentsDeposesByLotId: 0,
  actionCountDocumentsRefusesByLotId: 0,
  livrables: {},
};
