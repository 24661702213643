import React, { useState } from 'react';
import {
 Stack, Input, ListIcon, ListItem, List,
} from '@chakra-ui/core';
import { FieldInput } from 'components/Fields';
import Autosuggest from 'react-autosuggest';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FaPlusCircle } from 'react-icons/fa';
import { isNumber } from '@formiz/validations';

export const AutoCompleteLot = (props) => {
  const {
 listeDefault, typeInput, isSubmit, setIsSubmit,
} = props;
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');
  const [id, setId] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const { t } = useTranslation();

  const escapeRegexCharacters = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const theme = {
    container: {
      position: 'relative',
    },
    inputFocused: {
      outline: 'none',
    },
    inputOpen: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
      textAlign: 'left',
    },
    suggestion: {
      cursor: 'pointer',
      padding: '10px 20px',
    },
    suggestionHighlighted: {
      backgroundColor: '#ddd',
    },
  };

  const getSuggestionValue = (suggestion) => {
    if (suggestion.isAddNew) {
      return value;
    }

    return suggestion.label;
  };

  const getSuggestions = (val) => {
    const escapedValue = escapeRegexCharacters(val.trim());
    if (escapedValue === '') {
      return [];
    }

    const regex = new RegExp(`^${escapedValue}`, 'i');
    const suggestionLabel = listeDefault.filter((lot) => regex.test(getSuggestionValue(lot)));

    if (suggestionLabel.length === 0) {
      return [{ isAddNew: true }];
    }

    return suggestionLabel;
  };

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => {
    if (suggestion.isAddNew) {
      return (
        <>
          <span>
            {`[+] ${t('lot.new', { defaultValue: 'Ajouter un nouveau lot' })}`}
            <strong>{value}</strong>
          </span>
        </>
      );
    }

    return (
      <List key={suggestion.id} spacing={3} listStyleType="none">
        <ListItem listStyleType="none">
          <ListIcon icon={FaPlusCircle} color="green.500" />
          {suggestion.label}
        </ListItem>
      </List>
    );
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    setIsSelected(false);
    setIsSubmit(false);
    setId(null);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = (inputData) => {
    setSuggestions(getSuggestions(inputData.value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    setIsSelected(true);
    setIsSubmit(false);
    setId(suggestion.id);
    if (suggestion.isAddNew) {
      setId(null);
    }
  };

  // POUR AUTO SUGGESTION
  const inputProps = {
    placeholder: t('lot.placeholder.search', {
      defaultValue: 'Recherche un lot',
    }),
    onChange,
    value,
  };

  const renderInputComponent = (inputProperties) => (
    <Stack spacing={3} p={4}>
      <Input
        focusBorderColor="brand.500"
        variant="outline"
        width={[
          '100%', // base
          '100%', // 480px upwards
          '100%', // 768px upwards
          '50%', // 992px upwards
        ]}
        {...inputProperties}
      />
    </Stack>
  );

  const renderSuggestionsContainer = (datas) => {
    const { containerProps, children, query } = datas;
    return (
      <div {...containerProps}>
        <div>{children}</div>

        {isSelected && !isSubmit && typeInput === 'lot' && (
          <>
            <FieldInput name="lotId" hidden defaultValue={id} />
            <Stack isInline p={4}>
              <FieldInput
                label="libelle du lot"
                name="lotLibelle"
                defaultValue={query}
                required="This is required"
              />
              <FieldInput
                label="budget"
                name="budget"
                validations={[
                  {
                    rule: isNumber(),
                    message: t('errors.isNumber', {
                      defaultValue: 'Invalid number',
                    }),
                  },
                ]}
              />
            </Stack>
          </>
        )}
      </div>
    );
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
      theme={theme}
    />
  );
};

AutoCompleteLot.propTypes = {
  listeDefault: PropTypes.arrayOf(PropTypes.object).isRequired,
  typeInput: PropTypes.string.isRequired,
  isSubmit: PropTypes.bool.isRequired,
  setIsSubmit: PropTypes.func.isRequired,
};
