import {
  Box,
  useToast,
  Button,
  Text,
  Spinner,
  Flex,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/core';
import React from 'react';
import { useTodos, useCreateTodo } from 'services/exemple';
import { PageHeader, PageContent } from 'layout';
import { useTranslation } from 'react-i18next';

export const Archives = () => {
  const toast = useToast();
  const { isLoading, data: todoList, isError } = useTodos();
  const { t } = useTranslation();

  const [mutate, { isLoading: isPostLoading }] = useCreateTodo({
    onError: () => {
      toast({
        title: t('archives.errorAddingTask', { defaultValue: 'The task cannot be added' }),
        status: 'error',
      });
    },
    onSuccess: () => {
      toast({
        title: t('archives.taskAdded', { defaultValue: 'Todo task added' }),
        status: 'success',
      });
    },
  });

  return (
    <>
      <PageHeader>
        <Text
          fontSize="lg"
          color="brand.800"
          fontWeight="500"
        >
          {t('archives.title', { defaultValue: 'Archives' })}
        </Text>
      </PageHeader>
      <PageContent>
        <Text
          fontSize="2xl"
          fontWeight="600"
          color="brand.800"
        >
          {t('archives.page', { defaultValue: 'Archives Page' })}
        </Text>
        <Button
          onClick={mutate}
          color="brand.500"
          isDisabled={isLoading}
          isLoading={isPostLoading}
        >
          {t('archives.addTodo', { defaultValue: 'Add todo task' })}
        </Button>

        <Box mt={8}>
          {isError && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>
                {t('archives.errorLoadingTasks', { defaultValue: 'An error occurred while loading tasks.' })}
              </AlertDescription>
            </Alert>
          )}

          {/* Loader shown when listing is being fetched */}
          {isLoading && (
            <Flex alignItems="center" direction="column">
              <Spinner thickness="4px" mb={2} emptyColor="gray.200" color="brand.500" size="lg" />
              <Text>{t('archives.loading', { defaultValue: 'Loading todo...' })}</Text>
            </Flex>
          )}

          {/* Show a message when the listing is fetched and there is no items */}
          {(!isError && !isLoading && !todoList?.length) && (
            <Text>
              {t('archives.noTasks', { defaultValue: 'There is no tasks.' })}
            </Text>
          )}

          {/* Show the listing when the results is loaded and there is at least one item */}
          {(!isError && !isLoading && todoList?.length > 0) && todoList.map(((todo) => (
            <Box key={todo.id}>
              {todo?.title}
            </Box>
          )))}
        </Box>

      </PageContent>
    </>
  );
};
