import React from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  PseudoBox,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/core';
import { FaEnvelope, FaTimes, FaUserAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FieldSelectCustomHandleChange } from 'components/Fields';
import { Formiz } from '@formiz/core';

export const IntervenantList = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const {
    intervenant, roles, onDelete, onChangeRole, onInvitation,
  } = props;

  const rolesForSelect = roles?.map((role) => ({
    value: `${role.id}`,
    label: t(role.valeur),
  }));

  return (
    <Flex
      flexDirection={{
        base: 'column',
        sm: 'column',
        md: 'column',
        lg: 'row',
        xl: 'row',
      }}
      rounded="md"
      borderWidth="1px"
      borderStyle="solid"
      justifyContent="space-between"
      p={5}
    >
      <Stack flexBasis={0} flexGrow={1}>
        <Stack isInline>
          <Box as={FaUserAlt} size="20px" color="brand.500" />
          <Text fontWeight="bold" wordBreak="break-word">
            {`${intervenant.contactPrenom} ${intervenant.contactNom}`}
          </Text>
        </Stack>
        <Stack isInline>
          <Box as={FaEnvelope} size="20px" color="brand.500" />
          <Text wordBreak="break-word">{intervenant.contactEmail}</Text>
        </Stack>
      </Stack>
      <Stack py="auto" my="auto">
        <Formiz>
          <form>
            <FieldSelectCustomHandleChange
              name="contact_role"
              required={`${t('errors.required', {
                defaultValue: 'Champ requis',
              })}`}
              placeholder={`${t('intervenant.placeholder.role', {
                defaultValue: 'Role',
              })}`}
              options={rolesForSelect}
              handleCustomChange={(e) => onChangeRole(e, intervenant.id)}
              defaultValue={intervenant.roleId}
            />
          </form>
        </Formiz>
      </Stack>
      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr) auto',
        }}
        gap={2}
        py="auto"
        my="auto"
        m={2}
      >
        <PseudoBox
          as="button"
          fontSize="xs"
          mt={{
            base: 2,
            sm: 2,
            md: 2,
            lg: 0,
            xl: 0,
          }}
          onClick={(e) => onInvitation(e, intervenant.id)}
          bg="brand.500"
          color="white"
          rounded="0.25rem"
          fontWeight="semibold"
          pl="1rem"
          pr="1rem"
          display="inline-flex"
          alignItems="center"
        >
          <IconButton icon={FaEnvelope} bg="brand.500" />
          {`${t('intervenant.renvoyermail', {
            defaultValue: "Renvoyer un lien d'invitation",
          })}`}
        </PseudoBox>
        <Button
          fontSize="xs"
          mt={{
            base: 2,
            sm: 2,
            md: 2,
            lg: 0,
            xl: 0,
          }}
          onClick={onOpen}
          leftIcon={FaTimes}
          variantColor="danger"
        >
          {`${t('delete', { defaultValue: 'Supprimer un intervenant' })}`}
        </Button>
      </Grid>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('intervenant.modal.delete.header')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {t('intervenant.modal.delete.body')}
          </ModalBody>

          <ModalFooter>
            <Button variantColor="gray" mr={3} onClick={onClose}>
              {t('retour')}
            </Button>
            <Button
              onClick={onDelete}
              leftIcon={FaTimes}
              variantColor="danger"
            >
              {t('delete')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

IntervenantList.propTypes = {
  intervenant: PropTypes.shape({
    id: PropTypes.number,
    roleId: PropTypes.number,
    contactNom: PropTypes.string,
    contactPrenom: PropTypes.string,
    contactEmail: PropTypes.string,
  }).isRequired,
  roles: PropTypes.arrayOf(PropTypes.object),
  onDelete: PropTypes.func.isRequired,
  onChangeRole: PropTypes.func.isRequired,
  onInvitation: PropTypes.func.isRequired,
};

IntervenantList.defaultProps = {
  roles: [],
};
