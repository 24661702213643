/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {
  Formiz, FormizStep, useForm,
} from '@formiz/core';
import {
  Box, Stack, Flex, Button,
} from '@chakra-ui/core';
import { isEmail } from '@formiz/validations';
import { FieldInput, FieldUppy } from 'components';

// Basic Formiz Field example
// const FieldInput = (props) => {
//   const {
//     id, value, setValue, isValid, errorMessage,
//   } = useField(props);
//   const { label, type } = props;

//   return (
//     <div>
//       <label htmlFor={id}>{label}</label>
//       <input type={type || 'text'} id={id} value={value ?? ''} onChange={(e) => setValue(e.target.value)} />
//       {!isValid && <div>{errorMessage}</div>}
//     </div>
//   );
// };

export const FormDemo = (props) => {
  const form = useForm();

  const handleSubmit = (values) => {
    console.log(values); // eslint-disable-line
  };

  return (
    <Box p="4" bg="white" {...props}>
      <Formiz onValidSubmit={handleSubmit} connect={form}>
        <form noValidate onSubmit={form.submitStep}>
          Form Demo

          <FormizStep name="step1">
            <FieldInput
              label="Name"
              name="name"
            />
          </FormizStep>

          <FormizStep name="step2">
            <Stack spacing="6">
              <FieldInput
                label="Email"
                name="email"
                type="email"
                validations={[
                  {
                    rule: isEmail(),
                    message: 'Not valid',
                  },
                ]}
              />
              <FieldUppy
                callbackForRefresh={null}
                label="Toto"
                helper="Jpeg files"
                name="files.toto"
                required="required"
                validations={[
                  {
                    rule: (files) => !files || files.length >= 2,
                    message: 'Need 2 files',
                  },
                ]}
              />

              <FieldUppy
                callbackForRefresh={null}
                label="Avatars"
                name="files.avatars"
                required="required"
              />
            </Stack>
          </FormizStep>

          <Flex mt="4">
            {!form.isFirstStep && (
              <Button onClick={() => form.prevStep()}>
                Prev
              </Button>
            )}
            <Button
              variantColor="brand"
              ml="auto"
              type="submit"
              isDisabled={form.isStepSubmitted && !form.isStepValid}
            >
              {form.isLastStep ? 'Save' : 'Next'}
            </Button>
          </Flex>
        </form>
      </Formiz>
    </Box>
  );
};
