import React, { useState, useEffect } from 'react';
import {
  Flex, Text, Spinner,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { LectureItemProfil } from 'components/Profile/index';
import { useQuery } from 'react-query';
import { useAuthentificationContext } from 'context/auth-context';
import { APPENV } from 'services/config';
import Axios from 'axios';
import { EditProfile } from 'pages/Profile/edit-profile';
import { Editer } from 'components/Button/Editer';

export const ReadProfile = () => {
  // Hook
  const { t } = useTranslation();
  const { mailLocalStorage } = useAuthentificationContext();
  const [showEdit, setShowEdit] = useState(false);
  const { data, isLoading } = useQuery(['informations-personnelles', mailLocalStorage], () => Axios.get(
    `${APPENV.HOST_API}/utilisateur/${mailLocalStorage}`,
  ));

  useEffect(() => {
  }, [showEdit]);

  // Action au clic sur le chevron de droite
  const handleShowEdit = () => {
    setShowEdit(true);
  };

  // Vue de chargement
  if (isLoading) {
    return (
      <Flex
        backgroundColor="white"
        border="1px"
        borderColor="gray.50"
        rounded="0.5rem"
        flexDirection="column"
        p={4}
      >
        {/* Titre du bloc */}
        <Flex
          w="100%"
          p={2}
          justifyContent="space-between"
          alignItem="center"
        >
          <Flex
            justifyContent="center"
            alignItem="center"
            w="80%"
            mt="9px"
          >
            <Text
              as="b"
              w="100%"
              textAlign="center"
              fontSize={{ base: 'xs', lg: '2xl' }}
            >
              {t('contact.form.placeholder.readInformationsPersonnelles')}
            </Text>
          </Flex>
        </Flex>
        {/* Bloc des informations */}
        <Flex
          justifyContent="center"
        >
          <Spinner size="xl" color="brand.500" />
        </Flex>
      </Flex>
    );
  }

  const informationsContact = data?.data;

  return (
    <>
      {showEdit && (
        <EditProfile
          informationsProfile={informationsContact}
          returnFunction={setShowEdit}
        />
      )}

      {!showEdit && (
        <Flex
          mt={{ base: 2, lg: 0 }}
          backgroundColor="white"
          border="1px"
          borderColor="gray.50"
          rounded="0.5rem"
          flexDirection="column"
          pt={4}
        >
          {/* Titre du bloc */}
          <Flex
            w="100%"
            p={2}
            justifyContent="space-between"
            alignItem="center"
          >
            <Flex
              justifyContent="center"
              alignItem="center"
              w="80%"
            >
              <Text
                mt={{ base: '9px', lg: 0 }}
                as="b"
                w="100%"
                textAlign="center"
                fontSize={{ base: 'xs', lg: '2xl' }}
              >
                {t('contact.form.placeholder.readInformationsPersonnelles')}
              </Text>
            </Flex>
            <Flex
              w="10%"
              justifyContent="center"
            >
              <Editer
                handleClick={handleShowEdit}
              />
            </Flex>
          </Flex>
          {/* Bloc des informations */}
          <Flex>
            <Flex
              w="100%"
              flexDirection="column"
            >
              {/* Nom */}
              <LectureItemProfil
                label="contact.form.placeholder.nom"
                value={informationsContact?.nom ?? t('nonDefini')}
              />

              {/* Prenom */}
              <LectureItemProfil
                label="contact.form.placeholder.prenom"
                value={informationsContact?.prenom ?? t('nonDefini')}
              />

              {/* Adresse mail */}
              <LectureItemProfil
                label="contact.form.placeholder.adresseEmail"
                value={informationsContact?.email ?? t('nonDefini')}
              />

              {/* Désignation métier */}
              <LectureItemProfil
                label="contact.form.placeholder.designationMetier"
                value={informationsContact?.designationMetier ?? t('nonDefini')}
              />

              {/* Adresse1 */}
              <LectureItemProfil
                label="contact.form.placeholder.adresse1"
                value={informationsContact?.adresse1 ?? t('nonDefini')}
              />

              {/* Adresse2 */}
              <LectureItemProfil
                label="contact.form.placeholder.adresse2"
                value={informationsContact?.adresse2 ?? t('nonDefini')}
              />

              {/* Adresse3 */}
              <LectureItemProfil
                label="contact.form.placeholder.adresse3"
                value={informationsContact?.adresse3 ?? t('nonDefini')}
              />

              <Flex
                flexDirection={{ base: 'column', md: 'row' }}
              >
                {/* Ville */}
                <LectureItemProfil
                  label="contact.form.placeholder.ville"
                  value={informationsContact?.ville ?? t('nonDefini')}
                />
                {/* Code postal */}
                <LectureItemProfil
                  label="contact.form.placeholder.codePostal"
                  value={informationsContact?.codePostal ?? t('nonDefini')}
                />
              </Flex>

              <Flex
                flexDirection={{ base: 'column', md: 'row' }}
              >
                {/* Téléphone1 */}
                <LectureItemProfil
                  label="contact.form.placeholder.telephone1"
                  value={informationsContact?.telephone1 ?? t('nonDefini')}
                />

                {/* Téléphone2 */}
                <LectureItemProfil
                  label="contact.form.placeholder.telephone2"
                  value={informationsContact?.telephone2 ?? t('nonDefini')}
                />
              </Flex>

            </Flex>
          </Flex>
        </Flex>

      )}

    </>

  );
};
