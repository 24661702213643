import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { PseudoBox } from '@chakra-ui/core';

export const TimelineContext = createContext();

export const Timeline = ({
  progressSize, spacing, children, ...props
}) => {
  const countChildren = React.Children.count(children);

  const childrenClones = React.Children.map(children, (element, index) => (
    React.cloneElement(element, {
      isLast: index === countChildren - 1,
      pb: spacing,
    })
  ));

  return (
    <TimelineContext.Provider value={{ progressSize }}>
      <PseudoBox
        position="relative"
        {...props}
      >
        {childrenClones}
      </PseudoBox>
    </TimelineContext.Provider>
  );
};

Timeline.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  progressSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl']),
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

Timeline.defaultProps = {
  children: null,
  progressSize: 'md',
  spacing: 8,
};
