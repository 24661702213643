import React from 'react';
import {
  Text,
  Heading,
  Icon,
  Box,
  Flex,
} from '@chakra-ui/core';

import { Timeline } from 'components/Timeline/Timeline';
import { TimelineItem } from 'components/Timeline/TimelineItem';
import { TimelineProgress } from 'components/Timeline/TimelineProgress';
import { TimelineContent } from 'components/Timeline/TimelineContent';
import { FormatDate } from 'services/utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';

export const ProjectPhasesTimeline = ({
  isLoading, phases, ...props
}) => {
  let { phaseId } = useParams();
  phaseId = parseInt(phaseId, 10);

  const { t } = useTranslation();
  const history = useHistory();

  const handleUpdateId = (id) => {
    history.push({
      pathname: id,
    });
  };

  return (
    <>
      {isLoading && (
        <></>
      )}
      <Timeline
        progressSize="md"
        {...props}
      >
        {Object.values(phases)?.map((phase) => (
          <TimelineItem key={phase.data.id}>
            <TimelineProgress variantColor={t(`${phase?.data?.statutLibelle}.color`, { defaultValue: 'success' })} value={phase.data.avancement} />
            <TimelineContent onClick={() => { handleUpdateId(phase.data.id); }} cursor="pointer">
              <Heading wordBreak="break-word" size="sm" fontWeight={`${phaseId === phase.data.id ? 'bold' : 'normal'}`}>

                <Text
                  fontSize="xs"
                >
                  {
                    phase?.data?.statutLibelle === 'statut.cloturee' && (
                      <Icon
                        mr={2}
                        name="lock"
                      />
                    )
                  }
                  {phase?.data?.phaseLibelleCustom !== null ? phase?.data?.phaseLibelleCustom
                    : t(phase?.data?.phaseLibelle, { defaultValue: phase?.data?.phaseLibelle })}
                </Text>
              </Heading>
              <Flex>
                <Text fontSize="xs">
                  {`${FormatDate(phase?.data?.dateDebut, t('core.lang.shortcut'))}`}
                </Text>
                <Box mx={2} as={AiOutlineArrowRight} />
                <Text fontSize="xs">
                  {`${FormatDate(phase?.data?.dateFin, t('core.lang.shortcut'))}`}
                </Text>
              </Flex>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </>
  );
};
ProjectPhasesTimeline.propTypes = {
  phases: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  isLoading: PropTypes.bool.isRequired,
};

ProjectPhasesTimeline.defaultProps = {
  phases: [],
};
