import React from 'react';
import { Flex } from '@chakra-ui/core';
import PropTypes from 'prop-types';

export const CardDocument = ({ children }) => (
  <Flex
    my={2}
    flexDirection="column"
    mx="auto"
    p={3}
    backgroundColor="white"
    rounded="0.5rem"
    boxShadow="0px 0px 10px #c4c3c3;"
    w={{ base: '95%', md: '95%', lg: '60%' }}
  >
    {children}
  </Flex>
);

CardDocument.propTypes = {
  children: PropTypes.node.isRequired,
};
