import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@chakra-ui/core';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import { FormGroup } from 'components';

const propTypes = {
  label: PropTypes.node,
  isDisabled: PropTypes.bool,
  ...fieldPropTypes,
};

const defaultProps = {
  label: '',
  helper: '',
  isDisabled: false,
  ...fieldDefaultProps,
};

export const FieldCheckbox = (props) => {
  const {
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    errorMessage,
  } = useField(props);

  const {
    label, isDisabled, required, ...otherProps
  } = props;

  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const handleBlur = () => {
    setIsTouched(true);
  };

  const handleChange = (e) => setValue(e.target.checked);

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    showError,
    isDisabled,
    ...otherProps,
  };

  return (
    <FormGroup {...formGroupProps}>
      <Checkbox
        id={id}
        key={resetKey}
        defaultIsChecked={value}
        checked={value}
        onBlur={handleBlur}
        onChange={handleChange}
        aria-invalid={showError}
        aria-describedby={!isValid ? `${id}-error` : null}
        disabled={isDisabled}
        variantColor="brand"
      >
        {label}
      </Checkbox>
    </FormGroup>
  );
};
FieldCheckbox.propTypes = propTypes;
FieldCheckbox.defaultProps = defaultProps;
