import React from 'react';
import { Flex, Text } from '@chakra-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormatDate } from 'services/utils';
import { TitleDocument } from 'components/TitleDocument';
import { APPENV } from 'services/config';

export const BlocControle = ({
  document = null, validBloc = false, refuseBloc = false, historicBloc = false, projectToken, documentHistorique = null,
}) => {
  const documentsControle = document?.data?.files?.controle;
  const { t } = useTranslation();
  return (
    <Flex
      mb={3}
      w="100%"
      flexDirection="column"
      p={3}
      backgroundColor={(document?.data?.files?.data?.statutLibelle === 'statut.valide') ? '#F5F9F4' : '#ffe5e5'}
      rounded="0.5rem"
    >
      {
        validBloc === true && (

          <Flex
            flexDirection="column"
          >
            <Flex
              w="100%"
            >
              <Text
                color="#707070"
              >
                {t('documents.valided')}
                {' '}
                <span style={{ fontWeight: 'bold' }}>{FormatDate(document?.dateControle)}</span>
                {' '}
                {t('documents.par')}
                {' '}
                <span style={{ fontWeight: 'bold' }}>
                  {document?.data?.files?.data?.userNom}
                  {' '}
                  {document?.userPrenom}
                </span>
              </Text>
            </Flex>
            <Flex
              flexDirection={{ base: 'column', md: 'row' }}
              w="100%"
            >
              <Flex
                pr={{ base: 0, md: 2 }}
                py={{ base: 0, md: 2 }}
                w={{
                  base: '100%', md: '50%', lg: '50%', xl: '40%',
                }}
                flexDirection={{ base: 'column' }}
              >
                {Object.keys(document?.data?.files?.controle).length > 0
                  && Object.values(documentsControle)?.map((documentControle) => (
                    <TitleDocument
                      key={documentControle.id}
                      displayLoadBy={false}
                      urlTarget={`${APPENV.HOST_API}/projet/${projectToken}/file/${documentControle?.fichierId}`}
                      label={documentControle?.fileName}
                      dateImport={documentControle?.dateCreation}
                      nomImport={documentControle?.userNom}
                      prenomImport={documentControle?.userPrenom}
                    />
                  ))}
              </Flex>
              <Flex
                w={{
                  base: '100%', md: '50%', lg: '50%', xl: '60%',
                }}
                pl={{ base: 0, md: 2 }}
                py={{ base: 0, md: 2 }}
                flexDirection="column"
              >
                {
                  (document?.data?.files?.data?.commentaireControle !== null && document?.data?.files?.data?.commentaireControle !== undefined) && (
                    <>
                      <Text
                        as="b"
                        color="#707070"
                        fontSize={{ base: 'xs', md: 'md' }}
                      >
                        {t('documents.details.commentaire.validation')}
                      </Text>
                      <Text
                        color="black"
                        as="i"
                        fontSize="md"
                      >
                        {`« ${document?.data?.files?.data?.commentaireControle} »`}
                      </Text>
                    </>
                  )
                }

              </Flex>
            </Flex>
          </Flex>
        )
      }

      {
        refuseBloc && (
          <Flex
            flexDirection="column"
          >
            <Flex
              w="100%"
              mb={{ base: 3, md: 0 }}
            >
              <Text
                fontSize={{ base: 'xs', md: 'md' }}
                color="#707070"
              >
                {t('documents.refused')}
                {' '}
                <span style={{ fontWeight: 'bold' }}>{FormatDate(document?.dateControle)}</span>
                {' '}
              </Text>
            </Flex>
            <Flex
              w="100%"
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <Flex
                pr={{ base: 0, md: 2 }}
                py={{ base: 0, md: 2 }}
                w={{
                  base: '100%', md: '40%', lg: '50%', xl: '40%',
                }}
                flexDirection={{ base: 'column' }}
              >
                {Object.keys(document?.data?.files?.controle).length > 0
                  && Object.values(documentsControle)?.map((documentControle) => (
                    <TitleDocument
                      key={documentControle.id}
                      displayLoadBy={false}
                      urlTarget={`${APPENV.HOST_API}/projet/${projectToken}/file/${documentControle?.fichierId}`}
                      label={documentControle?.fileName}
                      dateImport={documentControle?.dateCreation}
                      nomImport={documentControle?.userNom}
                      prenomImport={documentControle?.userPrenom}
                    />
                  ))}
              </Flex>
              <Flex
                w={{
                  base: '100%', md: '50%', lg: '50%', xl: '60%',
                }}
                pl={{ base: 0, md: 2 }}
                py={{ base: 0, md: 2 }}
                flexDirection="column"
              >
                {
                  (document?.data?.files?.data?.commentaireControle !== null && document?.data?.files?.data?.commentaireControle !== undefined) && (
                    <>
                      <Text
                        as="b"
                        color="#707070"
                        fontSize={{ base: 'xs', md: 'md' }}
                      >
                        {t('documents.details.commentaire.refus')}
                      </Text>

                      <Text
                        color="black"
                        as="i"
                        fontSize="md"
                      >
                        {`« ${document?.data?.files?.data?.commentaireControle} »`}
                      </Text>
                    </>
                  )
                }

              </Flex>
            </Flex>
          </Flex>
        )
      }

      {/* Bloc historique */}
      {
        historicBloc && (
          <Flex
            flexDirection="column"
          >
            <Flex
              w="100%"
              mb={{ base: 3, md: 0 }}
            >
              <Text
                color="#707070"
                fontSize={{ base: 'xs', md: 'md' }}
              >
                {t('documents.refused')}
                {' '}
                <span style={{ fontWeight: 'bold' }}>{FormatDate(document?.dateControle)}</span>
                {' '}
              </Text>
            </Flex>
            <Flex
              w="100%"
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <Flex
                pr={{ base: 0, md: 2 }}
                py={{ base: 0, md: 2 }}
                w={{
                  base: '100%', md: '50%', lg: '50%', xl: '40%',
                }}
                flexDirection={{ base: 'column', md: 'row' }}
              >
                <TitleDocument
                  mb={{ base: 3, md: 0 }}
                  urlTarget={`${APPENV.HOST_API}/projet/${projectToken}/file/${documentHistorique?.file?.fichierId}`}
                  label={documentHistorique?.file?.fileName}
                  dateImport={documentHistorique?.file?.dateCreation}
                  nomImport={documentHistorique?.file?.data?.userNom}
                  prenomImport={documentHistorique?.file?.userPrenom}
                />
              </Flex>
              <Flex
                w={{
                  base: '100%', md: '50%', lg: '50%', xl: '60%',
                }}
                pl={{ base: 0, md: 2 }}
                py={{ base: 0, md: 2 }}
                flexDirection="column"
              >
                {
                  (documentHistorique?.file?.commentaireControle !== null && documentHistorique?.file?.commentaireControle !== undefined) && (
                    <>
                      <Text
                        as="b"
                        color="#707070"
                        fontSize={{ base: 'xs', md: 'md' }}
                      >
                        {t('documents.details.commentaire.refus')}
                      </Text>
                      <Text
                        color="black"
                        as="i"
                        fontSize="md"
                      >
                        {`« ${documentHistorique?.file?.commentaireControle} »`}
                      </Text>
                    </>
                  )
                }
              </Flex>
            </Flex>
          </Flex>
        )
      }
    </Flex>
  );
};

BlocControle.propTypes = {
  validBloc: PropTypes.bool,
  refuseBloc: PropTypes.bool,
  historicBloc: PropTypes.bool,
  document: PropTypes.shape(PropTypes.object),
  projectToken: PropTypes.string.isRequired,
  documentHistorique: PropTypes.shape(PropTypes.object),
};

BlocControle.defaultProps = {
  validBloc: false,
  refuseBloc: false,
  historicBloc: false,
  document: null,
  documentHistorique: null,
};
