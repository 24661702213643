import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { PageContent, PageHeader, PageFooter } from 'layout';
import {
  Heading,
  Flex,
  Stack,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Grid,
  Button,
} from '@chakra-ui/core';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import axios from 'axios';
import { APPENV } from 'services/config';
import { useTranslation } from 'react-i18next';
import {
  FaTasks, FaAddressBook, FaCogs, FaFlag,
} from 'react-icons/fa';
import { DatasProjetConfiguration } from './_partials/Projet';
import { DatasPhasesConfiguration } from './_partials/Phases';
import { IntervenantsConfiguration } from './_partials/Intervenants/IntervenantsConfiguration';
import { LotsConfiguration } from './_partials/Lots';

export const ProjectConfiguration = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { projectToken } = useParams();
  const [isSelected, setIsSelected] = useState({
    projet: true, phases: false, intervenants: false, lots: false,
  });
  const [phasesProjet, setPhasesProjet] = useState([]);

  const handleBack = () => {
    history.push({
      pathname: '/projects',
    });
  };

  const {
    data: projet, isLoading, isFetching, isError,
  } = useQuery(['configuration', projectToken], async () => {
    const result = await axios.get(`${APPENV.HOST_API}/projet/${projectToken}/datasForUpdate`);

    const phasesList = result?.data?.projet?.projet?.phases?.map((phase) => {
      const libelle = phase.data.phaseId === null ? phase.data.phaseLibelleCustom
        : t(phase.data.phaseLibelle, { defaultValue: phase.data.phaseLibelle });
      return (
        {
          label: libelle,
          codePhase: `${phase.data.codePhase}`,
          id: `${phase.data.id}`,
          phaseId: `${phase.data.phaseId}`,
          dateDebut: `${phase.data.dateDebut}`,
          dateFin: `${phase.data.dateFin}`,
          ordre: phase.data.ordre,
          statut: phase.data.statutLibelle,
          canDelete: phase.auth.canDelete,
          canUpdate: phase.auth.canUpdate,
        }
      );
    });

    setPhasesProjet(phasesList);

    return result;
  }, [projectToken]);

  if (isLoading || isFetching) {
    return (
      <>
        <PageContent>
          <Stack flex={1} align="center" justify="center">
            <Spinner size="xl" color="brand.500" />
          </Stack>
        </PageContent>
      </>
    );
  }

  if (isError) {
    return (
      <>
        <PageContent>
          <Stack flex={1}>
            <Alert
              status="error"
              variant="subtle"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon size="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                {t('error.load.projet.libelle', { defaultValue: 'Error while downloading project' })}
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                {t('error.load.projet.message', { defaultValue: 'Error while downloading project' })}
              </AlertDescription>
            </Alert>
          </Stack>
        </PageContent>
      </>
    );
  }

  if (!projet?.data?.projet?.projet?.auth.canConfigurer) {
    return (<Redirect to="/login" />);
  }

  return (
    <>
      <PageHeader onGoBack={() => handleBack()}>
        <Flex direction="column">
          <Heading as="h1" size="sm">
            {projet?.data?.projet?.projet?.data?.nom}
          </Heading>
        </Flex>
      </PageHeader>
      <PageContent
        mb="5rem"
      >
        <Grid
          pl={4}
          pr={4}
          mb={6}
          mt={6}
          templateColumns={{
            base: '1fr',
            sm: 'repeat(2, 1fr) auto',
            md: 'repeat(4, 1fr) auto',
            lg: 'repeat(4, 1fr) auto',
          }}
          gap={6}
        >
          {/* { isSelected === 'projet' && backgroundColor="brand.500" color="white" } */}
          <Button
            {...(isSelected.projet
              ? { backgroundColor: 'brand.500', color: 'white' }
              : '')}
            h="50px"
            onClick={() => setIsSelected({
              projet: true,
              phases: false,
              intervenants: false,
              lots: false,
            })}
            leftIcon={FaCogs}
            variantColor="brand"
            variant="outline"
            bg="white"
            _hover={{
              borderColor: 'gray.50',
              border: '1px',
              boxShadow: 'xl',
            }}
          >
            Projet
          </Button>
          <Button
            {...(isSelected.intervenants
              ? { backgroundColor: 'brand.500', color: 'white' }
              : '')}
            h="50px"
            onClick={() => setIsSelected({
              projet: false,
              phases: false,
              intervenants: true,
              lots: false,
            })}
            leftIcon={FaAddressBook}
            variantColor="brand"
            variant="outline"
            bg="white"
            _hover={{
              borderColor: 'gray.50',
              border: '1px',
              boxShadow: 'xl',
            }}
          >
            Intervenants
          </Button>
          <Button
            {...(isSelected.phases
              ? { backgroundColor: 'brand.500', color: 'white' }
              : '')}
            h="50px"
            onClick={() => setIsSelected({
              projet: false,
              phases: true,
              intervenants: false,
              lots: false,
            })}
            leftIcon={FaFlag}
            variantColor="brand"
            variant="outline"
            bg="white"
            _hover={{
              borderColor: 'gray.50',
              border: '1px',
              boxShadow: 'xl',
            }}
          >
            Phases
          </Button>
          <Button
            {...(isSelected.lots
              ? { backgroundColor: 'brand.500', color: 'white' }
              : '')}
            h="50px"
            onClick={() => setIsSelected({
              projet: false,
              phases: false,
              intervenants: false,
              lots: true,
            })}
            leftIcon={FaTasks}
            variantColor="brand"
            variant="outline"
            bg="white"
            _hover={{
              borderColor: 'gray.50',
              border: '1px',
              boxShadow: 'xl',
            }}
          >
            Lots
          </Button>
        </Grid>

        {isSelected.projet && (
          <DatasProjetConfiguration
            projetIntervenant={projet?.data?.projet?.projet?.intervenants}
            projetData={projet?.data?.projet?.projet?.data}
          />
        )}

        {isSelected.phases && (
          <DatasPhasesConfiguration
            projetData={projet?.data?.projet?.projet?.data}
            defaultPhases={projet?.data?.listes['phase.nom']}
            projetPhases={phasesProjet}
            setPhasesProjet={setPhasesProjet}
          />
        )}
        {isSelected.intervenants && (
          <IntervenantsConfiguration
            projetData={projet?.data?.projet?.projet?.data}
            rolesListe={projet?.data?.listes['intervenant.profil']}
            entreprises={projet?.data?.entreprises}
            defaultContact={projet?.data?.contacts}
            projetIntervenants={projet?.data?.projet?.projet?.intervenants}
          />
        )}

        {isSelected.lots && (
          <LotsConfiguration
            projetData={projet?.data?.projet?.projet?.data}
            defaultLots={projet?.data?.listes['lot.nom']}
            projetPhases={phasesProjet}
          />
        )}
      </PageContent>
      <PageFooter />
    </>
  );
};
