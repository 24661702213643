import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@chakra-ui/core';

const propTypes = {
  children: PropTypes.node.isRequired,
  noHeader: PropTypes.bool,
};

const defaultProps = {
  noHeader: false,
};

export const PageContent = ({ children, noHeader, ...rest }) => (
  <Flex
    mt={{ base: '4.5rem', md: '4.5rem' }}
    direction="column"
    flexGrow="1"
    {...rest}
  >
    {children}
  </Flex>
);

PageContent.propTypes = propTypes;
PageContent.defaultProps = defaultProps;
