import axios from 'axios';
import { useQuery, useMutation } from 'react-query';

export const useTodos = (params) => useQuery(['todos', params],
  async () => axios.get('https://jsonplaceholder.typicode.com/todos', { params }));

export const useCreateTodo = (config) => useMutation(
  () => axios.post('https://jsonplaceholder.typicode.com/todos'),
  config,
);
