/* eslint-disable react/prop-types */
import React from 'react';
import { useQuery } from 'react-query';
import {
  Heading,
  SimpleGrid,
  Stack,
  Spinner,
  Box,
  Alert,
  AlertIcon,
  Flex,
  Text,
} from '@chakra-ui/core';
import { PageContent, PageFooter } from 'layout';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { APPENV } from 'services/config';
import { ProjectCard } from './_partials/ProjectCard';
import { NewProject } from './NewProject';

export const Dashboard = () => {
  const { t } = useTranslation();

  function onClose(){
    console.log('onClose')
  }

  const {
    data: projects, isLoading, isFetching, isError,
  } = useQuery('projects', () => axios.get(
    `${APPENV.HOST_API}/projets?statut=ENCOURS`,
  ), { refetchOnWindowFocus: true });

  return (
    <>
      <PageContent p={4} mt="2rem" mb="3.5rem" noHeader>
        <Heading
          fontSize="2xl"
          mb="4"

        >
          {t('dashboard.currentProjects', { defaultValue: 'Mes projets en cours' })}
          {!isLoading && isFetching && <Spinner ml="4" size="4" emptyColor="gray.200" color="brand.500" />}
        </Heading>

        {/* S'il n'y a pas de projets */}
        {
          projects?.data?.length === 0 && (

            <Alert
              p={10}
              mt={5}
              rounded={5}
              status="info"
              d="flex"
              justifyContent="center"
            >
              <Flex
                flexDirection="column"
              >
                <Flex>
                  <AlertIcon />
                  <Text
                    textAlign="center"
                  >
                    {t('projet.message.vide')}
                  </Text>
                </Flex>
              </Flex>
            </Alert>
          )
        }

        {isLoading && (
          <Stack align="center">
            <Spinner emptyColor="gray.200" color="brand.500" />
          </Stack>
        )}

        {!isLoading && isError && (
          <Stack spacing={3}>
            <Alert status="error">
              <AlertIcon />
              {t('error.load.projet.liste', { defaultValue: 'Erreur lors de la récupération de la liste des projets' })}
            </Alert>
          </Stack>
        )}

        {!isLoading && !isError && projects && (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
            {projects?.data?.map((project) => (
              <ProjectCard
                auth={project.projet.auth}
                key={project.projet.data.id}
                project={project.projet}
              />
              ))}
            <Box>
              {/* <Button
                w="100%"
                h="100%"
                minW="200px"
                minH="200px"
                bg="white"
                rounded="md"
                borderWidth="1px"
                borderStyle="dashed"
                onClick={onOpen}
              >
                <Stack align="center">
                  <Icon icon={FaPlus} color="brand.500" />
                  <Text>{t('projet.new', { defaultValue: 'Créer un nouveau projet' })}</Text>
                </Stack>
              </Button> */}
            </Box>
          </SimpleGrid>
        )}
        {/* <NewProject isOpen={true} onClose={onClose} /> */}
      </PageContent>
      <PageFooter />
    </>
  );
};
