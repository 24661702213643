import React, { } from 'react';
import { useQuery, queryCache } from 'react-query';
import { PageContent, PageHeader, PageFooter } from 'layout';
import {
  Flex,
  Stack,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useToast,
  Button,
} from '@chakra-ui/core';
import {
  FieldUppy,
} from 'components';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { APPENV } from 'services/config';
import { useTranslation } from 'react-i18next';
import { Formiz, useForm } from '@formiz/core';
import { TitleDocument } from 'components/TitleDocument';
import { CardDocument } from 'components/Card/Document/CardDocument';
import { CardDocumentHeader } from 'components/Card/Document/CardDocumentHeader';
import { CardDocumentBody } from 'components/Card/Document/CardDocumentBody';
import { BlocControle } from 'components/Card/Document/BlocControle';
import { FieldInputTextArea } from 'components/Fields/FieldInputTextArea';

export const Document = () => {
  const {
    phaseId, projectToken, documentToken, documentId,
  } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const documentForm = useForm();
  const toast = useToast();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const lotId = parseInt(params.get('lotId'), 10);
  const livrableId = parseInt(params.get('livrableId'), 10);
  const {
    data: document, isLoading, isError,
  } = useQuery(['documentToken', documentToken], () => axios.get(`${APPENV.HOST_API}/projet/${projectToken}/document/${documentId}`));

  const handleInvalidate = () => {
    queryCache.invalidateQueries(['documentToken'], [documentToken]);
  };
  const handleSubmit = async (values) => {
    const listItems = values?.files?.map((file) => file.hash);

    const formData = {
      hashfiles: listItems,
      document_commentaire_controle: values.document_commentaire_controle,
    };
    try {
      await axios.post(values.url, formData);
    } catch (err) {
      toast({
        title: 'Document not updated',
        description: 'Failed to update',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    }

    history.push({
      pathname: `/projects/${projectToken}/phase/${phaseId}`,
      search: `?lotId=${lotId}&livrableId=${livrableId}`,
    });

    await queryCache.invalidateQueries('documentToken', documentToken);
  };
  const handleUrl = async (url, type) => {
    const datas = documentForm.values;
    datas.url = url;

    if (type === 'valider') {
      documentForm.isValid = true;
    }

    if (documentForm.isValid) {
      handleSubmit(datas);
    } else {
      documentForm.submit();
    }
  };

  const handleBack = () => {
    history.push({
      pathname: `/projects/${projectToken}/phase/${phaseId}`,
      search: `?lotId=${lotId}&livrableId=${livrableId}`,
    });
  };

  if (isLoading) {
    return (
      <>
        <PageContent>
          <Stack flex={1} align="center" justify="center">
            <Spinner size="xl" color="brand.500" />
          </Stack>
        </PageContent>
        <PageFooter />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <PageContent>
          <Stack flex={1}>
            <Alert
              status="error"
              variant="subtle"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon size="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                {t('error.load.projet.libelle', { defaultValue: 'Error while downloading document' })}
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                {t('error.load.projet.message', { defaultValue: 'Error while downloading document' })}
              </AlertDescription>
            </Alert>
          </Stack>
        </PageContent>
        <PageFooter />
      </>
    );
  }

  const auth = document?.data?.files?.auth;
  return (
    <Formiz connect={documentForm} autoForm noValidate>
      <PageHeader
        p={3}
        onGoBack={() => handleBack()}
      />
      <PageContent>
        {/* Détail du document */}
        {
          document?.data?.files?.data !== undefined && (
            <CardDocument>
              <CardDocumentHeader
                statutLibelle={document?.data?.files?.data?.statutLibelle}
                hasBadge
              >
                {`${t('documents.details.libelle', { defaultValue: 'Document details' })}`}
              </CardDocumentHeader>
              <CardDocumentBody>
                <Flex>
                  <TitleDocument
                    urlTarget={`${APPENV.HOST_API}/projet/${projectToken}/file/${document?.data?.files?.data?.fichierId}`}
                    label={document?.data?.files?.data?.fileName}
                    dateImport={document?.data?.files?.data?.dateCreation}
                    nomImport={document?.data?.files?.data?.userNom}
                    prenomImport={document?.data?.files?.data?.userPrenom}
                  />
                </Flex>
                {
                  document?.data?.files?.data?.statutLibelle === 'statut.valide' && (
                    <BlocControle
                      validBloc
                      projectToken={projectToken}
                      document={document}
                    />
                  )
                }
                {
                  document?.data?.files?.data?.statutLibelle === 'statut.refuse' && (
                    <BlocControle
                      refuseBloc
                      projectToken={projectToken}
                      document={document}
                    />
                  )
                }
              </CardDocumentBody>
            </CardDocument>
          )
        }
        {/* Charger une nouvelle version */}
        {auth.canRenvoyer && (
          <CardDocument>
            <CardDocumentHeader>
              {t('documents.actions.controle')}
            </CardDocumentHeader>
            <CardDocumentBody>
              {/* Zone de renvoi */}
              <Flex
                flexDirection="column"
              >
                <FieldUppy
                  w="100%"
                  validations={[
                    {
                      rule: (files) => !files || files.length === 1,
                      message: 'Only 1 file',
                    }]}
                  url={`${APPENV.HOST_API}/projet/${projectToken}/file/upload/temporary`}
                  name="files"
                  required="required"
                  fromForm
                  callbackForRefresh={handleInvalidate}
                />

                {/* Bouton de renvoi */}
                <Flex>
                  <Button
                    ml="auto"
                    w={{ base: '100%', md: '20%' }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleUrl(`${APPENV.HOST_API}/projet/${projectToken}/document/${document?.data?.files?.data?.id}`, 'renvoyer');
                    }}
                    type="button"
                    variantColor="brand"
                  >
                    {t('enregistrer', { defaultValue: 'enregistrer' })}
                  </Button>
                </Flex>
              </Flex>
            </CardDocumentBody>
          </CardDocument>
        )}

        {/* Refuser et valider */}
        {(auth.canRefuser || auth.canValider) && (
          <CardDocument>
            <CardDocumentHeader>
              {t('documents.actions.controle')}
            </CardDocumentHeader>
            <CardDocumentBody>
              <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                w="100%"
              >
                <Flex
                  flexDirection={{ base: 'column', xl: 'row' }}
                  w={{ base: '100%', md: '100%' }}
                >
                  {/* Zone de commentaire */}
                  <Flex
                    mb={{ base: 3 }}
                    h="11rem"
                    pr={{ base: 0, xl: 2 }}
                    w={{ base: '100%', md: '100%', xl: '50%' }}
                    flexDirection={{ base: 'column' }}
                  >
                    <FieldInputTextArea
                      w="100%"
                      name="document_commentaire_controle"
                      placeholder={t('documents.commentaire')}
                      type="textarea"
                      required
                    />
                  </Flex>

                  <Flex
                    pl={{ base: 0, xl: 2 }}
                    w={{ base: '100%', md: '100%', xl: '50%' }}
                    flexDirection="column"
                  >
                    {/* Zone upload */}
                    <FieldUppy
                      w="100%"
                      url={`${APPENV.HOST_API}/projet/${projectToken}/file/upload/temporary`}
                      name="files"
                      perimetre="documents.controle"
                      fromForm
                      callbackForRefresh={handleInvalidate}
                    />

                    <Flex
                      justifyContent="space-between"
                      flexDirection={{ base: 'column', xl: 'row' }}
                    >
                      {/* Les deux boutons */}
                      <Button
                        fontSize="xs"
                        w={{ base: '100%', xl: '48%' }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUrl(`${APPENV.HOST_API}/projet/${projectToken}/document/${document?.data?.files?.data?.id}/valider`, 'valider');
                        }}
                        type="button"
                        variantColor="brand"
                        mb={{ base: 3, xl: 0 }}
                      >
                        {t('documents.valider')}
                      </Button>
                      <Button
                        fontSize="xs"
                        w={{ base: '100%', xl: '48%' }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUrl(`${APPENV.HOST_API}/projet/${projectToken}/document/${document?.data?.files?.data?.id}/refuser`, 'refuser');
                        }}
                        type="button"
                        variant="outline"
                        variantColor="danger"
                      >
                        {t('documents.refuser')}
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </CardDocumentBody>
          </CardDocument>
        )}
        {/* Historique des refus */}
        {
          typeof (document?.data?.filesHisto) !== 'undefined' && (
            <CardDocument>
              <CardDocumentHeader>
                {t('documents.historique.libelle')}
              </CardDocumentHeader>
              <CardDocumentBody>
                {
                  (Object.keys(document?.data?.filesHisto).length > 0 && document?.data?.filesHisto !== undefined)
                  && Object.values(document?.data?.filesHisto)?.map((documentHistorique) => (
                    <BlocControle
                      mb={2}
                      historicBloc
                      key={documentHistorique.file.fichierId}
                      documentHistorique={documentHistorique}
                      projectToken={projectToken}
                    />
                  ))
                }
              </CardDocumentBody>
            </CardDocument>
          )
        }
      </PageContent>
      <PageFooter />
    </Formiz>
  );
};
