/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import Axios from 'axios';

Axios.interceptors.response.use((response) => response?.data, (error) => {
  if (error?.response?.request.response) {
    const errorOjb = JSON.parse(error?.response?.request.response);
    // Si l'utilisateur est enlevé de Firebase il y a redirection login et fin de la session
    if (errorOjb.statusCode === 405) {
      alert('Vous n\'êtes pas autoriser à acceder à l\'application');
      window.location.href = '/signout';
    } else if (errorOjb.statusCode === 401) {
      alert('Vous n\'êtes plus authentifié auprès de Firebase. Merci de vous authentifier');
      window.location.href = '/login';
    }
  }
  return Promise.reject(error);
});

Axios.interceptors.request.use((config) => {
  config.headers.uid = JSON.parse(localStorage.getItem('uid'));
  config.headers.email = JSON.parse(localStorage.getItem('email'));

  return config;
},
  (error) => Promise.reject(error));
