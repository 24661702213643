import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/core';
import { Icon } from 'components/Icon';

export const CircularIcon = ({
  icon,
  size,
  variantColor,
  variant,
  ...props
}) => {
  const getStyles = () => {
    switch (variant) {
      case 'solid':
        return {
          bg: `${variantColor}.400`,
          color: 'white',
        };
      case 'light':
        return {
          bg: `${variantColor}.50`,
          color: `${variantColor}.400`,
        };
      case 'default':
      default:
        return {
          bg: `${variantColor}.100`,
          color: `${variantColor}.600`,
        };
    }
  };
  return (
    <Box
      as="span"
      d="inline-block"
      w="2em"
      h="2em"
      borderRadius="full"
      position="relative"
      flex="none"
      fontSize={size}
      {...getStyles()}
      {...props}
    >
      <Icon
        as={icon}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
    </Box>
  );
};

CircularIcon.propTypes = {
  icon: PropTypes.func,
  size: PropTypes.oneOf(['2xs', 'xs', 'sm', 'md', 'lg', 'xl']),
  variantColor: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'light', 'solid']),
};

CircularIcon.defaultProps = {
  icon: null,
  size: 'md',
  variantColor: 'gray',
  variant: 'default',
};
