import React, { useState } from 'react';
import {
  Flex, Text, PseudoBox, useToast,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FaArrowLeft } from 'react-icons/fa';
import { FieldInput } from 'components';
import { Formiz, useForm } from '@formiz/core';
import { Enregistrer } from 'components/Button/Enregistrer';
import { useUpdateContact } from 'pages/ProjectDetails/_partials/ProjectContact/EditContact/useUpdateContact';
import { queryCache } from 'react-query';
import { isNumber } from '@formiz/validations';

export const EditProfile = ({ informationsProfile, returnFunction }) => {
  // Hook
  const { t } = useTranslation();
  const toast = useToast();
  const formEditProfile = useForm();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [mutate] = useUpdateContact({
    onError: () => {
      setIsSubmiting(false);
    },
    onSuccess: async ({ data }) => {
      // Chargement terminé
      // Mise à jour de la dépendance contact pour rafraîchir les informations dans la modale
      // AffichageTost && fermeture de la modale
      await queryCache.invalidateQueries(['informations-personnelles', informationsProfile.email]);

      toast({
        title: t('update.success', { defaultValue: 'Modification terminée' }),
        description: `${data.nom} ${data.prenom} a été mis à jour avec succès`,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
      // Fin du spinner
      setIsSubmiting(false);
      // Retour sur la page read only
      returnFunction(false);
    },
  });

  // Fonction au submit de l'update d'un contact
  const handleEdit = async (values) => {
    // Début du spinner
    setIsSubmiting(true);

    // Rajout du mail et de l'id
    const informationsHidden = { id: informationsProfile.id, email: informationsProfile.email };
    const datas = { ...values, ...informationsHidden };
    mutate(datas);
  };

  return (
    <>
      <Flex
        backgroundColor="white"
        border="1px"
        borderColor="gray.50"
        rounded="0.5rem"
        flexDirection="column"
        pt={4}
      >
        <Formiz onValidSubmit={handleEdit} connect={formEditProfile} autoForm>

          {/* Titre +boutton retour */}
          <Flex
            pb={2}
            justifyContent="start"
          >
            <Flex
              w={{ base: '10%', lg: '15%' }}
              p={2}
              justifyContent="center"
              alignItems="center"
            >
              <PseudoBox
                h="2rem"
                w="2rem"
                as={FaArrowLeft}
                color="green.800"
                onClick={() => returnFunction(false)}
                cursor="pointer"
                _hover={{
                backgroundColor: 'gray.50',
              }}
              />
            </Flex>
            <Flex
              w={{ base: '90%', lg: '85%' }}
              p={2}
              justifyContent="center"
              alignItems="center"
            >
              <Text
                as="b"
                textAlign="center"
                fontSize={{ base: 'md', lg: '2xl' }}
              >
                {t('contact.form.placeholder.editInformationsPersonnelles')}
              </Text>
            </Flex>
          </Flex>

          {/* Formulaire */}
          <Flex
            p={2}
            w="100%"
            flexDirection="column"
          >
            {/* Bloc nom + prénom */}
            <Flex
              w="100%"
              justifyContent="space-between"
              mb={2}
            >
              {/* Nom */}
              <FieldInput
                w="49%"
                name="nom"
                placeholder={t('contact.form.placeholder.nom')}
                defaultValue={informationsProfile?.nom ?? ''}
              />

              {/* Prenom */}
              <FieldInput
                w="49%"
                name="prenom"
                placeholder={t('contact.form.placeholder.prenom')}
                defaultValue={informationsProfile?.prenom ?? ''}
              />
            </Flex>
            {/* adresse1 */}
            <FieldInput
              name="adresse1"
              placeholder={t('contact.form.placeholder.adresse1')}
              defaultValue={informationsProfile?.adresse1 ?? ''}
              mb={2}
            />

            {/* adresse2 */}
            <FieldInput
              name="adresse2"
              placeholder={t('contact.form.placeholder.adresse2')}
              defaultValue={informationsProfile?.adresse2 ?? ''}
              mb={2}
            />

            {/* adresse3 */}
            <FieldInput
              name="adresse3"
              placeholder={t('contact.form.placeholder.adresse3')}
              defaultValue={informationsProfile?.adresse3 ?? ''}
              mb={2}
            />

            {/* Ville + code postal */}
            <Flex
              justifyContent="space-between"
              mb={2}
            >
              {/* Ville */}
              <FieldInput
                w="50%"
                name="ville"
                placeholder={t('contact.form.placeholder.ville')}
                defaultValue={informationsProfile?.ville ?? ''}
              />

              {/* Code postal */}
              <FieldInput
                w={{ base: '30%', lg: '20%' }}
                name="codePostal"
                placeholder={t('contact.form.placeholder.codePostal')}
                defaultValue={informationsProfile?.codePostal ?? ''}
                validations={[
                {
                  rule: isNumber(5),
                  message: t('form.error.codePostal'),
                },
              ]}
              />
            </Flex>

            {/* Téléphone1 */}
            <FieldInput
              name="telephone1"
              placeholder={t('contact.form.placeholder.telephone1')}
              defaultValue={informationsProfile?.telephone1 ?? ''}
              mb={2}
              validations={[
              {
                rule: isNumber(10),
                message: t('form.error.telephone'),
              },
            ]}
            />

            {/* Téléphone2 */}
            <FieldInput
              name="telephone2"
              placeholder={t('contact.form.placeholder.telephone2')}
              defaultValue={informationsProfile?.telephone2 ?? ''}
              mb={2}
              validations={[
              {
                rule: isNumber(10),
                message: t('form.error.telephone'),
              },
            ]}
            />

            {/* Désignation métier */}
            <FieldInput
              name="designationMetier"
              placeholder={t('contact.form.placeholder.designationMetier')}
              defaultValue={informationsProfile?.designationMetier ?? ''}
              mb={2}
            />
          </Flex>
          {/* Formulaire */}
          <Flex
            p={2}
            w="100%"
            justifyContent="end"
          >
            <Enregistrer
              isLoading={isSubmiting}
            />
          </Flex>
        </Formiz>

      </Flex>
    </>
  );
};

EditProfile.propTypes = {
  informationsProfile: PropTypes.shape(
    PropTypes.object,
  ),
  returnFunction: PropTypes.func.isRequired,
};

EditProfile.defaultProps = {
  informationsProfile: {},
};
