import React from 'react';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import {
  IconButton, Input, InputGroup, InputRightElement, Textarea,
} from '@chakra-ui/core';
import { FormGroup } from 'components';
import { FaSave } from 'react-icons/fa';
import PropTypes from 'prop-types';

const propTypes = {
  textAlign: PropTypes.string,
  ...fieldPropTypes,
};

const defaultProps = {
  textAlign: 'left',
  ...fieldDefaultProps,
};

export const FieldInput = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    setValue,
    value,
  } = useField(props);
  const {
    label, type, required, isDisabled, placeholder, textAlign, ...otherProps
  } = props;

  const {
    activeRightButton, handleClickRightButton, customId,
  } = props;

  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };

  const getElementAndProps = () => {
    if (type === 'textarea') {
      return [Textarea, {
        height: '1.8rem',
      }];
    }
    return [Input, {}];
  };

  const [Element, inputExtraProps] = getElementAndProps();

  return (
    <FormGroup {...formGroupProps}>
      <InputGroup>
        <Element
          textAlign={textAlign}
          id={id}
          type={type || 'text'}
          value={value ?? ''}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {
            setIsTouched(true);
          }}
          placeholder={placeholder}
          aria-invalid={showError}
          aria-required={!!required}
          aria-describedby={showError ? `${id}-error` : null}
          {...inputExtraProps}
        />

        {activeRightButton && (
          <InputRightElement width="4.5rem">
            <IconButton
              onClick={() => handleClickRightButton(customId, value)}
              variantColor="brand"
              variant="ghost"
              icon={FaSave}
              _active={{
                border: 'none',
              }}
              _hover={{
                color: 'green.200',
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </FormGroup>
  );
};

FieldInput.propTypes = propTypes;
FieldInput.defaultProps = defaultProps;
