import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { PseudoBox, Box, Flex } from '@chakra-ui/core';
import { CollapseContext } from './Collapse';

export const CollapseHeader = ({ children, ...props }) => {
  const { variant, isOpen, setIsOpen } = useContext(CollapseContext);

  const handleToggle = (e) => {
    if (!e) return;
    if (e?.type === 'click') setIsOpen((toggle) => !toggle);
    if (e?.type === 'keydown' && (e.key === 'Enter' || e.key === ' ')) setIsOpen((toggle) => !toggle);
  };

  const getStyles = () => {
    if (variant === 'card') {
      return {
        boxShadow: 'card',
        borderColor: 'gray.50',
      };
    }

    return {};
  };

  return (
    <PseudoBox
      bg="white"
      borderTopLeftRadius="lg"
      borderTopRightRadius="lg"
      borderBottomLeftRadius={!isOpen ? 'lg' : null}
      borderBottomRightRadius={!isOpen ? 'lg' : null}
      borderWidth="1px"
      cursor="pointer"
      px="6"
      py="3"
      outline="none"
      onClick={handleToggle}
      onKeyDown={handleToggle}
      role="button"
      tabIndex="0"
      aria-pressed={isOpen}
      aria-expanded={isOpen}
      _hover={{
        borderColor: 'gray.50',
        border: '1px',
        boxShadow: 'xl',
      }}
      _focus={{
        borderColor: `gray.${variant === 'card' ? '200' : '400'}`,
      }}
      {...getStyles()}
      {...props}
    >
      <Flex>
        <Box flexGrow="1">{children}</Box>
        {/* <Icon
          alignSelf="center"
          icon={FaCaretDown}
          transform={isOpen ? 'rotate(180deg)' : null}
          transition="transform 0.2s"
          color="brand.500"
          size="1.5rem"
        /> */}
      </Flex>
    </PseudoBox>
  );
};

CollapseHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

CollapseHeader.defaultProps = {
  children: null,
};
