import React, { useState } from 'react';
import {
  Flex, Heading, Box, Button, Stack, Spinner, Image,
} from '@chakra-ui/core';
import { Formiz, useForm } from '@formiz/core';
import { isEmail, isMinLength } from '@formiz/validations';
import { FieldInput } from 'components/Fields';
import { useTranslation } from 'react-i18next';
import { createUserWithEmailAndPassword } from 'config/firebase';
import { useAuthentificationContext } from 'context/auth-context';
import { PageContent } from 'layout';
import { APPENV } from 'services/config';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

export const Register = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [isReseting, setIsReseting] = useState(false);
  const loginForm = useForm();
  const { t } = useTranslation();
  const { initialisation, isLogged } = useAuthentificationContext();

  const handleInscription = async (values) => {
    setIsLoading(true);

    await axios.post(`${APPENV.HOST_API}/check-register`, values);
    createUserWithEmailAndPassword(values.login, values.password).then(() => {
      setIsLoading(false);
    }).catch(() => {
    });
  };

  return (

    <>

      {initialisation && (
        <>
          <PageContent>
            <Stack flex={1} align="center" justify="center">
              <Spinner size="xl" color="brand.500" />
            </Stack>
          </PageContent>

        </>
      )}

      {!isLoading && isLogged && !initialisation && (
        <Redirect to="/" />
      )}

      {
        !isLoading && !isLogged && !initialisation && (
          <Flex h="100%" bg="#f7f7f7">
            <Flex bg="white" rounded="1rem" p="8" m="auto" direction="column" align="center" boxShadow="card">
              <Heading d="flex" mb={2} alignSelf="center" size="2xl">
                <Image src="/media/Project IN - Logo - 128.png" />
              </Heading>
              <Box>
                <Formiz
                  connect={loginForm}
                  onValidSubmit={handleInscription}
                >
                  <form
                    noValidate
                    onSubmit={loginForm.submit}
                  >

                    <FieldInput
                      name="login"
                      type="email"
                      required={t('errors.required')}
                      label={t('email', { defaultValue: 'Email' })}
                      validations={[
                        {
                          rule: isEmail(),
                          message: t('errors.isEmail'),
                        },
                      ]}
                      my={2}
                    />
                    <FieldInput
                      name="password"
                      type="password"
                      required={t('errors.required')}
                      label={t('password', { defaultValue: 'Password' })}
                      validations={[
                        {
                          rule: isMinLength(8),
                          message: t('errors.isMinLength', { minLength: 8 }),
                        },
                      ]}
                      my={2}
                    />

                    <Button
                      type="submit"
                      my={2}
                      color="gray.900"
                      bg="green.500"
                      // isLoading={isLoading}
                      w="100%"
                    >
                      {t('register', { defaultValue: 'Inscription' })}
                    </Button>
                  </form>
                </Formiz>
              </Box>
            </Flex>
          </Flex>
        )
      }

    </>
  );
};
