import React, { useState } from 'react';
import Uppy from '@uppy/core';
import { DragDrop, StatusBar } from '@uppy/react';
import {
  Box, Stack, ListIcon, ListItem, List,
} from '@chakra-ui/core';
import {
  FormGroup,
} from 'components';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import XHRUpload from '@uppy/xhr-upload';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * WIP component done during the training
 * Not working, need more dev to work
 */

const propTypes = {
  ...fieldPropTypes,
};

const defaultProps = {
  ...fieldDefaultProps,
};

export const FieldUppy = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    setValue,
  } = useField(props);

  const { t } = useTranslation();
  const [errorUpload, setErrorUpload] = useState([]);
  const {
    livrableId, url, callbackForRefresh,
  } = props;
  // Do all the configuration here
  const uppy = React.useMemo(() => Uppy({ autoProceed: true })
    .use(XHRUpload, {
      headers: {
        uid: JSON.parse(localStorage.getItem('uid')),
        email: JSON.parse(localStorage.getItem('email')),
      },
      formData: true,
      endpoint: url,
      method: 'post',
    }),
    [url]);
  uppy.setMeta({ livrableId });
  uppy.on('complete', (result) => {
    // Cas du fail
    if (result.failed.length !== 0) {
      result.failed.map((x, index) => (
        setErrorUpload(
          {
            key: index,
            message: t(x.response.body.error.description),
          },
        )
      ));
    }
    // Cas du succès
    if (result.successful.length !== 0) {
      callbackForRefresh();
      setValue(result.successful.map((x, index) => (
        {
          key: index,
          name: x.name,
          hash: x.response.body.data.hashFileName,
        })));
    }
  });

  React.useEffect(() => () => uppy.close(), [uppy]);

  const {
    label, type, required, isDisabled, placeholder, ...otherProps
  } = props;
  const showError = !isValid && isSubmitted;
  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };

  return (
    <FormGroup {...formGroupProps}>
      <Stack>
        <Box>
          <DragDrop
            height="8rem"
            uppy={uppy}
            locale={{
              strings: {
                // Text to show on the droppable area.
                // `%{browse}` is replaced with a link that opens the system file selection dialog.
                dropHereOr: t('uppy.dragndrop', { defaultValue: 'Drop here or %{browse}' }),
                // Used as the label for the link that opens the system file selection dialog.
                browse: 'browse',
              },
            }}
          />
        </Box>
        <StatusBar
          uppy={uppy}
          hideUploadButton
          hideAfterFinish={false}
          showProgressDetails
        />

        {
          errorUpload.length !== 0 && (
            <>
              {([errorUpload] || []).map((file) => (
                <List key={file.key}>
                  <ListItem key={file.key}>
                    <ListIcon icon="not-allowed" color="red.500" />
                    {file.message}
                  </ListItem>
                </List>
              ))}
            </>
          )
        }

        {/* {!!(value || []).length && (
          <Box>
            <Button
              size="sm"
              onClick={() => {
                setValue(null);
                uppy.reset();
              }}
              leftIcon={FaEraser}
              variantColor="brand"
              ml="auto"
            >
              Clear
            </Button>
          </Box>
        )} */}
      </Stack>
    </FormGroup>
  );
};

FieldUppy.propTypes = {
  callbackForRefresh: PropTypes.func.isRequired,
  ...propTypes,
};

FieldUppy.defaultProps = defaultProps;
