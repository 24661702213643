import React, { useState } from 'react';
import {
  Flex, Heading, Box, Button, Text, Stack, Spinner, Image, Divider, useToast,
} from '@chakra-ui/core';
import { Formiz, useForm } from '@formiz/core';
import { isEmail, isMinLength } from '@formiz/validations';
import { FieldInput } from 'components/Fields';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { signInWithPopup, signInWithEmailPassword, sendPasswordResetEmail } from 'config/firebase';
import { useAuthentificationContext } from 'context/auth-context';
import { TiVendorMicrosoft } from 'react-icons/ti';
import { FcGoogle } from 'react-icons/fc';
import { PageContent } from 'layout';
import { APPENV } from 'services/config';
import axios from 'axios';
import firebase from 'firebase';

export const providerMicrosoft = new firebase.auth.OAuthProvider('microsoft.com').addScope('email');
export const providerGoogle = new firebase.auth.OAuthProvider('google.com').addScope('email');

export const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isReseting, setIsReseting] = useState(false);
  const loginForm = useForm();
  const { t } = useTranslation();
  const { initialisation, isLogged } = useAuthentificationContext();
  const toast = useToast();
  const history = useHistory();



  const handleBack = () => {
    history.push({
      pathname: '/',
    });
  };

  const handleSignIn = (e, provider) => {
    setIsLoading(true);
    signInWithPopup(provider).then(async () => {
      await axios.get(`${APPENV.HOST_API}/check-auth`);
      setIsError(false);
      setIsLoading(false);
    }).catch(() => {
      setIsError(true);
      setIsLoading(false);
      toast({
        title: t('error.login.libelle', { defaultValue: 'Loggin failed' }),
        description: t('error.login.message', { defaultValue: 'Loggin failed' }),
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    });
  };

  const handleConnexion = (values) => {
    setIsLoading(true);

    signInWithEmailPassword(values.login, values.password).then(async () => {
      await axios.get(`${APPENV.HOST_API}/check-auth`);
      setIsError(false);
      setIsLoading(false);
    }).catch(() => {
      setIsError(true);
      setIsLoading(false);
      toast({
        title: t('error.login.libelle', { defaultValue: 'Loggin failed' }),
        description: t('error.login.message', { defaultValue: 'Loggin failed' }),
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    });
  };

  const handleClickResetPassword = () => {
    setIsReseting(true);
  };

  const handleResetPassword = (values) => {
    setIsLoading(true);
    sendPasswordResetEmail(values.login).then(async () => {
      toast({
        title: t('success.passwordsent.libelle', { defaultValue: 'Password Sent' }),
        description: t('success.passwordsent.message', { defaultValue: 'Password Sent' }),
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
      setIsReseting(false);
      setIsLoading(false);
    }).catch(() => {
      setIsError(true);
      setIsReseting(false);
      setIsLoading(false);
      toast({
        title: t('error.password.libelle', { defaultValue: 'Loggin failed' }),
        description: t('error.password.message', { defaultValue: 'Loggin failed' }),
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    });
  };

  return (

    <>

      {initialisation && (
        <>
          <PageContent>
            <Stack flex={1} align="center" justify="center">
              <Spinner size="xl" color="brand.500" />
            </Stack>
          </PageContent>

        </>
      )}

      {!isLoading && isLogged && !initialisation && !isError && (
        <Redirect to="/" />
      )}

      {!isLogged && !initialisation && isReseting && !isError && (

      <Flex h="100%" bg="#f7f7f7">
        <Flex bg="white" rounded="1rem" p="8" m="auto" direction="column" align="center" boxShadow="card">
          <Heading d="flex" mb={2} alignSelf="center" size="2xl">
            <Image src="/media/Project IN - Logo - 128.png" />
          </Heading>
          <Box>
            <Formiz
              connect={loginForm}
              onValidSubmit={handleResetPassword}
            >
              <form
                noValidate
                onSubmit={loginForm.submit}
              >

                <FieldInput
                  name="login"
                  type="email"
                  required={t('errors.required')}
                  label={t('email', { defaultValue: 'Email' })}
                  validations={[
                        {
                          rule: isEmail(),
                          message: t('errors.isEmail'),
                        },
                      ]}
                  my={2}
                />
                <Button
                  type="submit"
                  my={2}
                  color="gray.900"
                  bg="green.500"
                  isLoading={isLoading}
                  w="100%"
                >
                  {t('reset', { defaultValue: 'Reseting' })}
                </Button>
              </form>
            </Formiz>
            <Text
              as="button"
              my={2}
              color="gray.900"
              textAlign="left"
              fontSize="0.8rem"
              fontStyle="italic"
              textDecoration="underline"
              onClick={handleBack}
              w="100%"
            >
              {t('login.signin', { defaultValue: 'login' })}
            </Text>
          </Box>
        </Flex>
      </Flex>
      )}

      {
        !isLoading && !isLogged && !initialisation && !isReseting && (
          <Flex h="100%" bg="#f7f7f7">
            <Flex bg="white" rounded="1rem" p="8" m="auto" direction="column" align="center" boxShadow="card">
              <Heading d="flex" mb={2} alignSelf="center" size="2xl">
                <Image src="/media/Project IN - Logo - 128.png" />
              </Heading>
              <Box>
                <Formiz
                  connect={loginForm}
                  onValidSubmit={handleConnexion}
                >
                  <form
                    noValidate
                    onSubmit={loginForm.submit}
                  >

                    <FieldInput
                      name="login"
                      type="email"
                      required={t('errors.required')}
                      label={t('email', { defaultValue: 'Email' })}
                      validations={[
                      {
                        rule: isEmail(),
                        message: t('errors.isEmail'),
                      },
                    ]}
                      my={2}
                    />
                    <FieldInput
                      name="password"
                      type="password"
                      required={t('errors.required')}
                      label={t('password', { defaultValue: 'Password' })}
                      validations={[
                      {
                        rule: isMinLength(8),
                        message: t('errors.isMinLength', { minLength: 8 }),
                      },
                    ]}
                      my={2}
                    />


                    <Button
                      type="submit"
                      my={2}
                      color="gray.900"
                      bg="green.500"
                      isLoading={isLoading}
                      w="100%"
                    >
                      {t('connection', { defaultValue: 'Connexion' })}
                    </Button>
                    <Text
                      as="button"
                      my={2}
                      color="gray.900"
                      textAlign="left"
                      fontSize="0.8rem"
                      fontStyle="italic"
                      textDecoration="underline"
                      onClick={handleClickResetPassword}
                    // isLoading={isLoading}
                      w="100%"
                    >
                      {t('resetpassword', { defaultValue: 'reset password' })}
                    </Text>
                  </form>
                  <Divider />
                  <Stack>
                    {/* Bouton de connexion Microsoft */}
                    <Button
                      type="button"
                      onClick={(e) => handleSignIn(e, providerMicrosoft)}
                      my={2}
                      border="1px"
                      borderColor="#4286f5"
                      bg="none"
                      color="#4286f5"
                      _hover={{
                      bg: '#4286f5',
                      color: 'white',
                    }}
                    >
                      {t('auth.provider.microsoft.login', { defaultValue: 'Se connecter avec Microsoft' })}
                      <Text ml="2">
                        <TiVendorMicrosoft />
                      </Text>
                    </Button>
                  </Stack>

                  <Stack>
                    {/* Bouton de connexion Google */}
                    <Button
                      type="button"
                      my={2}
                      onClick={(e) => handleSignIn(e, providerGoogle)}
                    // isLoading={isLoading}
                      bg="none"
                      border="1px"
                      borderColor="#db4437"
                      color="#db4437"
                      _hover={{
                      bg: '#db4437',
                      color: 'white',
                    }}
                    >
                      {t('auth.provider.google.login', { defaultValue: 'Se connecter avec Google' })}
                      <Text ml="2">
                        <FcGoogle />
                      </Text>
                    </Button>
                  </Stack>

                </Formiz>
              </Box>
            </Flex>
          </Flex>
        )
      }

    </>
  );
};
