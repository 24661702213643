import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex, useTheme, PseudoBox,
} from '@chakra-ui/core';
import { FaArrowLeft } from 'react-icons/fa';

const propTypes = {
  onGoBack: PropTypes.func,
  children: PropTypes.node,
};

const defaultProps = {
  onGoBack: null,
  children: null,
};

export const PageHeader = ({
  onGoBack,
  children,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Flex
      bg="white"
      h={{ base: '', md: theme.headerHeight }}
      w="100%"
      boxShadow="0px 2px 14px 0px rgba(5,7,26,0.02)"
      alignItems="center"
      position="fixed"
      zIndex="1"
      {...rest}
    >
      {onGoBack && (
        <PseudoBox
          rounded="0.3rem"
          p="0.5rem"
          cursor="pointer"
          _hover={{
            backgroundColor: 'gray.50',
          }}
        >
          <PseudoBox
            h="1.5rem"
            w="1.5rem"
            as={FaArrowLeft}
            color="green.800"
            onClick={onGoBack}

          />
        </PseudoBox>
      )}
      <Flex flex={1} alignItems="center" justifyContent="space-between">
        {children}
      </Flex>
    </Flex>
  );
};

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;
