/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { TitleDocument } from 'components/TitleDocument';
import PropTypes from 'prop-types';
import {
  Text,
  Flex,
  useToast,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  PseudoBox,
} from '@chakra-ui/core';
import {
  Badge, Card, CardBody,
} from 'components';
import { useTranslation } from 'react-i18next';
import { APPENV } from 'services/config';
import { useParams, Link } from 'react-router-dom';
import { Supprimer as SupprimerButton } from 'components/Icon/Supprimer';
import { queryCache } from 'react-query';
import { Supprimer } from 'components/Button/Supprimer';
import { useDeleteDocument } from './useDeleteDocument';

export const DocumentDetails = ({
  document: {
    id,
    statutLibelle,
    fileName,
    fichierId,
    userNom,
    userPrenom,
    dateCreation,
    commentaireControle,
  },
  auth,
  lotId,
  livrableId,
}) => {
  const { t } = useTranslation();
  const { projectToken, phaseId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const url = `/projects/${projectToken}/phase/${phaseId}/document/${id}?lotId=${lotId}&livrableId=${livrableId}`;
  const linkProps = {
    as: Link,
    to: url,
    isExternal: true,
  };


  const [mutate] = useDeleteDocument({
    onError: () => {
      setIsLoading(false);
      toast({
        title: t('update.fail', { defaultValue: 'La modification a échouée' }),
        description: t('error.delete.document.message'),
        status: 'danger',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    },
    onSuccess: async () => {
      // Chargement terminé
      await queryCache.invalidateQueries([['phaseId', 'projectToken'], [phaseId, projectToken]]);
      toast({
        title: t('update.success', { defaultValue: 'Suppression réussie' }),
        description: t('success.delete.document.message'),
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
      setIsLoading(false);
      //reload page
      window.location.reload();
    },
  });



  // Fonction de suppression d'un document
  const handleDelete = async () => {
    setIsLoading(true);
    const datas = { projectToken, id };

    // Rajout du entrepriseId et id
    await mutate(datas);
  };


  return (
    <>


      <Flex
        w="100%"
        mb={2}
        {...linkProps}
      >
        <Card>
          <PseudoBox
            rounded={8}
            _hover={{
              border: '1px',
              boxShadow: 'xl',
            }}
          >
            <CardBody p={1}>

              <Flex
                flexDirection={{ base: 'column', lg: 'row' }}
                width={{ base: '100%' }}
              >
                <TitleDocument
                  w={{ base: '100%', lg: '70%' }}
                  urlTarget={`${APPENV.HOST_API}/projet/${projectToken}/file/${fichierId}`}
                  size="sm"
                  label={fileName}
                  dateImport={dateCreation}
                  nomImport={userNom}
                  prenomImport={userPrenom}
                />
                <Flex
                  p={2}
                  width={{ base: '100%', lg: '30%' }}
                >
                  {/* Statut document */}
                  <Flex
                    w={{ base: '50%' }}
                    justifyContent="center"
                  >
                    <Badge
                      w={{ base: '70%', lg: '80%' }}
                      my="auto"
                      variantColor={t(`${statutLibelle}.color`, { defaultValue: 'success' })}
                    >
                      <Text textAlign="center">{t(`${statutLibelle}.libelle`, { defaultValue: 'default' })}</Text>
                    </Badge>
                  </Flex>
                  {/* Bouton delete (quand nouveau) */}
                  <Flex
                    w={{ base: '50%' }}
                    justifyContent="center"
                  >
                    {
                      auth.canDelete && (
                        <SupprimerButton
                          onOpen={onOpen}
                          onClick={(e) => e.preventDefault()}
                          label={t('tooltips.document.supprimer')}
                        />
                      )
                    }
                  </Flex>
                </Flex>
              </Flex>
              {/* Commentaire */}
              {
                commentaireControle !== null && (
                  <Flex
                    p={2}
                    w={{ base: '100%' }}
                  >
                    <Text whiteSpace="pre-wrap">
                      {`Commentaire: ${commentaireControle}`}
                    </Text>
                  </Flex>
                )
              }
              {/*  */}
            </CardBody>
          </PseudoBox>
        </Card>
      </Flex>
      {/* Modal suppression  */}
      {isOpen && (
        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('modal.entete.document.suppression')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {statutLibelle !== 'statut.novueau' && (t('modal.body.document.suppression.confirmationhisto'))}
              {statutLibelle !== 'statut.valide' && (t('modal.body.document.suppression.confirmation'))}

            </ModalBody>

            <ModalFooter>
              <Button
                variantColor="gray"
                onClick={onClose}
              >
                {t('cancel')}
              </Button>
              <Supprimer
                isLoading={isLoading}
                handleClick={handleDelete}
              />
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

    </>
  );
};
DocumentDetails.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.number,
    statutLibelle: PropTypes.string,
    fileName: PropTypes.string,
    fichierId: PropTypes.string,
    userNom: PropTypes.string,
    userPrenom: PropTypes.string,
    dateCreation: PropTypes.string,
    commentaireControle: PropTypes.string,
  }).isRequired,
  lotId: PropTypes.number,
  livrableId: PropTypes.number,
  auth: PropTypes.shape({
    canDelete: PropTypes.bool,
  }).isRequired,
};

DocumentDetails.defaultProps = {
  lotId: null,
  livrableId: null,

};
