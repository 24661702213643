import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex, Link,
} from '@chakra-ui/core';

const propTypes = {
  onGoBack: PropTypes.func,
  children: PropTypes.node,
};

const defaultProps = {
  onGoBack: null,
  children: null,
};

export const PageFooter = ({
  onGoBack,
  children,
  ...rest
}) => (
  <Flex
    boxShadow="0px 0px 10px #c4c3c3;"
    position="fixed"
    bottom="0px"
    bg="white"
    p={{ base: 5 }}
    w={{ base: '85%', lg: '94%' }}
    alignItems="center"
    justifyContent="center"
    zIndex="1"
    {...rest}
  >
    <Link
      _hover={{
          color: 'brand.500',
          fontWeight: 'bold',
        }}
      href="/"
      isExternal
    >
      {`© Noxia Ingenierie ${(new Date()).getFullYear()}`}
    </Link>
  </Flex>
  );

PageFooter.propTypes = propTypes;
PageFooter.defaultProps = defaultProps;
