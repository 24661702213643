import React, { useState } from 'react';
import {
  Button,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Stack,
  Box,
  Tag,
  TagLabel,
  useToast,
  Checkbox,
} from '@chakra-ui/core';
import { Formiz, useForm, FormizStep } from '@formiz/core';
import { FieldInput, Icon } from 'components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { FaUserAlt } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { APPENV } from 'services/config';
import ListBox from 'react-listbox';
import 'react-listbox/dist/react-listbox.css';

export const NewProject = ({
  isOpen, onClose,
}) => {
  const toast = useToast();
  const projectForm = useForm();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const listesQuery = useQuery('listes', () => axios.get(`${APPENV.HOST_API}/listes/default`));
  const listesContactsQuery = useQuery('contacts', () => axios.get(`${APPENV.HOST_API}/contacts`));
  const options = listesContactsQuery?.data?.data;

  if (listesContactsQuery.isFetching || listesQuery.isFetching) {
    return (<></>);
  }

  const onChange = (selectedValues) => selectedValues;

  const handleSubmit = async (values) => {
    const avancement = values.avancement !== '' ? parseFloat(values.avancement) : null;
    const budget = values.budget !== '' ? parseFloat(values.budget) : null;

    const formData = {
      avancement,
      budget,
    };

    try {
      setLoading(true);
      await axios.put(`${APPENV.HOST_API}/projet/new`, formData);
    } catch (err) {
      toast({
        title: 'Lot not updated',
        description: 'Failed to update',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    }

    setLoading(false);
    onClose();
  };

  const optionsList = options.map((user) => ({
    label: `${user.prenom} ${user.nom} - ${user.entrepriseNom}`,
    value: `${user.id}`,
  }));

  return (
    <>
      {!listesContactsQuery.isFetching && !listesContactsQuery.isError && !listesQuery.isFetching && !listesQuery.isError
      && (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {t('dashboard.newProject', { defaultValue: 'New project' })}
            </ModalHeader>
            <ModalCloseButton />
            <Formiz onValidSubmit={handleSubmit} connect={projectForm}>
              <form noValidate onSubmit={projectForm.submitStep}>
                <ModalBody>
                  <Tabs>
                    <TabList>
                      {projectForm.steps.map((step) => (
                        <Tab key={step.name} _selected={{ color: 'white', bg: 'green.700' }} onClick={() => projectForm.goToStep(step.name)}>
                          { step.label }
                        </Tab>
                    ))}
                    </TabList>
                    <TabPanels p={4}>
                      <TabPanel>
                        <FormizStep name="step1" label="Infos géné">
                          <Stack p={6} m={2}>
                            <Box
                              rounded="md"
                              borderWidth="1px"
                              borderStyle="dashed"
                              p={4}
                              position="relative"
                            >
                              <Tag variantColor="green">
                                <Icon icon={FaUserAlt} mr={2} />
                                <TagLabel>MOE</TagLabel>
                              </Tag>
                              <FieldInput
                                name="nom_moe"
                                label={`${t('projet.libelle.nommoe', { defaultValue: 'Nom de projet pour le MOE' })}`}
                                required={`${t('errors.required', { defaultValue: 'Nom de projet pour le MOE requis' })}`}
                                placeholder={`${t('projet.libelle.nommoe', { defaultValue: 'Nom de projet pour le MOE' })}`}
                              />
                              <FieldInput
                                name="budget_moe"
                                label={`${t('projet.libelle.numerobudgetmoe', { defaultValue: 'Numéro de budget pour le MOE' })}`}
                                required={`${t('errors.required', { defaultValue: 'Numéro de budget pour le MOE requis' })}`}
                                placeholder={`${t('projet.libelle.numerobudgetmoe', { defaultValue: 'Numéro de budget pour le MOE' })}`}
                              />
                            </Box>

                            <Box
                              rounded="md"
                              borderWidth="1px"
                              borderStyle="dashed"
                              p={4}
                              position="relative"
                            >
                              <Tag variantColor="blue">
                                <Icon icon={FaUserAlt} mr={2} />
                                <TagLabel>MOA</TagLabel>
                              </Tag>
                              <FieldInput
                                name="nom_moa"
                                label={`${t('projet.libelle.nommoa', { defaultValue: 'Nom de projet pour le MOA' })}`}
                                required={`${t('errors.required', { defaultValue: 'Nom de projet pour le MOA requis' })}`}
                                placeholder={`${t('projet.libelle.nommoa', { defaultValue: 'Nom de projet pour le MOA' })}`}
                              />
                              <FieldInput
                                name="budget_moa"
                                label={`${t('projet.libelle.numerobudgetmoa', { defaultValue: 'Numéro de budget pour le MOA' })}`}
                                required={`${t('errors.required', { defaultValue: 'Numéro de budget pour le MOA requis' })}`}
                                placeholder={`${t('projet.libelle.numerobudgetmoa', { defaultValue: 'Numéro de budget pour le MOA' })}`}
                              />
                            </Box>
                          </Stack>
                        </FormizStep>
                      </TabPanel>
                      <TabPanel>
                        <FormizStep name="step2" label="Phases">
                          <Stack spacing={4}>
                            {listesQuery?.data?.data['phase.nom']?.map((phase) => (
                              <Checkbox key={phase.id} variantColor="green" defaultIsChecked>
                                {t(`${phase.valeur}`)}
                              </Checkbox>
                              ))}
                          </Stack>
                        </FormizStep>
                      </TabPanel>
                      <TabPanel>
                        <FormizStep name="step3" label="Intervenants">
                          <ListBox options={optionsList} onChange={onChange} selected />
                        </FormizStep>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </ModalBody>

                <ModalFooter>
                  <Button variantColor="brand" variant="outline" disabled={isLoading || (!projectForm.isValid && projectForm.isSubmitted)}>
                    {isLoading ? 'Loading...' : 'Submit'}
                  </Button>
                </ModalFooter>
              </form>
            </Formiz>
          </ModalContent>
        </Modal>
      )}

    </>
  );
};

NewProject.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

NewProject.defaultProps = {
  isOpen: false,
  onClose: () => { },
};
