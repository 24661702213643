/* eslint-disable max-len */
import React from 'react';
import { Box } from '@chakra-ui/core';

/** *
 * Le svg à enregistrer doit être contenu dans un viewport à la taille de l'image
 *
 * Optimizer le svg : https://jakearchibald.github.io/svgomg/
 * Svg to React : https://react-svgr.com/playground/?icon=true
 */

export const Logo = ({ ...props }) => (
  <Box w="100%" h="100%" color="brand.500" {...props}>
    <svg
      id="prefix__Calque_1"
      x={0}
      y={0}
      viewBox="0 0 328.951 246.311"
      xmlSpace="preserve"
      width="100%"
      height="100%"
      {...props}
    >
      <style type="text/css" id="style2" />
      <path
        id="prefix__path859"
        d="M0 0s.41 164.208.41 246.31H42.9V35.507l195.98.074c12.43 0 22.95 2.67 31.17 8.22 8.22 5.55 14.531 12.81 18.741 21.42 4.21 8.6 6.31 17.59 6.31 26.96 0 9.56-2.1 18.55-6.31 27.16-4.21 8.61-10.52 15.68-18.74 21.23-8.22 5.54-18.74 8.41-31.17 8.41H73.115l19.073 32.13h146.693c20.07 0 36.9-4.4 50.1-13.2 13.2-8.8 23.339-20.08 30.029-34.04 6.69-13.77 9.941-28.11 9.941-43.22s-3.251-29.64-9.941-43.6c-6.7-13.77-16.84-25.05-30.03-33.85C275.79 4.4 258.96 0 238.88 0z"
        fill="white"
      />
    </svg>
  </Box>
);
