/* eslint-disable no-unused-vars */
import React, {
  useState, createContext, useEffect, useContext,
} from 'react';
import { auth } from 'config/firebase';
import { useLocalStorage } from 'services/localStorage';
import axios from 'axios';
import { APPENV } from 'services/config';

export const AuthentificationContext = createContext();
export const useAuthentificationContext = () => useContext(AuthentificationContext);

export const AuthentificationProvider = (props) => {
  const [uidLocalStorage, setUidLocalStorage] = useLocalStorage('uid', null);
  const [initialisation, setInitialisation] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [mailLocalStorage, setEmailLocalStorage] = useLocalStorage('email', null);

  useEffect(() => {
    setInitialisation(true);
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        // console.log(userAuth.providerData[0].email);
        setUidLocalStorage(userAuth.uid);
        if (APPENV.ENV === 'local') {
          // eslint-disable-next-line no-console
        }
        // sans le multi provider by email de config dans firevase
        // setEmailLocalStorage(userAuth.email);
        setEmailLocalStorage(userAuth.providerData[0].email);
        setIsLogged(true);
        setInitialisation(false);
      } else {
        setUidLocalStorage(null);
        setEmailLocalStorage(null);
        setIsLogged(false);
        setInitialisation(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logOut = () => {
    setUidLocalStorage(null);
    setEmailLocalStorage(null);
    setIsLogged(false);
    setInitialisation(false);
    auth.signOut();
  };

  return (
    <AuthentificationContext.Provider
      value={{
        initialisation, isLogged, setIsLogged, logOut, mailLocalStorage,
      }}
      {...props}
    />
  );
};
