import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
  CSSReset, Spinner, ThemeProvider, Flex,
} from '@chakra-ui/core';
import 'config/axios';
import 'config/i18next';
import { ReactQueryDevtools } from 'react-query-devtools';
import { customTheme } from 'theme/customTheme';
import { AuthentificationProvider } from 'context/auth-context';
import { ReactQueryConfigProvider } from 'react-query';
import { App } from './App';

const ScreenLoader = () => (
  <Flex w="100vw" h="100vh">
    <Spinner margin="auto" />
  </Flex>
);

const queryConfig = { queries: { refetchOnWindowFocus: false } };

ReactDOM.render(
  <React.StrictMode>
    <AuthentificationProvider>
      <ThemeProvider theme={customTheme}>
        <CSSReset />
        <ReactQueryDevtools initialIsOpen={false} />
        <ReactQueryConfigProvider config={queryConfig}>
          <Suspense fallback={<ScreenLoader />}>
            <App />
          </Suspense>
        </ReactQueryConfigProvider>
      </ThemeProvider>
    </AuthentificationProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
