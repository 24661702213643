import React from 'react';
import { Button } from '@chakra-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const Supprimer = ({ isLoading, handleClick, ...props }) => {
  const { t } = useTranslation();

  return (
    <Button
      variantColor="brand"
      ml="auto"
      type="submit"
      isLoading={isLoading}
      onClick={handleClick}
      {...props}
    >
      {t('delete', { defaultValue: 'Delete' })}
    </Button>
  );
};

Supprimer.propTypes = {
  isLoading: PropTypes.bool,
  handleClick: PropTypes.func,
};

Supprimer.defaultProps = {
  isLoading: false,
  handleClick: null,
};
