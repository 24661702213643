import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { PageContent, PageHeader, PageFooter } from 'layout';
import {
  Flex,
  Stack,
  Text,
  Button,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import {
  FaCloudDownloadAlt,
} from 'react-icons/fa';
import { APPENV } from 'services/config';
import { handleDownload } from 'services/files';
import { useTranslation } from 'react-i18next';
import { ProjectContact } from './_partials/ProjectContact';
import { PhaseDetails } from './_partials/PhaseDetails';
import { ProjectPhasesTimeline } from './_partials/ProjectPhasesTimeline';

export const ProjectDetails = () => {
  const { projectToken } = useParams();
  let { phaseId } = useParams();
  phaseId = parseInt(phaseId, 10);
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const handleBack = () => {
    history.push({
      pathname: '/projects',
    });
  };

  const {
    data: project, isLoading, isError,
  } = useQuery(['projectToken', projectToken], () => axios.get(`${APPENV.HOST_API}/projet/${projectToken}`));

  const download = () => {
    handleDownload(`${APPENV.HOST_API}/projet/${projectToken}/phase/${phaseId}/files`, 'zip', setIsLoadingDownload);
  };

  if (isError) {
    return (
      <>
        <PageContent>
          <Stack flex={1}>
            <Alert
              status="error"
              variant="subtle"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon size="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                {t('error.load.projet.libelle', { defaultValue: 'Error while downloading project' })}
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                {t('error.load.projet.message', { defaultValue: 'Error while downloading project' })}
              </AlertDescription>
            </Alert>
          </Stack>
        </PageContent>
        <PageFooter />
      </>
    );
  }

  return (
    <>
      {isLoading && (
        <>
          <PageContent>
            <Stack flex={1} align="center" justify="center">
              <Spinner size="xl" color="brand.500" />
            </Stack>
          </PageContent>
          <PageFooter />
        </>
      )}

      <PageHeader
        w={{ base: '85%', lg: '94%' }}
        p={2}
        px={2}
        onGoBack={() => handleBack()}
        border="1px"
        borderColor="gray.50"
      >
        {!isLoading && (
          <>

            <Flex
              w="100%"
              direction={{ base: 'row' }}
              alignItems={{ md: 'center' }}
              justifyContent="space-between"
            >
              <Flex
                flexDirection="column"
              >
                <Text
                  as="b"
                  fontSize="xs"
                  p={1}
                >
                  {
                    `${project?.data?.projet?.data?.nom}
              ${project?.data?.projet?.data?.numeroBudget === null || project?.data?.projet?.data?.numeroBudget === ''
                      ? '' : `- ${project?.data?.projet?.data?.numeroBudget}`}`
                  }
                </Text>
                <Text
                  p={1}
                  fontSize="xs"
                >
                  {`${project?.data?.projet?.data?.ville ?? ''} ${project?.data?.projet?.data?.codePostal ?? ''}`}
                </Text>
              </Flex>
              {/* Bouton télécharger */}
              <Flex
                alignItems="center"
              >
                <Button
                  w={{ base: '100%' }}
                  leftIcon={FaCloudDownloadAlt}
                  onClick={download}
                  variantColor="brand"
                  isLoading={isLoadingDownload}
                >
                  {t('download', { defaultValue: 'Download' })}
                </Button>
              </Flex>
            </Flex>
          </>
        )}
      </PageHeader>
      <PageContent
        mb="5rem"
      >
        <Flex
          p={3}
          px={5}
          flexDirection={{ base: 'column', lg: 'row' }}
        >
          {/* Timeline + contact */}
          <Flex
            flexDirection={{ base: 'column' }}
            w={{ base: '100%', lg: '20%', xl: '20%' }}
          >
            <Flex
              w="100%"
            >
              {project?.data?.projet?.data?.contactProjet?.email === null && project?.data?.projet?.data?.contactProjet?.entrepriseId === null && (
                <ProjectContact isLoading={isLoading} contact={null} auth={project?.data.projet.auth} />
              )}

              {project?.data?.projet?.data?.contactProjet?.email !== null && project?.data?.projet?.data?.contactProjet?.entrepriseId !== null && (
                <ProjectContact isLoading={isLoading} contact={project?.data?.projet?.data?.contactProjet} auth={project?.data.projet.auth} />
              )}
            </Flex>
            <Flex
              w="100%"
            >
              {!isLoading && (
                <ProjectPhasesTimeline isLoading={isLoading} phases={project?.data?.projet?.phases} mt="5" />)}
            </Flex>
          </Flex>
          <Flex
            w={{ base: '100%', lg: '80%', xl: '80%' }}
          >
            {!isLoading && (
              <PhaseDetails projectToken={projectToken} />
            )}
          </Flex>

        </Flex>
      </PageContent>
      <PageFooter />
    </>
  );
};
