import React, { useState } from 'react';
import {
 Box, TagLabel, Tag, useToast, Stack, Grid,
} from '@chakra-ui/core';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { APPENV } from 'services/config';
import { useTranslation } from 'react-i18next';
import { FaUserAlt } from 'react-icons/fa';
import { Formiz, useForm } from '@formiz/core';
import {
  FieldInput,
  Icon,
  Card,
  CardHeader,
  CardTitle,
  FieldSelect,
} from 'components';
import PropTypes from 'prop-types';
import { Enregistrer } from 'components/Button/Enregistrer';
import { queryCache } from 'react-query';

export const DatasProjetConfiguration = ({ projetData, projetIntervenant }) => {
  const projectForm = useForm();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const { projectToken } = useParams();
  const toast = useToast();
  const intervenantProjet = projetIntervenant.map((intervenant) => ({
    label: `${intervenant.data.contactPrenom} ${intervenant.data.contactNom} (${intervenant.data.contactEmail})`,
    value: `${intervenant.data.id}`,
  }));
  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      await axios.put(`${APPENV.HOST_API}/projet/${projectToken}`, values);
      toast({
        title: t('success.update.projet.libelle', {
          defaultValue: 'Projet mise à jour',
        }),
        description: t('success.update.projet.message', {
          defaultValue: 'Projet mise à jour',
        }),
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });

      await queryCache.invalidateQueries('configuration', projectToken);
    } catch (err) {
      toast({
        title: t('error.delete.projet.libelle', {
          defaultValue: 'Erreur lors de la mise à jour',
        }),
        description: t('error.delete.projet.message', {
          defaultValue: 'Erreur lors de la mise à jour',
        }),
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    }

    setLoading(false);
  };

  return (
    <Stack pl={4} pr={4}>
      <Formiz onValidSubmit={handleSubmit} connect={projectForm}>
        <form noValidate onSubmit={projectForm.submit}>
          <Card
            w={{
              base: '100%',
              md: '80%',
            }}
            textAlign="center"
            m="auto"
          >
            <CardHeader>
              <CardTitle>Modification des informations du projet</CardTitle>
            </CardHeader>
            <Grid
              templateColumns={{
                base: '1fr',
                md: 'repeat(2, 1fr) auto',
              }}
              p={{
                base: 0,
                sm: 0,
                md: 0,
                lg: 6,
              }}
            >
              <Box
                rounded="md"
                borderWidth="1px"
                borderStyle="solid"
                p={4}
                m={2}
                position="relative"
              >
                <Stack flexDirection={{ base: 'column', lg: 'row' }}>
                  <FieldInput
                    w={{
                      base: '14rem',
                      sm: '100%',
                      md: '100%',
                      lg: '14rem',
                    }}
                    name="code_projet"
                    label={`${t('projet.libelle.codeprojet', {
                      defaultValue: 'Code projet',
                    })}`}
                    required={`${t('errors.required', {
                      defaultValue: 'Code projetrequis',
                    })}`}
                    placeholder={`${t('projet.libelle.codeprojet', {
                      defaultValue: 'Code projet',
                    })}`}
                    defaultValue={projetData?.codeProjet}
                  />

                  <FieldInput
                    ml={{
                      base: '',
                      sm: '',
                      md: '',
                      lg: 'auto',
                    }}
                    textAlign="right"
                    w={{
                      base: '14rem',
                      sm: '100%',
                      md: '100%',
                      lg: '14rem',
                    }}
                    name="budget"
                    label={`${t('projet.libelle.budget')}`}
                    required={`${t('errors.required')}`}
                    placeholder={`${t('projet.libelle.budget')}`}
                    defaultValue={projetData?.budget}
                  />
                </Stack>
                <FieldSelect
                  w={{
                    base: '14rem',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',
                  }}
                  label={t('projet.placeholder.contactProjet')}
                  name="contactId"
                  options={intervenantProjet}
                  placeholder={`${t('projet.placeholder.contactProjet')}`}
                  defaultValue={projetData?.contactProjet?.intervenantId}
                />
              </Box>

              <Box
                rounded="md"
                borderWidth="1px"
                borderStyle="solid"
                p={4}
                m={2}
                position="relative"
              >
                <FieldInput
                  w={{
                    base: '14rem',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',
                  }}
                  name="adresse1"
                  label={`${t('projet.libelle.adresse1')}`}
                  placeholder={`${t('projet.libelle.adresse1')}`}
                  defaultValue={projetData?.adresse1}
                  mb={2}
                />
                {/* Adresse 2 */}
                <FieldInput
                  w={{
                    base: '14rem',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',
                  }}
                  name="adresse2"
                  label={`${t('projet.libelle.adresse2')}`}
                  placeholder={`${t('projet.libelle.adresse2')}`}
                  defaultValue={projetData?.adresse2}
                  mb={2}
                />
                {/* Adresse 3 */}
                <FieldInput
                  w={{
                    base: '14rem',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',
                  }}
                  name="adresse3"
                  label={`${t('projet.libelle.adresse3')}`}
                  placeholder={`${t('projet.libelle.adresse3')}`}
                  defaultValue={projetData?.adresse3}
                  mb={2}
                />
                <Stack flexDirection={{ base: 'column', lg: 'row' }}>
                  <FieldInput
                    w={{
                      base: '14rem',
                      sm: '100%',
                      md: '100%',
                      lg: '14rem',
                    }}
                    name="ville"
                    label={`${t('projet.libelle.ville')}`}
                    placeholder={`${t('projet.libelle.ville')}`}
                    defaultValue={projetData?.ville}
                    mb={2}
                  />
                  {/* Code postal */}
                  <FieldInput
                    ml={{
                      base: '',
                      sm: '',
                      md: '',
                      lg: 'auto',
                    }}
                    w={{
                      base: '14rem',
                      sm: '100%',
                      md: '100%',
                      lg: '14rem',
                    }}
                    name="codePostal"
                    label={`${t('projet.libelle.codePostal')}`}
                    placeholder={`${t('projet.libelle.codePostal')}`}
                    defaultValue={projetData?.codePostal}
                  />
                </Stack>
              </Box>
            </Grid>

            <Grid
              templateColumns={{
                base: '1fr',
                md: 'repeat(2, 1fr) auto',
              }}
              alignItems="center"
              p={{
                base: 0,
                sm: 0,
                md: 0,
                lg: 6,
              }}
            >
              <Box
                rounded="md"
                borderWidth="1px"
                borderStyle="solid"
                p={4}
                m={2}
                position="relative"
              >
                <Tag variantColor="green">
                  <Icon icon={FaUserAlt} mr={2} />
                  <TagLabel>MOE</TagLabel>
                </Tag>
                <FieldInput
                  w={{
                    base: '14rem',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',
                  }}
                  d="flex"
                  flexDirection="column"
                  justifyContent="start"
                  name="nom_moe"
                  label={`${t('projet.libelle.nommoe', {
                    defaultValue: 'Nom de projet pour le MOE',
                  })}`}
                  required={`${t('errors.required', {
                    defaultValue: 'Nom de projet pour le MOE requis',
                  })}`}
                  placeholder={`${t('projet.libelle.nommoe', {
                    defaultValue: 'Nom de projet pour le MOE',
                  })}`}
                  defaultValue={projetData?.nomProjetMoe}
                />
                <FieldInput
                  w={{
                    base: '14rem',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',
                  }}
                  d="flex"
                  flexDirection="column"
                  justifyContent="start"
                  name="budget_moe"
                  label={`${t('projet.libelle.numerobudgetmoe', {
                    defaultValue: 'Numéro de budget pour le MOE',
                  })}`}
                  placeholder={`${t('projet.libelle.numerobudgetmoe', {
                    defaultValue: 'Numéro de budget pour le MOE',
                  })}`}
                  defaultValue={projetData?.numeroBudgetMoe}
                />
              </Box>

              <Box
                rounded="md"
                borderWidth="1px"
                borderStyle="solid"
                p={4}
                m={2}
                position="relative"
              >
                <Tag variantColor="blue">
                  <Icon icon={FaUserAlt} mr={2} />
                  <TagLabel>MOA</TagLabel>
                </Tag>
                <FieldInput
                  w={{
                    base: '14rem',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',
                  }}
                  d="flex"
                  flexDirection="column"
                  justifyContent="start"
                  name="nom_moa"
                  label={`${t('projet.libelle.nommoa', {
                    defaultValue: 'Nom de projet pour le MOA',
                  })}`}
                  required={`${t('errors.required', {
                    defaultValue: 'Nom de projet pour le MOA requis',
                  })}`}
                  placeholder={`${t('projet.libelle.nommoa', {
                    defaultValue: 'Nom de projet pour le MOA',
                  })}`}
                  defaultValue={projetData?.nomProjetMoa}
                />
                <FieldInput
                  w={{
                    base: '14rem',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',
                  }}
                  d="flex"
                  flexDirection="column"
                  justifyContent="start"
                  name="budget_moa"
                  label={`${t('projet.libelle.numerobudgetmoa', {
                    defaultValue: 'Numéro de budget pour le MOA',
                  })}`}
                  placeholder={`${t('projet.libelle.numerobudgetmoa', {
                    defaultValue: 'Numéro de budget pour le MOA',
                  })}`}
                  defaultValue={projetData?.numeroBudgetMoa}
                />
              </Box>
            </Grid>
            <Stack p={4}>
              <Enregistrer
                isLoading={
                  isLoading || (!projectForm.isValid && projectForm.isSubmitted)
                }
              />
            </Stack>
          </Card>
        </form>
      </Formiz>
    </Stack>
  );
};

DatasProjetConfiguration.propTypes = {
  projetData: PropTypes.shape({
    contactProjet: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    nomProjetMoe: PropTypes.string,
    nomProjetMoa: PropTypes.string,
    numeroBudgetMoa: PropTypes.string,
    numeroBudgetMoe: PropTypes.string,
    codeProjet: PropTypes.string,
    budget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    adresse1: PropTypes.string,
    adresse2: PropTypes.string,
    adresse3: PropTypes.string,
    ville: PropTypes.string,
    codePostal: PropTypes.string,
  }).isRequired,
  projetIntervenant: PropTypes.arrayOf(PropTypes.object).isRequired,
};
