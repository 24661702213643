/* eslint-disable max-len */
import React from 'react';
import { Box } from '@chakra-ui/core';

/** *
 * Le svg à enregistrer doit être contenu dans un viewport à la taille de l'image
 *
 * Optimizer le svg : https://jakearchibald.github.io/svgomg/
 * Svg to React : https://react-svgr.com/playground/?icon=true
 */

export const LogoWyzen = ({ ...props }) => (
  <Box w="100%" h="100%" color="brand.500" {...props}>
    <img src='/logo.png'></img>
    {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.463 121.415">
      <g fill="currentColor">
        <path d="M65.182 75.159v3.265h51.814c-7.822 23.104-29.926 38.714-54.848 38.734-31.931 0-57.815-25.273-57.816-56.449 0-31.177 25.885-56.451 57.816-56.451h.001c19.958.014 38.498 10.077 49.045 26.621h3.589C103.898 12.548 83.849 1.255 62.148 1.231c-33.643 0-60.917 26.628-60.917 59.476v.001c.001 32.848 27.274 59.476 60.917 59.476 26.627-.03 50.149-16.942 58.081-41.76h.002v-3.265H65.182z" />
        <path d="M56.176 66.804l-3.958-26.373h-5.422l-4.127 25.935-3.405-25.935h-4.666l5.391 37.756h4.943l4.483-28.227 4.496 28.227h4.836l5.484-37.756h-4.59zM70.46 73.689v-6.776l5.035-9.376h-3.186l-3.157 6.39-3.223-6.39h-3.241l5.016 9.354v6.798zM78.284 73.689h11.127v-2.721H81.62l7.501-10.897v-2.534H79.098v2.732h6.379l-7.193 10.479zM103.914 60.269v-2.732H93.759v16.152h10.417v-2.721h-7.652v-4.396h6.876V63.85h-6.876v-3.581zM109.15 57.537v16.152h2.57V63.156l5.52 10.533h2.775V57.537h-2.569v10.787l-5.605-10.787z" />
      </g>
</svg>*/}
  </Box>
);
