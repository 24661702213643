import React from 'react';
import { Flex, Text, Badge } from '@chakra-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const CardDocumentHeader = ({ children, statutLibelle, hasBadge = false }) => {
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      w="100%"
      borderBottom="1px"
      borderColor="#70707038"
      pb={3}
      mb={3}
    >
      <Text
        fontSize="lg"
        as="b"
        color="#707070"
      >
        {children}
      </Text>
      {
        hasBadge && (
          <Badge
            ml={{ base: 0, md: 3 }}
            p="0.2rem"
            rounded="0.5rem"
            w={{ base: '6rem' }}
            my="auto"
            variantColor={t(`${statutLibelle}.color`, { defaultValue: 'success' })}
          >
            <Text textAlign="center">{t(`${statutLibelle}.libelle`, { defaultValue: 'default' })}</Text>
          </Badge>
        )
      }

    </Flex>
  );
};

CardDocumentHeader.propTypes = {
  children: PropTypes.node.isRequired,
  statutLibelle: PropTypes.string,
  hasBadge: PropTypes.bool,
};

CardDocumentHeader.defaultProps = {
  hasBadge: false,
  statutLibelle: null,
};
