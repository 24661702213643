import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { Archives } from 'pages/Archives';
import { Dashboard } from 'pages/Dashboard';
import { Notifications } from 'pages/Notifications';
import { Profile } from 'pages/Profile';
import { NoMatch } from 'pages/NoMatch';
import { Login } from 'pages/Login';
import { ProjectDetails } from 'pages/ProjectDetails';
import { Document } from 'pages/Document';
import { ProjectConfiguration } from 'pages/Configuration';
import { Signout } from 'pages/Signout';
import { Authenticated } from 'Authenticated';
import { Register } from 'pages/Register';

export const Routes = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // if (typeof window.scrollTo === 'function') {
    //   window.scrollTo(0, 0);
    // }
  }, [pathname]);

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/projects" />
      </Route>
      {/* //TODO mettre Authenticated */}
      <Authenticated exact path="/projects" component={Dashboard} />
      <Authenticated exact path="/projects/:projectToken/phase/:phaseId" component={ProjectDetails} />
      <Authenticated exact path="/projects/:projectToken/phase/:phaseId/document/:documentId" component={Document} />
      <Authenticated exact path="/projects/:projectToken/edit" component={ProjectDetails} />
      <Authenticated exact path="/projects/:projectToken/configuration" component={ProjectConfiguration} />
      <Authenticated exact path="/archives" component={Archives} />
      <Authenticated exact path="/notifications" component={Notifications} />
      <Authenticated exact path="/profile" component={Profile} />
      {/* Public routes  */}
      <Route exact path="/register" component={Register} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/signout" component={Signout} />

      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
};
