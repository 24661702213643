import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/core';

export const NotificationBadge = ({
  value, limit, variantColor, backgroundColor, color, ...props
}) => (
  <Box
    px="0.5em"
    d="inline-block"
    bg={`${variantColor}.50`}
    color={color}
    fontWeight="bold"
    borderRadius="full"
    backgroundColor={backgroundColor}
    {...props}
  >
    {value > limit ? `+${limit - 1}` : value}
  </Box>
  );

NotificationBadge.propTypes = {
  value: PropTypes.number,
  limit: PropTypes.number,
  variantColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,

};

NotificationBadge.defaultProps = {
  value: 0,
  limit: 100,
  variantColor: 'gray',
  backgroundColor: 'gray',
  color: 'white',
};
