import React from 'react';
import { Box } from '@chakra-ui/core';

export const TimelineContent = (props) => (
  <Box
    position="relative"
    pl="4"
    {...props}
  />
);
