import React from 'react';
import PropTypes from 'prop-types';
import { Spinner as SpinnerChakra } from '@chakra-ui/core';

export const Spinner = ({ size, ...props }) => {
  const getStyles = () => {
    switch (size) {
      case 'xs': return { thickness: '2px' };
      case 'sm': return { thickness: '2px' };
      case 'md': return { thickness: '3px' };
      case 'lg': case 'xl': default: return { thickness: '4px' };
    }
  };

  return (
    <SpinnerChakra
      speed="0.6s"
      size={size}
      color="brand.500"
      emptyColor="gray.200"
      {...getStyles()}
      {...props}
    />
  );
};

Spinner.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
};

Spinner.defaultProps = {
  size: 'xl',
};
