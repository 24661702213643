import React from 'react';
import { Text } from '@chakra-ui/core';
import { PageContent } from 'layout';
import { useTranslation } from 'react-i18next';

export const NoMatch = () => {
  const { t } = useTranslation();
  return (
    <PageContent noHeader>
      <Text fontSize="2xl" fontWeight="600" color="brand.800">{t('notFound.title', { defaultValue: 'Page 404' })}</Text>
    </PageContent>
  );
};
