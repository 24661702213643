import React, { useState } from 'react';
import {
  Stack,
  Heading,
  Spinner,
  AlertDescription,
  AlertTitle,
  Alert,
  useDisclosure,
  Text,
  Flex,
  AlertIcon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Icon,
  PseudoBox,
} from '@chakra-ui/core';

import axios from 'axios';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { APPENV } from 'services/config';
import { NotificationBadge } from 'components';
import { FaEllipsisV } from 'react-icons/fa';
import { Redirect, useParams } from 'react-router-dom';
import { FormatDate } from 'services/utils';
import { LotDetails } from '../LotDetails';
import { ItemDetails } from '../ItemDetails';
import { EditPhase } from './EditPhase';

export const PhaseDetails = ({ projectToken }) => {
  let { phaseId } = useParams();
  phaseId = parseInt(phaseId, 10);
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [configurationLot, setConfigurationLot] = useState(false);
  const handleClick = (e) => {
    e.stopPropagation();
    onOpen();
  };

  const {
    data, isLoading, isError,
  } = useQuery([['phaseId', 'projectToken'], [phaseId, projectToken]],
  () => axios.get(`${APPENV.HOST_API}/projet/${projectToken}/phase/${phaseId}`), {
    refetchOnWindowFocus: true,
  });

  if (isLoading) {
    return (
      <>
        <Stack flex={1} align="center" justify="center">
          <Spinner size="xl" color="brand.500" />
        </Stack>
      </>
    );
  }

  const urlCreateLot = `/projects/${projectToken}/configuration?section=lots`;
  const handleRedirectConfig = () => setConfigurationLot(true);
  const phase = data?.data?.phase?.data;
  const auth = data?.data?.phase?.auth;
  const roleUser = data?.data?.roleUser;

  if (configurationLot) {
    return <Redirect to={urlCreateLot} />;
  }

  if (isError || typeof (phase) === 'undefined') {
    return (
      <>
        <Stack flex={1}>
          <Alert
            status="error"
            variant="subtle"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon size="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              {t('error.load.phase.libelle', { defaultValue: 'Error while downloading project phase' })}
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              {t('error.load.phase.message', { defaultValue: 'Error while downloading project phase' })}
            </AlertDescription>
          </Alert>
        </Stack>
      </>
    );
  }

  const actionCountDocumentsDeposesByPhaseId = data?.data?.phase.actionCountDocumentsDeposesByPhaseId;
  const actionCountDocumentsRefusesByPhaseId = data?.data?.phase.actionCountDocumentsRefusesByPhaseId;
  const notifications = data?.data?.phase.notifications;

  const lots = phase?.lots;
  const detail = {
    budget: phase?.totalBudgetPhase,
    variation: phase?.variation,
    variationPourcentage: phase?.variationPourcentage,
    variationProjet: phase?.variationBudgetInitiale,
    variationProjetPourcentage: phase?.variationBudgetInitialePourcentage,
    avancement: phase?.avancement,
    budgetCourant: phase?.budgetCourant,
    libelle: phase?.libelle,
    libelleCustom: phase?.libelleCustom,
    dateDebut: phase?.dateDebut,
    dateFin: phase?.dateFin,
    moyenneDocumentsValides: phase?.moyenneDocumentsValides,
    statutLibelle: phase?.statutLibelle,
  };

  return (
    <Flex
      flexDirection="column"
      ml={{ lg: 2 }}
      w="100%"
    >
      {phase?.commentaireMoa !== null && (
        <Stack>
          <Alert
            status="success"
            justifyContent="center"
            flexDirection="column"
          >
            <Stack mr="auto" isInline>
              <AlertIcon />
              <AlertTitle fontSize="lg">
                {t('phase.libelle.commentairemoa', { defaultValue: 'Phase comment' })}
              </AlertTitle>
            </Stack>
            <AlertDescription maxWidth="sm">
              {phase?.commentaireMoa}
            </AlertDescription>
          </Alert>
        </Stack>
      )}
      <Heading
        w="100%"
        fontSize={{ base: 'md', lg: '1xl' }}
      >
        <Flex
          w="100%"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          p={2}
        >
          <Flex
            alignItems="center"
          >
            {
              (detail.statutLibelle === 'statut.cloturee') && (
                <Icon
                  mr={2}
                  name="lock"
                />
              )
            }
            {
              actionCountDocumentsDeposesByPhaseId !== 0 && actionCountDocumentsDeposesByPhaseId !== undefined
              && detail.statutLibelle !== 'statut.cloturee' && (
                <NotificationBadge
                  limit={10}
                  mr="1rem"
                  d="flex"
                  value={actionCountDocumentsDeposesByPhaseId}
                  fontSize="0.8rem"
                  backgroundColor="#1378fa"
                  color="white"
                  justifyContent="center"
                  alignItems="center"
                  w="1rem"
                  h="1rem"
                  p="0.8rem"
                />
              )
            }
            {
              actionCountDocumentsRefusesByPhaseId !== 0 && actionCountDocumentsRefusesByPhaseId !== undefined
              && detail.statutLibelle !== 'statut.cloturee' && (
                <NotificationBadge
                  limit={10}
                  mr="1rem"
                  d="flex"
                  value={actionCountDocumentsRefusesByPhaseId}
                  fontSize="0.8rem"
                  backgroundColor="#1378fa"
                  color="white"
                  justifyContent="center"
                  alignItems="center"
                  w="1rem"
                  h="1rem"
                  p="0.8rem"
                />
              )
            }
            <Text
              d="flex"
              flexDirection="row"
              fontSize={{ base: 'md', lg: '2xl' }}
              alignItems="center"
            >
              {phase?.phaseLibelleCustom !== null ? phase?.phaseLibelleCustom : t(phase?.phaseLibelle, { defaultValue: phase?.phaseLibelle })}
            </Text>
          </Flex>

          {/* Menu édition de la phase en cours  */}
          {(auth.canUpdate || auth.canUpdateCommentaireMoa || auth.canCloturer) && (
            <Menu>
              <MenuButton
                as={IconButton}
                icon={FaEllipsisV}
                variantColor="brand"
                variant="ghost"
                onClick={(e) => e.stopPropagation()}
                ml="0.5rem"
                _hover={{
                  backgroundColor: 'gray.50',
                }}
              />
              <MenuList placement="bottom-end">

                {(auth.canUpdate || auth.canUpdateCommentaireMoa) && (
                <MenuItem onClick={handleRedirectConfig}>
                  <Text
                    fontSize="lg"
                  >
                    {' '}
                    {t('phase.menu.configurer')}
                  </Text>
                </MenuItem>
                    )}
                { auth.canCloturer && (
                <MenuItem onClick={handleClick}>

                  {
                    (notifications !== undefined)
                    && (notifications.length !== 0) && (
                      <NotificationBadge
                        limit={10}
                        mr="1rem"
                        d="flex"
                        value={notifications.length}
                        fontSize="0.8rem"
                        backgroundColor="#1378fa"
                        color="white"
                        justifyContent="center"
                        alignItems="center"
                        w="1rem"
                        h="1rem"
                        p="0.8rem"
                      />
                    )
                  }
                  <Text
                    fontSize="lg"
                    color="red.500"
                  >
                    {' '}
                    {t('phase.menu.cloturer')}
                  </Text>
                </MenuItem>

                    )}

              </MenuList>
            </Menu>

          )}
        </Flex>
      </Heading>
      <EditPhase id={phaseId} auth={auth} isOpen={isOpen} onClose={onClose} />
      <Stack>
        <ItemDetails
          livrables={{}}
          isLot={false}
          withLabel={false}
          withLegends
          detail={detail}
          actionCountDocumentsDeposesByLotId={actionCountDocumentsDeposesByPhaseId}
          actionCountDocumentsRefusesByLotId={actionCountDocumentsRefusesByPhaseId}
          auth={auth}
          onOpen={onOpen}
          phaseStatut={detail?.statutLibelle}
        />
      </Stack>
      {/* // S'il y a des lots */}
      {lots.length !== 0 && Object.values(lots)?.map((lot) => (
        <LotDetails
          key={lot.data.id}
          lot={lot.data}
          auth={lot.auth}
          livrables={lot.livrables}
          dateDebut={FormatDate(phase.dateDebut, t('core.lang.shortcut'))}
          dateFin={phase.dateFin}
          actionCountDocumentsDeposesByLotId={lot.actionCountDocumentsDeposesByLotId}
          actionCountDocumentsRefusesByLotId={lot.actionCountDocumentsRefusesByLotId}
          phaseStatut={detail?.statutLibelle}
          havePhasePrecedente={phase?.havePhasePrecedente}
        />
      ))}

      {/* S'il n'y a pas de lot */}
      {
        lots.length === 0 && (

          <Alert
            p={10}
            mt={5}
            rounded={5}
            status="info"
            d="flex"
            justifyContent="center"
          >
            <Flex
              flexDirection="column"
            >
              <Flex>
                <AlertIcon />
                <Text
                  textAlign="center"
                >
                  {t('lot.message.vide')}
                </Text>
              </Flex>
              {
                roleUser === 'intervenant.profil.moe' && (
                  <PseudoBox
                    onClick={handleRedirectConfig}
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      mt={5}
                      textAlign="center"
                    >
                      <Icon color="green.500" name="add" mr={2} />
                      <Text>
                        {t('lot.message.creer')}
                      </Text>
                    </Flex>
                  </PseudoBox>
                )
              }
            </Flex>
          </Alert>
        )
      }
    </Flex>
  );
};
PhaseDetails.propTypes = {
  projectToken: PropTypes.string.isRequired,
};
