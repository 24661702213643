import React from 'react';
import {
  Text, Box, Stack, Grid,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { PageHeader, PageContent } from 'layout';
import { FormDemo } from './FormDemo';

const Demo = (props) => (
  <Box
    minH="4"
    minW="4"
    bg="gray.100"
    borderWidth="1px"
    borderColor="gray.300"
    {...props}
  />
);

export const Notifications = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader>
        <Text
          fontSize="lg"
          color="brand.800"
          fontWeight="500"
        >
          {t('notifications.title', { defaultValue: 'Notifications' })}
        </Text>
      </PageHeader>
      <PageContent>
        <Grid flex="1" templateColumns="minmax(14rem, 20rem) minmax(50ch, 1fr)" templateRows="1fr" gap="8">
          <Stack bg="brand.50" spacing="4">
            <Demo h="10rem" />
            <Demo />
          </Stack>
          <Stack bg="brand.50" spacing="4">
            <Demo />
            <FormDemo />
          </Stack>
        </Grid>
      </PageContent>

    </>
  );
};
