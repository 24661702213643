import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { PseudoBox, useTheme } from '@chakra-ui/core';
import { TimelineContext } from './Timeline';

export const TimelineItem = ({ isLast, ...props }) => {
  const theme = useTheme();
  const { progressSize } = useContext(TimelineContext);

  const circularProgressSize = theme?.sizes?.circularProgress?.[progressSize] || progressSize;

  return (
    <PseudoBox
      position="relative"
      pl={circularProgressSize}
      _before={!isLast ? {
        content: '""',
        position: 'absolute',
        backgroundColor: 'gray.200',
        display: 'block',
        width: '1px',
        top: circularProgressSize,
        left: `calc(${circularProgressSize} / 2)`,
        bottom: 0,
      } : {}}
      {...props}
    />
  );
};

TimelineItem.propTypes = {
  isLast: PropTypes.bool,
  progressSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl']),
};

TimelineItem.defaultProps = {
  isLast: false,
  progressSize: '2xl',
};
