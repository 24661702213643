import { theme } from '@chakra-ui/core';
// import 'typeface-roboto';

const fonts = {
  body: 'Roboto, sans-serif',
  heading: 'Roboto, sans-serif',
  mono: 'Menlo, monospace',
};

const fontSizes = {
  ...theme.fontSizes,
  '2xs': '10px',
};

const colors = {
  ...theme.colors,
  // Go to https://smart-swatch.netlify.com/ to easily generate a new color palette.

  lightWhite: '#F7FAFB',

  blue:
  {
    50: '#e0f4ff',
    100: '#b8dcfa',
    200: '#8ec4f1',
    300: '#63ace8',
    400: '#3994e0',
    500: '#1f7bc6',
    600: '#135f9b',
    700: '#084470',
    800: '#002946',
    900: '#000f1d',
  },

  gray: {
    50: '#E6EBEB',
    100: '#D5DDDD',
    150: '#c8cdcd',
    200: '#BCC5C5',
    300: '#A1AEAE',
    400: '#879796',
    500: '#6E7E7E',
    600: '#556262',
    700: '#3C4646',
    800: '#222A2A',
    900: '#031010',
  },

  success: {
    50: '#e6fae8',
    100: '#c6e9c8',
    200: '#a4d9a6',
    300: '#82c984',
    400: '#5fb963',
    500: '#46a049',
    600: '#357c38',
    700: '#255927',
    800: '#133615',
    900: '#001400',
  },

  warning: {
    50: '#fff2de',
    100: '#fbd7b6',
    200: '#f3b88b',
    300: '#ed975f',
    400: '#e67433',
    500: '#cc6619',
    600: '#a05812',
    700: '#73460c',
    800: '#462f03',
    900: '#1e1100',
  },

  danger: {
    50: '#ffe3e8',
    100: '#feb6be',
    200: '#ffd07d',
    300: '#f25969',
    400: '#ee2b3e',
    500: '#d41125',
    600: '#a60b1c',
    700: '#770613',
    800: '#4a0109',
    900: '#1f0001',
  },

  brand: {
    50: '#0597cc',
    100: '#CCE9DE',
    200: '#99D2BD',
    300: '#66BC9B',
    400: '#33A57A',
    500: '#008F59',
    600: '#007440',
    700: '#005929',
    800: '#003F12',
    900: '#00120D',
  },
  brandSecondary: {
    50: '#e3f6ff',
    100: '#c3e0ec',
    200: '#a2cadb',
    300: '#80b5cc',
    400: '#5da0bc',
    500: '#4587a3',
    600: '#33697f',
    700: '#234b5c',
    800: '#0f2e3a',
    900: '#001118',
  },
};

const shadows = {
  ...theme.shadows,
  card: `0 4px 8px ${colors.gray['50']}`,
};

const sizes = {
  ...theme.sizes,
  circularProgress: {
    xs: '1rem',
    sm: '1.5rem',
    md: '2rem',
    lg: '2.5rem',
    xl: '3rem',
    '2xl': '3.5rem',
  },
};

export const customTheme = {
  ...theme,
  sizes,
  fonts,
  fontSizes,
  colors,
  shadows,
  menuWidth: { base: '10rem', xs: '5rem' },
headerHeight: 16,
};
