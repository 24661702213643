import React, { useCallback, useEffect, useState } from 'react';
import {
  SimpleGrid,
  Grid,
  Box,
  Tag,
  TagLabel,
  Stack,
  useToast,
  Alert,
  AlertIcon,
  Flex,
  Heading,
  Spinner,
  Text,
} from '@chakra-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DraggableLotCard } from 'components/DraggableCard/lot';
import { useParams } from 'react-router-dom';
import { APPENV } from 'services/config';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formiz, useForm } from '@formiz/core';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FieldSelect,
  Icon,
} from 'components';
import { FaFlag } from 'react-icons/fa';
import { AutoCompleteLot } from 'components/AutoComplete/lot';
import { Enregistrer } from 'components/Button/Enregistrer';

export const LotsConfiguration = ({ defaultLots, projetPhases }) => {
  const { t } = useTranslation();
  const lotsForm = useForm();
  const selectPhaseForm = useForm();
  const { projectToken } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingSubmit, setLoadingSubmit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSelectedPhase, setIsSelectedPhase] = useState('');
  // const [isClicked, setIsClicked] = useState(false);
  // const [clickVal, setClickVal] = useState('');
  // const [clickId, setClickId] = useState('');
  const removeDuplicateLots = (otherArray) => (current) => otherArray.filter((other) => other.lotId === current.id).length === 0;

  // const handleClick = (lotLabel, lotId) => {
  //   setClickVal(lotLabel);
  //   setIsClicked(true);
  //   setClickId(lotId);
  // };

  const phasesForSelect = projetPhases?.map((phase) => {
    const libelle = phase.phaseId === null
        ? phase.label
        : t(phase.label, { defaultValue: phase.phaseLibelle });
    return {
      label: libelle,
      value: `${phase.id}`,
      statut: `${phase.statut}`,
    };
  });

  const defaultLotsList = defaultLots?.map((lot) => ({
    label: t(lot.valeur, { defaultValue: lot.valeur }),
    id: `${lot.id}`,
    lotId: `${lot.id}`,
    ordre: lot.ordre,
  }));

  const fullDefaultListe = defaultLotsList;
  const [listeLotsProjet, setListeLotsProjet] = useState([]);
  const [listeLotsDefault, setListeLotsDefault] = useState(defaultLotsList);

  const toast = useToast();

  useEffect(() => {
    async function fetchData() {
      // You can await here
      if (isSelectedPhase !== '') {
        setLoading(true);
        const response = await axios.get(
          `${APPENV.HOST_API}/projet/${projectToken}/phase/${isSelectedPhase}/lots`,
        );
        const LotsList = response.data?.map((lot) => {
          const libelle = lot.data.lotId === null
              ? lot.data.lotLibelleCustom
              : t(lot.data.lotLibelle, { defaultValue: lot.data.lotLibelle });
          return {
            label: libelle,
            id: `${lot.data.id}`,
            lotId: `${lot.data.lotId}`,
            ordre: lot.data.ordre,
            statut: lot.data.statutLibelle,
            budget: `${lot.data.budget}`,
            avancement: `${lot.data.avancement}`,
            canDelete: lot.auth.canDelete,
            canUpdate: lot.auth.canUpdate,
          };
        });

        const toRemove = LotsList?.filter(
          (x) => defaultLotsList.indexOf(x) === -1,
        );

        setListeLotsDefault(
          defaultLotsList.filter(removeDuplicateLots(toRemove)),
        );
        setListeLotsProjet(LotsList);

        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectedPhase]);

  const getItem = (src, indexSrc) => {
    const srcArr = Array.from(src);
    const [removed] = srcArr.splice(indexSrc, 1);
    srcArr.splice(indexSrc, 0, removed);
    return removed;
  };

  const addItem = (arr, item) => {
    arr.push(item);
    return arr;
  };

  const removeItem = (src, indexSrc) => {
    const srcArr = Array.from(src);
    srcArr.splice(indexSrc, 1);
    return srcArr;
  };

  const reorder = (src, indexSrc, indexDest) => {
    const srcArr = Array.from(src);
    const [removed] = srcArr.splice(indexSrc, 1);
    srcArr.splice(indexDest, 0, removed);

    return srcArr;
  };

  const handleBudgetUpdate = useCallback(
    async (id, budget) => {
      const formData = {};

      formData.id = id;
      formData.budget = budget;

      try {
        await axios.put(
          `${APPENV.HOST_API}/projet/${projectToken}/lot/${id}/budget`,
          formData,
        );
        toast({
          title: t('success.update.lot.libelle', {
            defaultValue: 'Lot mis à jour',
          }),
          description: t('success.update.lot.message', {
            defaultValue: 'Lot mis à jour',
          }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (err) {
        toast({
          title: t('error.update.lot.libelle', {
            defaultValue: 'Erreur lors de la mise à jour',
          }),
          description: t('error.update.lot.message', {
            defaultValue: 'Erreur lors de la mise à jour',
          }),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [projectToken, toast, t],
  );

  const handleAvancementUpdate = useCallback(
    async (id, avancement) => {
      const formData = {};

      formData.id = id;
      formData.avancement = avancement;

      try {
        await axios.put(
          `${APPENV.HOST_API}/projet/${projectToken}/lot/${id}/avancement`,
          formData,
        );
        toast({
          title: t('success.update.lot.libelle', {
            defaultValue: 'Avancement',
          }),
          description: t('success.update.lot.message', {
            defaultValue: 'Avancement mis à jour',
          }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (err) {
        toast({
          title: t('error.update.lot.libelle', {
            defaultValue: 'Erreur lors de la mise à jour',
          }),
          description: t('error.update.lot.message', {
            defaultValue: 'Erreur lors de la mise à jour',
          }),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [projectToken, toast, t],
  );

  const handleRemove = useCallback(
    async (src, indexSrc) => {
      const result = removeItem(src, indexSrc);

      try {
        await axios.put(
          `${APPENV.HOST_API}/projet/${projectToken}/phase/${isSelectedPhase}/lots/delete`,
          result,
        );
        const item = getItem(src, indexSrc);
        const itemToAdd = fullDefaultListe.find((x) => x.id === item.lotId);

        if (typeof itemToAdd !== 'undefined') {
          const arrDefault = addItem(listeLotsDefault, itemToAdd);
          setListeLotsDefault(arrDefault);
        }

        setListeLotsProjet(result);

        toast({
          title: t('success.delete.lot.libelle', {
            defaultValue: 'lot supprimée',
          }),
          description: t('success.delete.lot.message', {
            defaultValue: 'lot supprimée',
          }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (err) {
        toast({
          title: t('error.delete.lot.libelle', {
            defaultValue: 'Erreur lors de la suppression',
          }),
          description: t('error.delete.lot.message', {
            defaultValue: 'Erreur lors de la suppression',
          }),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [
      projectToken,
      toast,
      listeLotsDefault,
      fullDefaultListe,
      t,
      isSelectedPhase,
    ],
  );

  const handleReorder = useCallback(
    async (values) => {
      await axios.put(
        `${APPENV.HOST_API}/projet/${projectToken}/phase/${isSelectedPhase}/lots/order`,
        values,
      );
    },
    [projectToken, isSelectedPhase],
  );

  const handleSubmit = async (values) => {
    try {
      setLoadingSubmit(true);
      const result = await axios.post(
        `${APPENV.HOST_API}/projet/${projectToken}/phase/${isSelectedPhase}/lots/insert`,
        values,
      );
      const LotsResult = result?.data?.map((lot) => {
        const libelle = lot.data.lotId === null
            ? lot.data.lotLibelleCustom
            : t(lot.data.lotLibelle, { defaultValue: lot.data.lotLibelle });
        return {
          label: libelle,
          id: `${lot.data.id}`,
          lotId: `${lot.data.lotId}`,
          ordre: lot.data.ordre,
          statut: `${lot.data.statutLibelle}`,
          budget: `${lot.data.budget}`,
          avancement: `${lot.data.avancement}`,
          canDelete: lot.auth.canDelete,
          canUpdate: lot.auth.canUpdate,
        };
      });

      setListeLotsProjet(LotsResult);

      const index = listeLotsDefault.findIndex((x) => x.id === values.lotId);
      const removeFromDefault = removeItem(listeLotsDefault, index);
      setListeLotsDefault(removeFromDefault);
      setIsSubmit(true);

      toast({
        title: t('success.insert.lot.libelle', { defaultValue: 'lot ajoutée' }),
        description: t('success.insert.lot.message', {
          defaultValue: 'lot ajoutée',
        }),
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (err) {
      toast({
        title: t('error.insert.lot.libelle', {
          defaultValue: 'Erreur lors de la création',
        }),
        description: t('error.insert.lot.message', {
          defaultValue: 'Erreur lors de la création',
        }),
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    }

    setLoadingSubmit(false);
  };

  const getListStyle = () => ({
    padding: '1.5rem',
    width: '100%',
    margin: 'auto',
  });

  // using useCallback is optional
  const onBeforeCapture = useCallback(() => {
    /* ... */
  }, []);
  const onBeforeDragStart = useCallback(() => {
    /* ... */
  }, []);
  const onDragStart = useCallback(() => {
    /* ... */
  }, []);
  const onDragUpdate = useCallback(() => {}, []);
  const onDragEnd = useCallback(
    async (datas) => {
      // dropped outside the list
      const { source, destination } = datas;

      if (!destination) {
        return;
      }

      if (
        source.droppableId === destination.droppableId
        && source.index === destination.index
      ) {
        return;
      }

      if (source.droppableId !== destination.droppableId) {
        return;
      }

      if (source.droppableId === destination.droppableId) {
        const result = reorder(
          listeLotsProjet,
          source.index,
          destination.index,
        );
        setListeLotsProjet(result);
        await handleReorder(result);
      }
    },
    [listeLotsProjet, handleReorder],
  );

  return (
    <>
      <Stack pl={4} pr={4} mb={4}>
        <Card
          w={{
            base: '100%',
            md: '80%',
          }}
          textAlign="center"
          mx="auto"
        >
          <CardHeader>
            <CardTitle>
              <Tag variantColor="green">
                <Icon icon={FaFlag} mr={2} />
                <Text>
                  {t('phase.selection', {
                    defaultValue: 'Sélection de la phase',
                  })}
                </Text>
              </Tag>
              <Alert status="info" mt={4}>
                <AlertIcon />
                {t('lot.description.selectphase')}
              </Alert>
            </CardTitle>
          </CardHeader>
          <SimpleGrid columns={1} spacing={10} p={6}>
            <Formiz onValidSubmit={handleSubmit} connect={selectPhaseForm}>
              <form noValidate onSubmit={selectPhaseForm.submit}>
                <FieldSelect
                  label="Phases"
                  name="lot_phase"
                  helper={`${t('lot.helper.selectphase', {
                    defaultValue: 'Vous devez sélectionner une phase',
                  })}`}
                  required={`${t('errors.required', {
                    defaultValue: 'Champ requis',
                  })}`}
                  placeholder={`${t('lot.placeholder.selectphase', {
                    defaultValue: 'Sélectionner une phase',
                  })}`}
                  options={phasesForSelect}
                  setParentValue={setIsSelectedPhase}
                />
              </form>
            </Formiz>
          </SimpleGrid>
        </Card>
      </Stack>
      {isSelectedPhase !== '' && (
        <Stack spacing={6} pl={4} pr={4}>
          <Card
            w={{
              base: '100%',
              md: '80%',
            }}
            textAlign="center"
            m="auto"
          >
            <CardHeader>
              <CardTitle>
                <Tag variantColor="green">
                  <Icon icon={FaFlag} mr={2} />
                  <Text>
                    {t('lot.configuration', {
                      defaultValue: 'Configuration des Lots',
                    })}
                  </Text>
                </Tag>
                <Alert status="info" mt={4}>
                  <AlertIcon />
                  {t('lot.description.draganddrop')}
                </Alert>
              </CardTitle>
            </CardHeader>

            {isLoading && (
              <Flex alignItems="center" direction="column">
                <Spinner
                  thickness="4px"
                  mb={2}
                  emptyColor="gray.200"
                  color="brand.500"
                  size="lg"
                />
                <Text>
                  {t('archives.loading', { defaultValue: 'Loading todo...' })}
                </Text>
              </Flex>
            )}

            {!isLoading && (
              <DragDropContext
                onBeforeCapture={onBeforeCapture}
                onBeforeDragStart={onBeforeDragStart}
                onDragStart={onDragStart}
                onDragUpdate={onDragUpdate}
                onDragEnd={onDragEnd}
              >
                <SimpleGrid columns={1} spacing={10}>
                  <Droppable droppableId="projet">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {listeLotsProjet?.map((lot, index) => (
                          <Draggable
                            key={lot.id}
                            draggableId={`${lot.id}`}
                            index={index}
                          >
                            {(pvd) => (
                              <Flex
                                justify="center"
                                ref={pvd.innerRef}
                                {...pvd.draggableProps}
                                {...pvd.dragHandleProps}
                              >
                                <DraggableLotCard
                                  id={lot.id}
                                  canDelete={lot.canDelete}
                                  canUpdate={lot.canUpdate}
                                  label={`${lot.label}`}
                                  budget={`${lot.budget}`}
                                  avancement={`${lot.avancement}`}
                                  statut={`${lot.statut}`}
                                  onClick={() => handleRemove(listeLotsProjet, index)}
                                  handleAvancementUpdate={
                                    handleAvancementUpdate
                                  }
                                  handleBudgetUpdate={handleBudgetUpdate}
                                />
                              </Flex>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </SimpleGrid>
              </DragDropContext>
            )}
          </Card>
          <Card
            w={{
              base: '100%',
              md: '80%',
            }}
            textAlign="center"
            m="auto"
          >
            <Formiz onValidSubmit={handleSubmit} connect={lotsForm}>
              <form noValidate onSubmit={lotsForm.submit}>
                <CardHeader>
                  <CardTitle>
                    <Tag variantColor="green">
                      <Icon icon={FaFlag} mr={2} />
                      <TagLabel>
                        {t('lot.header', { defaultValue: 'Lots' })}
                      </TagLabel>
                    </Tag>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {isLoading && (
                    <Flex alignItems="center" direction="column">
                      <Spinner
                        thickness="4px"
                        mb={2}
                        emptyColor="gray.200"
                        color="brand.500"
                        size="lg"
                      />
                      <Text>
                        {t('archives.loading', {
                          defaultValue: 'Loading todo...',
                        })}
                      </Text>
                    </Flex>
                  )}

                  {!isLoading && (
                    <>
                      <Grid
                        templateColumns={{
                          base: '1fr',
                        }}
                        alignItems="center"
                        w="100%"
                        p={6}
                      >
                        <Heading size="sm" as="div">
                          {t('lot.search', {
                            defaultValue:
                              'Recherchez parmis les lots disponibles, ou ajoutez-en un.',
                          })}
                        </Heading>
                        <Flex flexWrap="wrap">
                          {listeLotsDefault?.map((lot) => (
                            <Tag
                              key={lot.id}
                              m={2}
                              maxW={300}
                              variantColor="gray"
                              _hover={{
                                borderColor: 'gray.50',
                                border: '1px',
                                boxShadow: 'xl',
                              }}
                            >
                              <TagLabel fontSize="xs">{`${lot.label} `}</TagLabel>
                            </Tag>
                          ))}
                        </Flex>
                      </Grid>
                      <Grid
                        templateColumns={{
                          base: '1fr',
                        }}
                        alignItems="center"
                        p={6}
                      >
                        <Box
                          rounded="md"
                          borderWidth="1px"
                          borderStyle="dashed"
                          m={2}
                          position="relative"
                        >
                          <AutoCompleteLot
                            name="autocomplete"
                            required="This is required"
                            placeholder="Start date"
                            listeDefault={listeLotsDefault}
                            typeInput="lot"
                            isSubmit={isSubmit}
                            setIsSubmit={setIsSubmit}
                            // isClicked={isClicked}
                            // setIsClicked={setIsClicked}
                            // defaultVal={clickVal}
                            // defaultId={clickId}
                          />
                        </Box>
                      </Grid>
                      <Stack p={4}>
                        <Enregistrer
                          label="button.ajouterauprojet.libelle"
                          isLoading={isLoadingSubmit}
                          disabled={
                            isLoadingSubmit
                            || (!lotsForm.isValid && lotsForm.isSubmitted)
                          }
                        />
                      </Stack>
                    </>
                  )}
                </CardBody>
              </form>
            </Formiz>
          </Card>
        </Stack>
      )}
    </>
  );
};

LotsConfiguration.propTypes = {
  projetLots: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      budget: PropTypes.oneOfType([
        PropTypes.oneOf([null]).isRequired,
        PropTypes.number,
        PropTypes.string,
      ]),
      variation: PropTypes.oneOfType([
        PropTypes.oneOf([null]).isRequired,
        PropTypes.number,
      ]),
      avancement: PropTypes.oneOfType([
        PropTypes.oneOf([null]).isRequired,
        PropTypes.number,
        PropTypes.string,
      ]),
      libelleCustom: PropTypes.string,
      libelle: PropTypes.string,
      moyenneDocumentsValides: PropTypes.number,
    }).isRequired,
  ),
  projetPhases: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      valeur: PropTypes.string,
      ordre: PropTypes.number,
    }).isRequired,
  ),
  defaultLots: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      valeur: PropTypes.string,
      ordre: PropTypes.number,
    }).isRequired,
  ),
};

LotsConfiguration.defaultProps = {
  projetLots: [],
  defaultLots: [],
  projetPhases: [],
};
